import { ThemeProvider } from "@mui/material/styles";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import store from "./app/store";
import { theme } from "./common/theme/Theme";

import "./index.css";

export const history = createBrowserHistory();
const root = createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <BrowserRouter history={history}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);
