import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { authSelector } from "../../../features/auth/authSlice";
import styles from "../../../styles/profile.module.css";

/* ------------------------------------------------------------------------- */
const Profile = () => {
    const auth = useSelector(authSelector);

    return (
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} className={styles.header}>
                <AccountCircleIcon fontSize="large" />
                Votre Profil
            </Grid>

            <Grid container direction="row">
                <Grid item xs={1}>
                    <h4>Nom :</h4>
                </Grid>
                <Grid item xs={4}>
                    <div className={styles.infos}>{auth.user.lastname.charAt(0).toUpperCase() + auth.user.lastname.slice(1)}</div>
                </Grid>
            </Grid>

            <Grid container direction="row">
                <Grid item xs={1}>
                    <h4>Prénom :</h4>
                </Grid>
                <Grid item xs={4}>
                    <div className={styles.infos}>{auth.user.firstname.charAt(0).toUpperCase() + auth.user.firstname.slice(1)}</div>
                </Grid>
            </Grid>

            <Grid container direction="row">
                <Grid item xs={1}>
                    <h4>Email :</h4>
                </Grid>
                <Grid item xs={4}>
                    <div className={styles.infos}>{auth.user.email}</div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Profile;
