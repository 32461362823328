import styles from "./submitButton.module.css";

const SubmitButton = (props) => {
    return (
        <button
            id={props.id}
            type={props.type}
            disabled={props.disabled}
            onClick={props.onClick}
            className={`${props.extraClassName}
      ${
          props.buttonStyle === "dark"
              ? styles.bannerButtonDark
              : props.buttonStyle === "little"
              ? styles.bannerButtonLittle
              : props.buttonStyle === "disabled"
              ? styles.bannerButtonDisabled
              : props.buttonStyle === "disabledBig"
              ? styles.bannerButtonDisabledBig
              : styles.bannerButtonLight
      }`}
        >
            {props.buttonText}
        </button>
    );
};

export default SubmitButton;
