/**
 * Fichier de déclaration de constantes et de variables globales
 *
 * const ENV: Target environment
 *  PROD: env. de production
 *  DEV: staging == env. de test machine distante
 *  LOCAL: developpement en local
 *
 */

export const ENV = process.env.REACT_APP_APP_ENV;

export const version = `v.${process.env.REACT_APP_VERSION}`;

export const KEYCLOAK_CLIENT_ID = "middleware";

export let HREF_X3,
    HREF_GTICK,
    ROOT_URL_GTICK,
    HREF_KC,
    ROOT_URL_KC,
    ROOT_URL_LUMEN,
    BASE_BDD_MIDDLEWARE_API,
    ROOT_URL_MIDDLEWARE,
    MIDDLEWARE_BEARER_TOKEN,
    ROOT_URL_PROD_MIDDLEWARE,
    BASE_BDD_PROD_MIDDLEWARE_API,
    KEYCLOAK_CLIENT_SECRET,
    KEYCLOAK_USERNAME,
    KEYCLOAK_PASSWORD,
    KEYCLOAK_BASE_URL;

ROOT_URL_PROD_MIDDLEWARE = process.env.REACT_APP_ROOT_URL_PROD_MIDDLEWARE;
BASE_BDD_PROD_MIDDLEWARE_API = process.env.REACT_APP_BASE_BDD_PROD_MIDDLEWARE_API;

HREF_X3 = process.env.REACT_APP_HREF_X3;

// LaGalaxy Tickets link
HREF_GTICK = process.env.REACT_APP_HREF_GTICK;
ROOT_URL_GTICK = process.env.REACT_APP_ROOT_URL_GTICK;

// BackOffice
ROOT_URL_LUMEN = process.env.REACT_APP_ROOT_URL_LUMEN;

// Middleware
ROOT_URL_MIDDLEWARE = process.env.REACT_APP_ROOT_URL_MIDDLEWARE;
MIDDLEWARE_BEARER_TOKEN = process.env.REACT_APP_MIDDLEWARE_BEARER_TOKEN;

// BDD used
BASE_BDD_MIDDLEWARE_API = process.env.REACT_APP_BASE_BDD_MIDDLEWARE_API;

// Keycloak server
ROOT_URL_KC = process.env.REACT_APP_KEYCLOAK_BASE_URL;
// Keycloak vars
KEYCLOAK_CLIENT_SECRET = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;
KEYCLOAK_USERNAME = process.env.REACT_APP_KEYCLOAK_USERNAME;
KEYCLOAK_PASSWORD = process.env.REACT_APP_KEYCLOAK_PASSWORD;
KEYCLOAK_BASE_URL = process.env.REACT_APP_KEYCLOAK_BASE_URL;


// Keycloak vars LOCAL
// HREF_KC = "http://localhost:8081/";
// ROOT_URL_KC = "http://localhost:8081/";
// KEYCLOAK_CLIENT_SECRET = "kJ4a76jfo2YI8Sa0hmoFRx6eZcnwXnZF";
// KEYCLOAK_USERNAME = "g.muganza";
// KEYCLOAK_PASSWORD = "toto";
// KEYCLOAK_BASE_URL = "http://localhost:8081/";
