import { useState, useEffect } from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Form, Field } from "react-final-form";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { useGetAddressesQuery, useGetAllCountryQuery, useGetUnlockClientQuery, usePostX3AddressMutation } from "../../app/services/middleware";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import Spinner from "../../common/components/spinner/Spinner";
import Offline from "../../common/pages/Offline";
import { createX3Address } from "../../common/utils/helperFunctions";
import { regexTel, regexNumber, regexInput } from "../../common/utils/helperRegex";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import styles from "../../styles/addressForm.module.css";
import { cartSelector, setCountry, setShippingAddress } from "../cart/cartSlice";

/* ------------------------------------------------------------------------- */
const AddressForm = () => {
    const isOnline = useOnlineStatus();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { client, country } = useSelector(cartSelector);
    const { state } = useLocation();
    const { id } = useParams();

    const param = {
        clientCode: client.clientCode,
        isActif: "Oui",
    };

    const { data } = useGetAddressesQuery(param, {
        selectFromResult: ({ data }) => ({
            data: data?.adresseslivraison.adresselivraison.find((address) => address.numero === id),
        }),
    });

    const dataCountry = useGetAllCountryQuery();

    useEffect(() => {
        if (country === "") {
            dispatch(setCountry("FR")); // TODO recupérer le country via les propriétés de l'adresse séléctionnée
        }
    });

    const handleSelectCountry = (country) => {
        dispatch(setCountry(country));
    };

    const fixedAddress = data && Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== " "));

    const headAddress = {
        clientCode: client.clientCode,
        addressNumber: id,
        addressLivraisonDefaut: client.addressLivraisonDefaut,
        country: country,
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [triggerSetAddress] = usePostX3AddressMutation();

    const { data: unlockClient, isFetching, isError, error } = useGetUnlockClientQuery(client.clientCode);

    /* ----------------------------------------------------------------------- */
    const onSubmit = (values) => {
        setIsSubmitting(true);
        try {
            triggerSetAddress(createX3Address(headAddress, values))
                .unwrap()
                .then((response) => {
                    if (response.success === "true") {
                        if (state.type === "create") toast.success("L'adresse a été créée.");
                        if (state.type === "update") toast.success("L'adresse a été mise à jour.");
                        dispatch(setShippingAddress(id));
                        navigate("/addresses");
                        window.location.reload();
                    } else {
                        setIsSubmitting(false);
                        if (state.type === "create") {
                            toast.error("L'adresse n'a pas pu être créee.", {
                                id: "errorAddress",
                            });
                        }

                        if (state.type === "update") {
                            toast.error("L'adresse n'a pas pu être modifiée.", {
                                id: "errorAddress",
                            });
                        }
                    }
                });
        } catch (error) {
            setIsSubmitting(false);
            if (state.type === "create") {
                toast.error("L'adresse n'a pas pu être créee.", {
                    id: "errorAddress",
                });
            }

            if (state.type === "update") {
                toast.error("L'adresse n'a pas pu être modifiée.", {
                    id: "errorAddress",
                });
            }
        }
    };

    if (isFetching) return null;

    if (!unlockClient) {
        navigate("/profile/addresses");
        toast.error("Quelque chose s'est mal passé.");
    }

    if (isError) return <div>{error}</div>;

    if (isSubmitting) return <Spinner />;

    if (isOnline) {
        return (
            <>
                <div
                    className={styles.goBack}
                    onClick={() => {
                        navigate("/addresses");
                        window.location.reload();
                    }}
                >
                    <ArrowBackIosNewIcon /> Toutes les adresses
                </div>
                <div className={styles.registerContainer}>
                    <div className={styles.register}>
                        {state.type === "update" ? (
                            <h2 className={styles.title}> Modifier une adresse </h2>
                        ) : (
                            <h2 className={styles.title}> Créer une adresse </h2>
                        )}
                        <Form
                            onSubmit={onSubmit}
                            validate={(values) => {
                                const errors = {};
                                if (!values.designation) {
                                    errors.designation = "Ce champs est requis";
                                }
                                if (regexInput.test(values.designation) === false) {
                                    errors.designation = "Veuillez indiquer une désignation valable";
                                }
                                if (!values.address) {
                                    errors.address = "Ce champs est requis";
                                }
                                if (regexInput.test(values.address) === false) {
                                    errors.address = "Veuillez indiquer une adresse valable";
                                }
                                if (!values.zipCode) {
                                    errors.zipCode = "Ce champs est requis";
                                }
                                if (regexNumber.test(values.zipCode) === false) {
                                    errors.zipCode = "Veuillez indiquer un code postal valable";
                                }
                                if (!values.city) {
                                    errors.city = "Ce champs est requis";
                                }
                                if (regexInput.test(values.city) === false) {
                                    errors.city = "Veuillez indiquer une ville valable";
                                }
                                if (!values.phone) {
                                    errors.phone = "Ce champs est requis";
                                }
                                if (regexTel.test(values.phone) === false) {
                                    errors.phone = "Veuillez indiquer un numéro de téléphone valable";
                                }
                                return errors;
                            }}
                            render={({ submitError, handleSubmit, submitting }) => (
                                <form onSubmit={handleSubmit} className={styles.registerFormContainer}>
                                    <div className={styles.registerForm}>
                                        <div className={styles.twoFieldsRow}>
                                            <Field name="designation" type="text" initialValue={fixedAddress && fixedAddress.libelle} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="*Libellé" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="address" type="text" initialValue={fixedAddress && fixedAddress.adresse} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="*Adresse" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className={styles.twoFieldsRow}>
                                            <Field name="address2" type="text" initialValue={fixedAddress && fixedAddress.adresses2} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="Complément adresse 1" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="address3" type="text" initialValue={fixedAddress && fixedAddress.adresse3} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="Complément adresse 2" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>{" "}
                                        <div className={styles.twoFieldsRow}>
                                            <Field name="zipCode" type="text" initialValue={fixedAddress && fixedAddress.codepostal} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="*Code Postal" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="city" type="text" initialValue={fixedAddress && fixedAddress.ville} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="*Ville" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>{" "}
                                        <div className={styles.twoFieldsRow}>
                                            <Field name="phone" type="text" initialValue={fixedAddress && fixedAddress.telephone} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="*Téléphone" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="fax" type="text" initialValue={fixedAddress && fixedAddress.fax} defaultValue="">
                                                {({ input, meta }) => (
                                                    <div className={styles.inputContainer}>
                                                        <span className={styles.inputSpan}>
                                                            {" "}
                                                            <input {...input} type="text" placeholder="Fax" />
                                                        </span>{" "}
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>{" "}
                                        <div className={styles.twoFieldsRow}>
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <select value={country} onChange={(e) => handleSelectCountry(e.target.value)} className={styles.select}>
                                                        <option value="FR" className={styles.defaultCountry}>
                                                            France
                                                        </option>
                                                        {dataCountry.data &&
                                                            dataCountry.data.pays.pays.map((item) => (
                                                                <option key={Math.random().toString(36).substring(2, 9)} value={item.codeiso2}>
                                                                    {item.libelle}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </span>
                                            </div>
                                        </div>
                                        {submitError && <div className="error">{submitError}</div>}
                                        <div className={styles.buttons}>
                                            <SubmitButton
                                                buttonText={state.type === "update" ? "Modifier" : "Créer"}
                                                disabled={isSubmitting}
                                                type="submit"
                                                buttonStyle="dark"
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </>
        );
    }
    return <Offline />;
};

export default AddressForm;
