import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    locationLastAction: localStorage.getItem("locationLastAction") ? localStorage.getItem("locationLastAction") : null,
};

const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        updateLastLocation: (state, action) => {
            state.locationLastAction = action.payload;
            localStorage.setItem("locationLastAction", action.payload);
        },

        clearLocation: (state, action) => {
            state.locationLastAction = "";
        },
    },
});

export const { updateLastLocation, clearLocation } = locationSlice.actions;
export default locationSlice.reducer;
export const locationSelector = (state) => state.location;
