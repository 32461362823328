import { useEffect, useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fr } from "date-fns/locale";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { usePostRefreshTokenMutation } from "../../../app/services/keycloakApi";
import { lumenApi, useGetUsersQuery, useUpdateStatusQuoteMutation, useSearchQuotesMutation } from "../../../app/services/lumenApi";
import { useGetInfosClientQuery, usePostArticlesMutation, usePutTraiterLogMutation } from "../../../app/services/middleware";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import { clearCart, restoreCart, setClient, setFromQuote, setShippingAddress } from "../../../features/cart/cartSlice";
import SelectClientOrder from "../../../features/order/SelectClientOrder";
import {
    clearQuote,
    restoreQuote,
    setClientQuote,
    setFactAddress,
    setIsRecoveryQuote,
    setQuoteId,
    setRefQuote,
    setShippingAddressClient,
} from "../../../features/quote/quoteSlice";
import styles from "../../../styles/searchQuote.module.css";
import CustomSubmitButton from "../../components/buttons/CustomSubmitButton";
import PopUpSentSimpleQuote from "../../components/popUpQuote/PopUpSentSimpleQuote";
import { ENV } from "../../utils/apiConstants";
import { getFormDataToRefreshKeycloakToken, strDatetimeFrFormat } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const SearchQuote = () => {
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(authSelector);

    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);

    const [clientSelect, setClientSelect] = useState("");
    const [isClientSelected, setIsClientSelected] = useState(false);
    const [addressMail, setAddressMail] = useState("");

    const [selection, setSelection] = useState([]);
    const [tabUsers, setTabUsers] = useState([]);

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(14);

    const [open, setOpen] = useState(false);

    // - api service calls
    const resGetClientInfos = useGetInfosClientQuery(clientSelect.clientCode);
    const resGetUsers = useGetUsersQuery();
    const [triggerRefreshToken] = usePostRefreshTokenMutation();

    const [triggerSearchQuote, resTriggerSearchQuote] = useSearchQuotesMutation();
    const [triggerInfoQuote, resTriggerInfoQuote] = lumenApi.endpoints.quoteDetails.useLazyQuery();
    const [triggerArticle] = usePostArticlesMutation();
    const [triggerStatusClient] = useUpdateStatusQuoteMutation();
    const [triggerLog] = usePutTraiterLogMutation();

    /* ----------------------------------------------------------------------- */

    let formDateDebut = "";
    let formDateFin = "";

    if (dateDebut !== null) {
        formDateDebut = `${dateDebut.getFullYear()}-${`0${dateDebut.getMonth() + 1}`.slice(-2)}-${`0${dateDebut.getDate()}`.slice(-2)}`;
    }

    if (dateFin !== null) {
        formDateFin = `${dateFin.getFullYear()}-${`0${dateFin.getMonth() + 1}`.slice(-2)}-${`0${dateFin.getDate()}`.slice(-2)}`;
    }

    /* -- useEffect() -------------------------------------------------------- */
    useEffect(() => {
        if (resGetClientInfos.originalArgs !== undefined) {
            setIsClientSelected(true);
        }
    }, [resGetClientInfos.originalArgs]);

    useEffect(() => {
        if (resGetUsers.data !== undefined && resGetUsers.status === "fulfilled") {
            setTabUsers(resGetUsers.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetUsers]);

    useEffect(() => {
        if (resGetClientInfos.status === "fulfilled" && resGetClientInfos.data !== null && resGetClientInfos.data.nbresultats !== 0) {
            const selectedEmail = resGetClientInfos.data?.client?.facturation?.email1 !== "" ? resGetClientInfos.data.client.facturation.email1 : "";
            setAddressMail(selectedEmail);
        }
    }, [resGetClientInfos]);

    useEffect(() => {
        if (resTriggerSearchQuote.status === "fulfilled" && resTriggerSearchQuote.data !== null && resGetClientInfos.data.nbresultats > 0) {
            setRows(resTriggerSearchQuote.data.quotes.quote);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerSearchQuote]);

    /* ----------------------------------------------------------------------- */
    const stringDateComparator = (str1, str2) => {
        return Date.parse(str1) > Date.parse(str2);
    };

    /**
     * function called onClick button Recherher
     */
    const handleClickOnSearch = () => {
        if (clientSelect !== "") {
            let bodyParam = {
                client_code: clientSelect.clientCode,
                start_date: formDateDebut,
                end_date: formDateFin,
            };

            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
            triggerSearchQuote(bodyParam);
        } else {
            toast.error("Veuillez renseigner un client");
        }
    };

    const handleStatusClient = (params) => {
        const bodyParam = {
            quote_id: params.row.id,
            status: 2,
        };
        triggerStatusClient(bodyParam)
            .unwrap()
            .then((response) => {
                // Mettre à jour le statut de la ligne dans le tableau
                const updatedRow = { ...params.row, status: 2 };
                const updatedRowsCopy = [...rows]; // Utilisez `rows` au lieu de `updatedRows`
                const rowIndex = updatedRowsCopy.findIndex((row) => row.id === params.row.id);
                updatedRowsCopy[rowIndex] = updatedRow;
                setRows(updatedRowsCopy); // Mettez à jour `rows`
            });
    };

    const mapStatusToDescription = (status) => {
        switch (status) {
            case 0:
                return "En cours de création";
            case 1:
                return "En attente de validation client";
            case 2:
                return "Accepté par le client";
            case 3:
                return "en file d'attente...";
            case 4:
                return "Succès";
            case 5:
                return "Céation de commande en cours";
            case -1:
                return "Erreur";
            default:
                return "Statut inconnu";
        }
    };

    const handleClickRestart = (params) => {
        dispatch(clearQuote());
        dispatch(setIsRecoveryQuote(true));
        dispatch(setQuoteId(params.row.id));
        dispatch(
            setClientQuote({
                clientCode: params.row.client_code,
                client: params.row.client,
                codepostal: params.row.zip_code,
            })
        );
        dispatch(setFactAddress("FAC"));
        dispatch(setShippingAddressClient(params.row.shipping_address));
        triggerInfoQuote(params.row.id);
    };

    useEffect(() => {
        if (resTriggerInfoQuote.status === "fulfilled" && resTriggerInfoQuote.isSuccess === true && resTriggerInfoQuote.data !== null) {
            const params = resTriggerInfoQuote.data;
            const productsBills = resTriggerInfoQuote.data.items.item;
            let index = 0;
            if (params.status < 2) {
                productsBills.forEach(async (product) => {
                    const body = {
                        article: product.reference,
                    };
                    const bodyParam = encodeURIComponent(JSON.stringify(body));
                    await triggerArticle(bodyParam)
                        .unwrap()
                        .then((response) => {
                            const articles = {
                                ...response.articles.article[0],
                                ...product,
                                indexInQuote: index,
                                quoteQuantity: parseInt(product.quantity, 10),
                            };
                            dispatch(restoreQuote(articles));
                            index += 1;
                        });
                });
                dispatch(setRefQuote(params.reference));
                navigate("/add-quote");
            } else {
                productsBills.forEach(async (product) => {
                    const body = {
                        article: product.reference,
                    };
                    const bodyParam = encodeURIComponent(JSON.stringify(body));
                    await triggerArticle(bodyParam)
                        .unwrap()
                        .then((response) => {
                            const articles = {
                                ...response.articles.article[0],
                                ...product,
                                puuid: uuidv4(),
                                indexInCart: index,
                                quantity: parseInt(product.quantity, 10),
                            };
                            dispatch(restoreCart(articles));
                            index += 1;
                        });
                });

                navigate("/order");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerInfoQuote]);

    const msgRecovery = "Etes-vous sûr de vouloir reprendre ce devis ?";
    const msgOrder = "Etes-vous sûr de vouloir créer une commande ?";

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };

    const handleRestartOrder = async (params) => {
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        await triggerRefreshToken(formData)
            .unwrap()
            .then((response) => {
                if (response.status === false) {
                    toast(
                        <>
                            <InfoIcon fontSize="medium" className={styles.posInToast} />
                            Votre session a expirée.
                            <br />
                            Merci de saisir votre login et mot de passe pour vous reconnecter.
                        </>,
                        {
                            duration: 7000,
                        }
                    );

                    // - send log warning
                    errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                    errorInfos.userEmail = auth.user.email;
                    errorInfos.className = "SearchQuote.js - event onClick button Créer une commande";
                    errorInfos.code = 300;
                    errorInfos.criticite = 1;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);

                    dispatch(resetUser());
                    navigate("/login");
                } else {
                    dispatch(clearCart());
                    dispatch(
                        setClient({
                            clientCode: params.row.client_code,
                            client: params.row.client,
                            codepostal: params.row.zip_code,
                        })
                    );
                    dispatch(setShippingAddress(params.row.shipping_address));
                    dispatch(setFromQuote(true));
                    triggerInfoQuote(params.id);
                    const bodyParam = {
                        quote_id: params.row.id,
                        status: 5,
                    };
                    triggerStatusClient(bodyParam);
                }
            });
    };

    function confirmPopup(params, msg) {
        if ((params.row.status === 0 && params.row.user_id !== auth.user.id) || (params.row.status === 5 && params.row.user_id !== auth.user.id)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    const userObject = tabUsers.find((element) => element.id === params.row.user_id);
                    return (
                        <div className={styles.customUI}>
                            <div className={styles.beatsWarning}>
                                <WarningAmberIcon className={styles.warning} />
                            </div>
                            <span className={styles.titleWarning}>Risque de doublon</span>

                            <p>
                                L&#39;utilisateur {userObject.lastname} {userObject.firstname} est à l&#39;initiative de la création du devis. <br />
                                {msg}
                            </p>
                            <button onClick={onClose}>Annuler</button>
                            {msg === msgRecovery ? (
                                <button
                                    onClick={() => {
                                        handleClickRestart(params);
                                        onClose();
                                    }}
                                >
                                    Valider
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        handleRestartOrder(params);
                                        onClose();
                                    }}
                                >
                                    Valider
                                </button>
                            )}
                        </div>
                    );
                },
            });
        } else {
            const condition = msg === msgRecovery;
            if (condition) {
                handleClickRestart(params);
            } else handleRestartOrder(params);
        }
    }

    const columns = [
        {
            field: "lock",
            headerName: "",
            sortable: false,
            width: 24,
            maxWidth: 24,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => <LockIcon className={params.row.status === 0 || params.row.status === 5 ? styles.lockOrder : styles.none} />,
        },
        {
            field: "ident",
            headerName: "Identifiant",
            headerClassName: "tableHeader",
            cellClassName: "ident",
            flex: 1,
            minWidth: 110,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "status",
            headerName: "Statut",
            headerClassName: "tableHeader",
            cellClassName: "status",
            flex: 1,
            minWidth: 170,
            headerAlign: "center",
            align: "left",
            valueGetter: (params) => mapStatusToDescription(params.value),
        },
        {
            field: "created_at",
            headerName: "Date de création",
            headerClassName: "tableHeader",
            cellClassName: "createdAt",
            flex: 1,
            headerAlign: "center",
            align: "left",
            valueGetter: (params) => params.row.created_at,
            valueFormatter: (params) => strDatetimeFrFormat(params.value),
            sortComparator: stringDateComparator,
        },
        {
            field: "recovery",
            headerName: "Editer",
            headerClassName: "tableHeader",
            cellClassName: "recovery",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status < 2) {
                    return <RestartAltIcon className={`restart ${styles.icon}`} onClick={() => confirmPopup(params, msgRecovery)} />;
                }
            },
        },
        {
            field: "pdf",
            headerName: "Voir PDF",
            headerClassName: "tableHeader",
            cellClassName: "pdf",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status !== 0) {
                    return <PictureAsPdfIcon className={`pdf ${styles.icon}`} onClick={() => window.open(params.row.pdf_link)} />;
                }
            },
        },
        {
            field: "Validated",
            headerName: "Validation client",
            headerClassName: "tableHeader",
            cellClassName: "Validated",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status === 1) {
                    return <PublishedWithChangesIcon className={`validate ${styles.icon}`} onClick={() => handleStatusClient(params)} />;
                }
                if (params.row.status === 2) {
                    return <DoneIcon className={`done ${styles.icon}`} />;
                }
            },
        },
        {
            field: "order",
            headerName: "Créer une commande",
            headerClassName: "tableHeader",
            cellClassName: "order",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status >= 2) {
                    return <ShoppingCartCheckoutIcon className={`create-order-icon ${styles.icon}`} onClick={() => confirmPopup(params, msgOrder)} />;
                }
            },
        },
    ];

    /* -- display content -------------------------------------------------- */
    if (isOnline) {
        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3}>
                {" "}
                <div>
                    <h2 className={styles.title} id="titleSearchQuote">
                        Devis
                    </h2>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={8} id="selectClient">
                        <SelectClientOrder setClient={setClientSelect} noClear />
                    </Grid>

                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Début"
                                value={dateDebut}
                                onChange={(newValue) => {
                                    setDateDebut(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Fin"
                                format="dd/MM/yyyy"
                                value={dateFin}
                                onChange={(newValue) => {
                                    setDateFin(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <div className={styles.selectedClient}>
                    <div id="clientselected" className={styles.selectedClientLeft}>
                        Client sélectionné:
                        {isClientSelected ? (
                            <span className={styles.clientSelect}>
                                {clientSelect.clientCode} - {clientSelect.client}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <br />
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <CustomSubmitButton id="btnSearchQuote" buttonText="Rechercher" onClick={handleClickOnSearch} disabled={!isClientSelected} />
                    </Grid>

                    <Grid item xs={4}></Grid>

                    <Grid item xs={3}>
                        <div>
                            {resGetClientInfos.status === "pending" ? (
                                ""
                            ) : resGetClientInfos.data.nbresultats === 0 ? (
                                ""
                            ) : (
                                <span>
                                    <select
                                        value={`${addressMail}`}
                                        onChange={(e) => {
                                            setAddressMail(e.target.value);
                                        }}
                                        className={styles.selectMail}
                                    >
                                        {resGetClientInfos.data.client.facturation.email1 === "" ? (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={"Pas d'adresse de facturation"}>
                                                Pas d&#39;adresse de facturation
                                            </option>
                                        ) : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email1}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email1}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email2 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email2}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email2}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email3 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email3}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email3}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email4 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email4}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email4}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email5 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email5}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email5}
                                            </option>
                                        )}
                                    </select>
                                </span>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={3} className={styles.selectedClientRight}>
                        {resGetClientInfos.status === "pending" ? (
                            ""
                        ) : resGetClientInfos.data.nbresultats === 0 ? (
                            ""
                        ) : (
                            <CustomSubmitButton
                                id="sendEmailQuote"
                                buttonText="Envoyer les devis sélectionnés par email"
                                onClick={handleOpen}
                                disabled={!selection.length}
                            />
                        )}
                    </Grid>
                </Grid>
                <br />
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "created_at", sort: "desc" }],
                            },
                        }}
                        rows={rows}
                        getRowId={(row) => row.id}
                        onRowSelectionModelChange={(e) => {
                            setSelection(e);
                        }}
                        rowSelectionModel={selection}
                        rowHeight={36}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[14, 25, 50]}
                        sortingOrder={["asc", "desc"]}
                        isRowSelectable={(params) => params.row.status > 0}
                        checkboxSelection
                        disableSelectionOnClick
                    />
                </div>
                <Dialog open={open} onClose={handleClose} maxWidth="xl">
                    <div className={styles.grey}>
                        <PopUpSentSimpleQuote clientCode={clientSelect.clientCode} emailAddress={addressMail} dataList={selection} />
                    </div>
                    <DialogActions className={styles.grey}>
                        <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
    return <Offline />;
};

export default SearchQuote;
