import { Grid } from "@mui/material";

import SearchProductsOffer from "./SearchProductsOffer";
import styles from "../../styles/cartRowEmpty.module.css";

const OfferRowEmpty = ({ index }) => {
    return (
        <Grid item xs={8} className={styles.cartTableRow}>
            <SearchProductsOffer index={index} />
        </Grid>
    );
};

export default OfferRowEmpty;
