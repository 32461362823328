import React, { useEffect, useState } from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./addresses.module.css";
import AddressRow from "./AddressRow";
import { middleware } from "../../app/services/middleware";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import Spinner from "../../common/components/spinner/Spinner";
import Offline from "../../common/pages/Offline";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import { cartSelector, setClient } from "../cart/cartSlice";
import { commercialOfferSelector } from "../commercialOffer/commercialOfferSlice";
import { locationSelector } from "../location/locationSlice";
import { quoteSelector } from "../quote/quoteSlice";

/* ------------------------------------------------------------------------- */

const Addresses = () => {
    // console.log("[line::25][INFO] component Addresses is rendered...");

    const isOnline = useOnlineStatus();
    const { client, shippingAddress } = useSelector(cartSelector);
    const { clientQuote } = useSelector(quoteSelector);
    const { offerClient } = useSelector(commercialOfferSelector);
    const location = useSelector(locationSelector);
    const dispatch = useDispatch();

    let clientCode = null;
    let clientName = null;

    if (location.locationLastAction !== null && location.locationLastAction.includes("/add-quote")) {
        clientCode = clientQuote.clientCode;
        clientName = clientQuote.client;
    } else if (location.locationLastAction !== null && location.locationLastAction.includes("/add-commercial-offer")) {
        clientCode = offerClient.clientCode;
        clientName = offerClient.client;
    } else {
        clientCode = client.clientCode;
        clientName = client.client;
    }

    const param = {
        clientCode: clientCode,
        isActif: "Oui",
    };

    const [newAddressNumber, setNewAddressNumber] = useState(null);
    const [defaultShippingAddress, setDefaultShippingAddress] = useState(shippingAddress);

    const [triggerGetAddresses, responseGetAddresses] = middleware.endpoints.getAddresses.useLazyQuery();

    const [triggerGetInfos, responseGetInfos] = middleware.endpoints.getInfosClient.useLazyQuery();

    const navigate = useNavigate();

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: "100%",
    }));

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        if (clientCode !== null) {
            if (
                location.locationLastAction !== null &&
                (location.locationLastAction.includes("/add-quote") || location.locationLastAction.includes("/add-commercial-offer"))
            ) {
                const clientProperties = {
                    clientCode: clientCode,
                    clientName: clientName,
                };
                dispatch(setClient(clientProperties));
            }

            triggerGetAddresses(param);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (responseGetAddresses.status === "fulfilled" && responseGetAddresses.data.adresseslivraison.adresselivraison != null) {
            const list = responseGetAddresses.data.adresseslivraison.adresselivraison
                .filter((address) => address.numero.startsWith("L"))
                .map((address) => address.numero.slice(1));

            setNewAddressNumber(`L${Math.max(...list) + 1}`);
            triggerGetInfos(clientCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseGetAddresses]);

    useEffect(() => {
        if (shippingAddress !== defaultShippingAddress) {
            triggerGetAddresses(param);
            setDefaultShippingAddress(shippingAddress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingAddress]);

    /* ----------------------------------------------------------------------- */
    if (responseGetAddresses?.isFetching || responseGetInfos?.isFetching) {
        return <Spinner />;
    }

    if (isOnline) {
        if (responseGetAddresses.isError) {
            return <div>{responseGetAddresses.error}</div>;
        }

        if (responseGetInfos.isError) {
            return <div>{responseGetInfos.error}</div>;
        }

        if (responseGetAddresses?.status === "fulfilled" && responseGetInfos?.status === "fulfilled" && responseGetAddresses.data.nbresultats === 0) {
            const clientInfos = responseGetInfos.data;

            return (
                <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3}>
                    <div
                        className={styles.goBack}
                        onClick={() => {
                            if (location.locationLastAction !== null && location.locationLastAction.includes("/add-quote")) {
                                navigate("/add-quote");
                            } else if (location.locationLastAction !== null && location.locationLastAction.includes("/add-commercial-offer")) {
                                navigate("/add-commercial-offer");
                            } else {
                                navigate("/order");
                            }
                        }}
                    >
                        <ArrowBackIosNewIcon /> Retour
                    </div>
                    <div>
                        <h2 className={styles.title}>
                            Carnet d&#39;adresses {clientCode} - {clientName}{" "}
                        </h2>
                    </div>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" className={styles.topRow}>
                        <Grid item md={6}>
                            <Item>
                                <h4 className={styles.addressCardTitle}>ADRESSE DE FACTURATION PAR DÉFAUT</h4>
                                <AddressRow item={clientInfos.client.facturation} />
                            </Item>
                        </Grid>
                        <Grid item md={6}>
                            <Item>
                                <h4 className={styles.addressCardTitle}>ADRESSE DE Livraison PAR DÉFAUT</h4>
                                <AddressRow item={clientInfos.client.livraison} />
                            </Item>
                        </Grid>
                    </Grid>{" "}
                    <div className={styles.noResult}>
                        <p>
                            <CloudOffIcon />
                            Aucune adresse de livraison n&#39;est enregistrée
                        </p>
                        <SubmitButton
                            buttonText="Ajouter une adresse"
                            onClick={() =>
                                navigate(`/address/${newAddressNumber}`, {
                                    state: { type: "create" },
                                })
                            }
                        />
                    </div>
                </Box>
            );
        }

        if (responseGetAddresses?.status === "fulfilled" && responseGetInfos?.status === "fulfilled" && responseGetAddresses.data.nbresultats > 0) {
            return (
                <Box id="adrBook" sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3}>
                    <div
                        className={styles.goBack}
                        onClick={() => {
                            if (location.locationLastAction !== null && location.locationLastAction.includes("/add-quote")) {
                                navigate("/add-quote");
                            } else if (location.locationLastAction !== null && location.locationLastAction.includes("/add-commercial-offer")) {
                                navigate("/add-commercial-offer");
                            } else {
                                navigate("/order");
                            }
                        }}
                    >
                        <ArrowBackIosNewIcon /> Retour
                    </div>
                    <div>
                        <h2 className={styles.title}>
                            Carnet d&#39;adresses {clientCode} - {clientName}{" "}
                        </h2>
                    </div>
                    <div>
                        <h4 className={styles.subTitle}>Adresses par défaut</h4>
                    </div>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" className={styles.topRow}>
                        <Grid item md={6}>
                            <Item>
                                <h4 className={styles.addressCardTitle}>ADRESSE DE FACTURATION PAR DÉFAUT</h4>
                                <AddressRow item={responseGetInfos.data.client?.facturation} />
                            </Item>
                        </Grid>
                        <Grid item md={6}>
                            <Item>
                                <h4 className={styles.addressCardTitle}>ADRESSE DE Livraison PAR DÉFAUT</h4>
                                <AddressRow item={responseGetInfos.data.client?.livraison} />
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" className={styles.topRow}>
                        <Grid item xs={12}>
                            <h4 className={styles.subTitle}>Adresses complémentaires</h4>
                            <SubmitButton
                                buttonText="Ajouter une adresse GMU"
                                onClick={() => {
                                    navigate(`/address/${newAddressNumber}`, {
                                        state: { type: "create" },
                                    });
                                }}
                            />
                        </Grid>
                        {responseGetAddresses?.data?.adresseslivraison?.adresselivraison.map((item, index) => (
                            <Grid item md={6} key={index}>
                                {" "}
                                <Item>
                                    <h4 className={styles.addressCardTitle}>ADRESSE COMPLEMENTAIRE</h4>
                                    <AddressRow item={item} />
                                    <SubmitButton
                                        buttonText="Modifier"
                                        onClick={() =>
                                            navigate(`/address/${item.numero}`, {
                                                state: {
                                                    type: "update",
                                                },
                                            })
                                        }
                                    />
                                </Item>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            );
        }
    } else {
        return <Offline />;
    }
};

export default Addresses;
