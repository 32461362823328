import { useRef } from "react";

import { Grid } from "@mui/material";

import styles from "../../styles/selectClient.module.css";

/* ------------------------------------------------------------------------- */
const SelectNumOrder = ({ setNum, setClient, clear }) => {
    const ref = useRef(null);

    function handleInputChange(input) {
        if (input.length > 0) {
            clear("inputClient");
            setClient(0);
            setNum(input);
        } else {
            setNum([]);
        }
    }

    // -- display content --
    return (
        <div className={styles.searchContainerFacturation} ref={ref}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className={styles.selectCell}>
                        <input
                            id="inputNum"
                            autoComplete="off"
                            type="text"
                            name="text"
                            placeholder="Numéro commande"
                            className={styles.searchInput}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default SelectNumOrder;
