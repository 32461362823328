import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid } from "@mui/x-data-grid";

import styles from "../../../styles/chronologie.module.css";
import { HREF_X3 } from "../../utils/apiConstants";

const AllDocumentsX3 = (props) => {
    function getOrderNumber(params) {
        return `${params.row.orderNumber || ""}`;
    }

    function copyInClipboard(number) {
        navigator.clipboard.writeText(number);
    }

    // - colonnes tableau de documents de commandes liés à un panier
    const columnsDoc = [
        {
            field: "id",
            headerName: "id",
            headerClassName: "tableHeader",
            cellClassName: "SFNumber",
            flex: 1,
            maxWidth: 1,
            headerAlign: "center",
            align: "left",
            hide: true,
        },
        {
            field: "typecommand",
            headerName: "Type",
            headerClassName: "tableHeader",
            cellClassName: "SFNumber",
            flex: 1,
            maxWidth: 100,
            headerAlign: "center",
            align: "left",
            sortable: false,
        },
        {
            field: "statutdocX3creation",
            headerName: "Statut",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 160,
            headerAlign: "center",
            align: "left",
            sortable: false,
            renderCell: (params) => (
                <>
                    {params.value === 1 && params.row.orderNumber != null && <div className={styles.usageIco}>créé dans X3</div>}
                    {params.value === 1 && params.row.orderNumber == null && <div className={styles.usageIco}>n&#39;existe plus dans Sage X3</div>}
                    {params.value === -1 && <div className={styles.alertIco}>création en échec</div>}
                    {params.value === 0 && <div className={styles.errorIco}>en file d&#39;attente...</div>}
                </>
            ),
        },
        {
            field: "orderNumber",
            headerName: "numéro de commande",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 180,
            headerAlign: "center",
            align: "left",
            sortable: false,
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.value !== null && (
                        <div
                            className={styles.hand}
                            onClick={() => window.open(`${HREF_X3}?url=/trans/x3/erp/BIOTECH/$sessions?f=GESSOH/1/OBJBPR/M/${params.value}`, "_blank")}
                        >
                            {params.value}
                        </div>
                    )}
                </>
            ),
        },
        {
            field: "Lien",
            headerName: "Copier le numéro",
            headerClassName: "tableHeader",
            flex: 1,
            minWidth: 110,
            headerAlign: "center",
            align: "center",
            sortable: false,
            valueGetter: getOrderNumber,
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.orderNumber != null && (
                        <div onClick={() => copyInClipboard(params.value)} className={`${styles.tooltipBtn} ${styles.copyBtn}`}>
                            <ContentCopyIcon className={styles.customSize} />
                            {/* <div className={styles.tooltiptext}>Copier le numéro de commande</div> */}
                        </div>
                    )}
                </>
            ),
        },
    ];

    return (
        <div className={styles.viewContainer}>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                    },
                }}
                rows={props.doc}
                rowHeight={50}
                disableColumnFilter
                disableColumnMenu
                hideFooter
                sortingOrder={["asc", "desc"]}
                columns={columnsDoc}
                disableRowSelectionOnClick
            />
        </div>
    );
};
export default AllDocumentsX3;
