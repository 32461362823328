import { useState } from "react";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useSelector, useDispatch } from "react-redux";

import { clearQuoteNum, quoteSelector, setRefQuote } from "./quoteSlice";
import styles from "../../styles/addQuote.module.css";

/* ------------------------------------------------------------------------- */
const RefInput = () => {
    const quote = useSelector(quoteSelector);
    const dispatch = useDispatch();

    const [refField, setRefField] = useState(quote.refQuote ? quote.refQuote : "");

    const handleRefField = (value) => {
        setRefField(value);
        dispatch(setRefQuote(value));
        dispatch(clearQuoteNum());
    };

    /**
     * Component's field is editable only on page order
     */
    return (
        <>
            <LocalOfferIcon className={styles.adjustPos} />
            <span>*Référence du devis : </span>
            <input
                autoComplete="off"
                type="text"
                name="ref"
                placeholder="Référence"
                maxLength={20}
                value={refField}
                onChange={(e) => {
                    handleRefField(e.target.value);
                }}
            />
        </>
    );
};

export default RefInput;
