import { useState, useEffect } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { middleware } from "../../../app/services/middleware";
import { clearCart } from "../../../features/cart/cartSlice";
import styles from "../../../styles/phoneIp.module.css";
import CardClient from "../../components/cardClient/CardClient";
import PopUpAddInterlocuteur from "../../components/cardClient/PopUpAddInterlocuteur";
import Spinner from "../../components/spinner/Spinner";
import { regexTel } from "../../utils/helperRegex";

/* ------------------------------------------------------------------------- */
const PhoneIp = () => {
    const { phone } = useParams();

    const navigate = useNavigate();

    const [triggerSearchClientByPhone, result] = middleware.endpoints.getClientsByPhone.useLazyQuery();
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
        dispatch(clearCart());
    };

    useEffect(() => {
        if (regexTel.test(phone) === false) {
            navigate("/not-found");
        } else if (regexTel.test(phone) === true && phone) {
            triggerSearchClientByPhone(phone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone]);

    useEffect(() => {
        if (result.isSuccess) {
            if (result.data.nbresultats > 0) {
                setClients(result.data.clients.client);
            } else {
                handleClickOpen();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);

    const handleClose = () => {
        setOpen(false);
        dispatch(clearCart());
    };

    if (result.status === "pending") {
        return <Spinner />;
    }

    if (clients.length > 0) {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2 className={styles.title}>Sélection du client de l&#39;appel entrant</h2>
                        <h3 className={styles.center}>{phone}</h3>
                    </Grid>

                    {clients.map((client, index) => (
                        <Grid item sm={6} md={3} key={index}>
                            <CardClient client={client} />
                        </Grid>
                    ))}
                    <Grid item xs={2}>
                        <Card>
                            <CardContent className={styles.cardBlack}>
                                <div className={styles.maxHeigth} onClick={() => handleClickOpen()}>
                                    <div>
                                        <h4 className={styles.new}>NOUVEAU CONTACT</h4>
                                    </div>

                                    <div className={styles.actionIcon}>
                                        <AddCircleOutlineIcon fontSize="inherit" />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Dialog className={styles.marginLeft} open={open} onClose={handleClose} maxWidth="xl">
                    <div className={styles.popUp}>
                        <div className={styles.heigth}>
                            <PopUpAddInterlocuteur phone={phone} clients={clients} />
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>Fermer</Button>
                        </DialogActions>
                    </div>
                </Dialog>
                <br />
            </>
        );
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Card>
                        <CardContent className={styles.cardBlack}>
                            <div className={styles.maxHeigth} onClick={() => handleClickOpen()}>
                                <div>
                                    <h4 className={styles.new}>NOUVEAU CONTACT</h4>
                                </div>

                                <div className={styles.actionIcon}>
                                    <AddCircleOutlineIcon fontSize="inherit" />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog className={styles.marginLeft} open={open} onClose={handleClose} maxWidth="xl">
                <div className={styles.popUp}>
                    <div className={styles.heigth}>
                        <PopUpAddInterlocuteur phone={phone} />
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

export default PhoneIp;
