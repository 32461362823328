import { Grid } from "@mui/material";

import SearchProductsQuote from "./SearchProductsQuote";
import styles from "../../styles/cartRowEmpty.module.css";

const QuoteRowEmpty = ({ index }) => {
    return (
        <Grid item xs={8} className={styles.cartTableRow}>
            <SearchProductsQuote index={index} />
        </Grid>
    );
};

export default QuoteRowEmpty;
