import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ROOT_URL_KC } from "../../common/utils/apiConstants";
import { timestampToDate } from "../../common/utils/helperFunctions";

const timeInCacheShort = 0;
// eslint-disable-next-line no-unused-vars
const timeInCacheMedium = 1800;
// eslint-disable-next-line no-unused-vars
const timeInCacheLong = 3600;

const realm = "biotechdental";

const baseQuery = fetchBaseQuery({
    baseUrl: ROOT_URL_KC,
    prepareHeaders: (headers) => {
        // Personnalisez les en-têtes si nécessaire
        headers.set("Content-Type", "application/x-www-form-urlencoded");
        return headers;
    },
});

export const keycloakApi = createApi({
    reducerPath: "keycloakApi",
    baseQuery,
    // tagTypes: ["Auth", "User"],
    endpoints: (builder) => ({
        postToken: builder.mutation({
            query: (formData) => ({
                url: `/realms/${realm}/protocol/openid-connect/token`,
                method: "POST",
                body: formData,
            }),
            keepUnusedDataFor: timeInCacheShort,
            providesTags: ["Auth"],
        }),

        postIntrospect: builder.mutation({
            query(formData) {
                return {
                    url: `/realms/${realm}/protocol/openid-connect/token/introspect`,
                    method: "POST",
                    body: formData,
                };
            },
            providesTags: ["User"],
        }),

        postRefreshToken: builder.mutation({
            query(formData) {
                return {
                    url: `/realms/${realm}/protocol/openid-connect/token`,
                    method: "POST",
                    body: formData,
                };
            },
            providesTags: ["Auth"],
        }),

        postLogout: builder.mutation({
            query(formData) {
                return {
                    url: `/realms/${realm}/protocol/openid-connect/logout`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
    }),
});

export const { usePostTokenMutation, usePostIntrospectMutation, usePostRefreshTokenMutation, usePostLogoutMutation } = keycloakApi;
