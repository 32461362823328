import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_BDD_MIDDLEWARE_API, BASE_BDD_PROD_MIDDLEWARE_API, ROOT_URL_LUMEN } from "../../common/utils/apiConstants";
import { isoStrToDate } from "../../common/utils/helperFunctions";

const timeInCacheShort = 0;
// eslint-disable-next-line no-unused-vars
const timeInCacheMedium = 1800;
// eslint-disable-next-line no-unused-vars
const timeInCacheLong = 3600;

const baseQuery = fetchBaseQuery({
    baseUrl: ROOT_URL_LUMEN,
    prepareHeaders: (headers, { getState }) => {
        const lumenToken = getState().auth.lumenToken;
        if (lumenToken) {
            headers.set("Authorization", `Bearer ${lumenToken}`);
        }
        // const keycloakToken = getState().auth.keycloakToken;
        // if (keycloakToken) {
        //     headers.set("Authorization", `Bearer ${keycloakToken}`);
        // }
        return headers;
    },
});

export const lumenApi = createApi({
    reducerPath: "lumenApi",
    baseQuery: baseQuery,
    tagTypes: ["Order", "OrderWeb", "OrderOrigin", "Auth", "DocumentX3", "Product", "ProductWeb", "Reglement", "User", "Role", "X3Orders"],
    endpoints: (build) => ({
        postSendFacture: build.mutation({
            query(body) {
                return {
                    url: "send-facture",
                    method: "POST",
                    body,
                };
            },
            providesTags: ["SendFacture"],
        }),

        postLoginLumen: build.mutation({
            query(body) {
                return {
                    url: "login",
                    method: "POST",
                    body,
                };
            },
            providesTags: ["Auth"],
        }),

        postRefreshToken: build.mutation({
            query(body) {
                return {
                    url: "refresh-token",
                    method: "POST",
                    body,
                };
            },
            providesTags: ["Auth"],
        }),

        postRegisterLumen: build.mutation({
            query(body) {
                return {
                    url: "register",
                    method: "POST",
                    body,
                };
            },
            providesTags: ["Auth"],
        }),

        postDeleteUser: build.mutation({
            query(body) {
                return {
                    url: "delete",
                    method: "DELETE",
                    body,
                };
            },
        }),

        postCheckEmailLumen: build.mutation({
            query(body) {
                return {
                    url: "check-email",
                    method: "POST",
                    body,
                };
            },
        }),

        postForgotPassword: build.mutation({
            query(body) {
                return {
                    url: "forgot-password",
                    method: "POST",
                    body,
                };
            },
        }),

        postSendNewPassword: build.mutation({
            query(body) {
                return {
                    url: "change-password",
                    method: "POST",
                    body,
                };
            },
        }),

        getLogout: build.query({
            query: () => "logout",
        }),
        getRefreshJwt: build.query({
            query: () => "logout",
        }),

        getOrders: build.query({
            query: () => "order",
            transformResponse: (response) => {
                if (response.order.length > 0) {
                    const newData = response.order.map((order) => ({
                        ...order,
                        created_at: isoStrToDate(order.created_at),
                        updated_at: isoStrToDate(order.updated_at),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "Order", id })), { type: "Order", id: "LIST" }] : [{ type: "Order", id: "LIST" }],
        }),

        getNbOrderFails: build.query({
            query: (userId) => `order/failuresOrders/${userId}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getAllOrdersFromUser: build.query({
            query: (id) => `order/histo/${id}`,
            transformResponse: (response) => {
                if (response.order.length > 0) {
                    const newData = response.order.map((order) => ({
                        ...order,
                        created_at: isoStrToDate(order.created_at),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "Order", id })), { type: "Order", id: "LIST" }] : [{ type: "Order", id: "LIST" }],
        }),

        getAllOrdersFromAllUser: build.query({
            query: () => `order/histo-all-user`,
            transformResponse: (response) => {
                if (response.order.length > 0) {
                    const newData = response.order.map((order) => ({
                        ...order,
                        created_at: isoStrToDate(order.created_at),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "Order", id })), { type: "Order", id: "LIST" }] : [{ type: "Order", id: "LIST" }],
        }),

        getOrder: build.query({
            query: (id) => `order/${id}`,
            providesTags: (result, error, id) => [{ type: "Order", id }],
        }),

        // getAddresses: build.query({
        //     query: (param) => `client/getAddresses/${param.clientCode}/${param.isActif}`,

        //     providesTags: (result) =>
        //         result?.adresselivraison
        //             ? [
        //                   result.adresseslivraison.adresselivraison.map(({ numero }) => ({
        //                       type: "Addresse",
        //                       numero,
        //                   })),
        //                   { type: "Addresse", numero: "LIST" },
        //               ]
        //             : [{ type: "Addresse", numero: "LIST" }],

        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        // getClientsBySearch: build.query({
        //     query: (search) => `client/getClientsBySearch/${encodeURIComponent(search)}`,

        //     transformResponse: (response) => {
        //         return response;
        //     },

        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        postOrder: build.mutation({
            query(body) {
                return {
                    url: "order",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [{ type: "Order", id: "LIST" }],
        }),

        getOrderDetails: build.query({
            query: (arg) => `order/orderDetails/${arg.codeClient}/${arg.numeroCommande}`,
            transformResponse: (response) => {
                return response;
            },
            keepUnusedDataFor: 0,
        }),

        // postDocumentX3: build.mutation({
        //     query(body) {
        //         return {
        //             url: "documentX3",
        //             method: "POST",
        //             body,
        //         };
        //     },
        //     invalidatesTags: [{ type: "Order", id: "LIST" }],
        // }),

        updateOrder: build.mutation({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `order/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "Order", id },
                { type: "Order", id: "LIST" },
            ],
        }),

        updateProductStatus: build.mutation({
            query(data) {
                const { puuid, ...body } = data;
                return {
                    url: `product/update-status/${puuid}`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (result, error, { puuid }) => [
                { type: "Product", puuid },
                { type: "Product", puuid: "LIST" },
            ],
        }),

        updateOrderStatus: build.mutation({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `order/update-status/${id}`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "Order", id },
                { type: "Order", id: "LIST" },
            ],
        }),

        updateProductWebStatus: build.mutation({
            query(data) {
                const { puuid, ...body } = data;
                return {
                    url: `productWeb/update-status/${puuid}`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (result, error, { puuid }) => [
                { type: "ProductWeb", puuid },
                { type: "ProductWeb", puuid: "LIST" },
            ],
        }),

        updateOrderWebStatus: build.mutation({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `orderWeb/update-status/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "OrderWeb", id },
                { type: "OrderWeb", id: "LIST" },
            ],
        }),

        updateOrderWebStatusX3Creation: build.mutation({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `orderWeb/update-statusX3Creation/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "OrderWeb", id },
                { type: "OrderWeb", id: "LIST" },
            ],
        }),

        deleteOrder: build.mutation({
            query(id) {
                return {
                    url: `order/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "Order", id },
                { type: "Order", id: "LIST" },
            ],
        }),

        getOrdersWeb: build.query({
            query: (type) => `orderWeb/all/${type}`,
            transformResponse: (response) => {
                if (response.orderWeb.length > 0) {
                    const newData = response.orderWeb.map((orderWeb) => ({
                        ...orderWeb,
                        created_at: isoStrToDate(orderWeb.created_at),
                        updated_at: isoStrToDate(orderWeb.updated_at),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "OrderWeb", id })), { type: "OrderWeb", id: "LIST" }] : [{ type: "OrderWeb", id: "LIST" }],
        }),

        getOrdersWebOneTime: build.query({
            query: (type) => `orderWeb/all/${type}`,
            transformResponse: (response) => {
                if (response.orderWeb.length > 0) {
                    const newData = response.orderWeb.map((orderWeb) => ({
                        ...orderWeb,
                        created_at: isoStrToDate(orderWeb.created_at),
                        updated_at: isoStrToDate(orderWeb.updated_at),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "OrderWeb", id })), { type: "OrderWeb", id: "LIST" }] : [{ type: "OrderWeb", id: "LIST" }],
        }),

        getOrderWeb: build.query({
            query: (id) => `orderWeb/${id}`,
            providesTags: (result, error, id) => [{ type: "OrderWeb", id }],
        }),

        getOrderOrigin: build.query({
            query: (id) => `orderOrigin/${id}`,
            providesTags: (result, error, id) => [{ type: "OrderOrigin", id }],
        }),

        postOrderWeb: build.mutation({
            query(body) {
                return {
                    url: "orderWeb",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [{ type: "OrderWeb", id: "LIST" }],
        }),

        postReglement: build.mutation({
            query(body) {
                return {
                    url: "reglement",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [{ type: "Reglement", id: "LIST" }],
        }),

        lockOrderWeb: build.mutation({
            query(data) {
                const { id } = data;
                return {
                    url: `orderWeb/lock/${id}`,
                    method: "POST",
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "OrderWeb", id },
                { type: "OrderWeb", id: "LIST" },
            ],
        }),

        unlockOrderWeb: build.mutation({
            query(data) {
                const { id } = data;
                return {
                    url: `orderWeb/unlock/${id}`,
                    method: "POST",
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "OrderWeb", id },
                { type: "OrderWeb", id: "LIST" },
            ],
        }),

        updateOrderWeb: build.mutation({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `orderWeb/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "OrderWeb", id },
                { type: "OrderWeb", id: "LIST" },
            ],
        }),

        deleteOrderWeb: build.mutation({
            query(id) {
                return {
                    url: `orderWeb/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "OrderWeb", id },
                { type: "OrderWeb", id: "LIST" },
            ],
        }),

        updatePassword: build.mutation({
            query(body) {
                return {
                    url: "update-password",
                    method: "POST",
                    body,
                };
            },
        }),

        postGetLastOrderIdByUser: build.mutation({
            query(body) {
                return {
                    url: "/order/last-id-by-user",
                    method: "POST",
                    body,
                };
            },
        }),

        getUsers: build.query({
            query: () => "users",
            transformResponse: (response) => {
                if (response?.users?.length > 0) {
                    const newData = response.users.map((user) => ({
                        id: user.id,
                        firstname: user.firstname,
                        lastname: user.lastname,
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "User", id })), { type: "User", id: "LIST" }] : [{ type: "User", id: "LIST" }],
        }),

        getRoles: build.query({
            query: () => "roles",
            transformResponse: (response) => {
                if (response.roles.length > 0) {
                    const newData = response.roles.map((role) => ({
                        id: role.id,
                        name: role.name,
                        slug: role.slug,
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "Role", id })), { type: "Role", id: "LIST" }] : [{ type: "Role", id: "LIST" }],
        }),

        postGetRolesByUser: build.mutation({
            query(body) {
                return {
                    url: "/rolesByUserId",
                    method: "POST",
                    body,
                };
            },
        }),

        addRolesUser: build.mutation({
            query(body) {
                return {
                    url: "/rolesForUser",
                    method: "PUT",
                    body,
                };
            },
        }),

        deleteRolesUser: build.mutation({
            query(body) {
                return {
                    url: "/rolesForUser",
                    method: "DELETE",
                    body,
                };
            },
        }),

        getNbOrdersHandled: build.query({
            query: (dateTime) => `/stat/nbOrders/${dateTime}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getNbOrdersHandledInWeek: build.query({
            query: () => "/stat/nbOrdersOnWeek",
            keepUnusedDataFor: timeInCacheShort,
        }),

        postGetNbOrdersByUser: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/stat/ordersByUsers",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        getNbMaxOrdersHandledInWeek: build.query({
            query: () => `/stat/nbMaxCmdSurUneSemaine`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getNbMaxOrdersHandledInDay: build.query({
            query: () => `/stat/nbMaxCmdSurUneJournee`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        deleteProductWeb: build.mutation({
            query: (id) => {
                return {
                    url: `/productWeb/${id}`,
                    method: "DELETE",
                };
            },
        }),

        addProductWeb: build.mutation({
            query(body) {
                return {
                    url: "/productWeb/store",
                    method: "POST",
                    body,
                };
            },
        }),

        postGetNbOrdersPerDay: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/stat/nbOrdersPerDay",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        postGetNbOrdersPerWeek: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/stat/nbOrdersPerWeek",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        postGetNbOrdersPerHourByUser: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/stat/ordersPerHourByUsers",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        postGetNbProductsByUser: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/stat/productsByUsers",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        postGetNbOrders: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/stat/nbOrders",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        // postArticles: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: "/product/products",
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        getListInQueue: build.query({
            query: (id) => `order/listInQueue/${id}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getDocX3ByOrderId: build.query({
            query: (id) => `documentX3ParCommandeId/${encodeURIComponent(id)}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getDocX3ByOrderWebId: build.query({
            query: (id) => `documentX3ParCommandeWebId/${encodeURIComponent(id)}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        // getInfosClient: build.query({
        //     query: (codeClient) => `/client/getInfosClient/${codeClient}`,
        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        getStatusAccountLaGalaxy: build.query({
            query: (uuid) => `/client/getGalaxyActivationStatus/${uuid}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getSendEmailFirstConnexion: build.query({
            query: (email) => `/client/sendEmailFirstConnexion/${email}/false`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        // getSoldeSmiley: build.query({
        //     query: (suuid) => `/client/getSoldeSmiley/${suuid}`,
        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        // getSoldeComptable: build.query({
        //     query: (codeClient) => `/client/getSoldeComptable/${codeClient}`,
        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        // getUnlockClient: build.query({
        //     query: (codeClient) => `/client/getUnlockClient/${codeClient}`,
        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        // getAllCountry: build.query({
        //     query: () => `/getAllCountry`,
        //     keepUnusedDataFor: timeInCacheShort,
        //     providesTags: (result) =>
        //         result?.credit
        //             ? [
        //                   result.pays.pays.map(({ numero }) => ({
        //                       type: "Pays",
        //                       numero,
        //                   })),
        //                   { type: "Pays", numero: "LIST" },
        //               ]
        //             : [{ type: "Pays", numero: "LIST" }],
        // }),

        // getArticle: build.query({
        //     query: (codeArticle) => `/product/product/${codeArticle}`,
        //     transformResponse: (response) => {
        //         if (response.nbresultats > 0) {
        //             return response.articles.article[0];
        //         }
        //     },
        // }),

        // getCredits: build.query({
        //     query: (codeClient) => `/client/credits/${codeClient}`,
        //     transformResponse: (response) => {
        //         if (response.nbresultats > 0) {
        //             const newArray = response.credits.credit.filter((credit) => credit.libelle !== "OFFERT" && credit.statut === "actif");
        //             return newArray;
        //         }
        //     },
        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        productsBySearch: build.mutation({
            query: (body) => {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/product/productsBySearch",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
            transformResponse: (response) => {
                if (response.success === false) {
                    return response;
                }
                if (response.nbresultats > 0) {
                    return response.articles.article;
                }
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        productsByCode: build.mutation({
            query: (body) => {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/product/productsByCode",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
            transformResponse: (response) => {
                if (response.success === false) {
                    return response;
                }
                if (response.nbresultats > 0) {
                    return response.articles.article;
                }
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        postHandlingOrderDocuments: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/traitementCommandeDocument",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
            invalidatesTags: [{ type: "X3Orders", numero: "LIST" }],
        }),

        postUpdateOrdersX3Status: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/order/updateOrderStatusX3Creation",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
            invalidatesTags: [{ type: "X3Orders", numero: "LIST" }],
        }),

        // postContact: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: "/contact/addContact",
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        importOrder: build.mutation({
            query(body) {
                return {
                    url: "/orderImport/import",
                    method: "POST",
                    body,
                };
            },
        }),

        // getClientsByPhone: build.query({
        //     query: (search) => `/contact/getClientsByPhone/${encodeURIComponent(search)}`,
        //     transformResponse: (response) => {
        //         return response;
        //     },
        //     keepUnusedDataFor: timeInCacheShort,
        // }),

        // getZoneAddress: build.query({
        //     query: (params) => `/client/zoneAddress/${params.customerCode}/${params.codeAddress}`,
        //     transformResponse: (response) => {
        //         return response;
        //     },
        // }),

        // getPaymentMethod: build.query({
        //     query: (nomBaseSAGE) => `/client/paymentMethod/${nomBaseSAGE}`,
        //     transformResponse: (response) => {
        //         return response;
        //     },
        // }),

        // checkAuthorization: build.query({
        //     query: (params) => `/product/authorization/${params.itemCode}/${params.zone}`,
        //     transformResponse: (response) => {
        //         return response;
        //     },
        // }),

        getDBL100: build.query({
            query: () => `/DBL100`,
            transformResponse: (response) => {
                return response;
            },
        }),

        // postOrderByClient: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: "/order/orderByClient",
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        // postAllBillsByDate: build.mutation({
        //     query(body) {
        //         const jsonBody = JSON.parse(decodeURIComponent(body));
        //         return {
        //             url: `/lireFacturesListeParDate/${BASE_BDD_PROD_MIDDLEWARE_API}`,
        //             method: "POST",
        //             body: jsonBody,
        //         };
        //     },
        // }),

        postOrderByNum: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/order/orderByNum",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        // postX3Address: build.mutation({
        //     query(body) {
        //         const jsonBody = JSON.parse(decodeURIComponent(body));
        //         jsonBody.base = BASE_BDD_MIDDLEWARE_API;
        //         return {
        //             url: `/client/postX3Address`,
        //             method: "POST",
        //             body: jsonBody,
        //         };
        //     },
        //     invalidatesTags: [{ type: "Addresses", numero: "LIST" }],
        // }),

        // postSendNotification: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: `/send-notif`,
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        //     invalidatesTags: [{ type: "Addresses", numero: "LIST" }],
        // }),

        // postEmail5: build.mutation({
        //     query(body) {
        //         const jsonBody = JSON.parse(decodeURIComponent(body));
        //         jsonBody.base = BASE_BDD_MIDDLEWARE_API;
        //         return {
        //             url: `/client/postEmail5`,
        //             method: "POST",
        //             body: jsonBody,
        //         };
        //     },
        // }),

        // postGetRepartition: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: `/product/repartition`,
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        // postGetTarifClient: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: `/product/tarifClient`,
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        // postGetX3ExistOrder: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: `/existOrderX3`,
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        //     invalidatesTags: [{ type: "X3Orders", numero: "LIST" }],
        // }),

        // postPricesCustomersFamille: build.mutation({
        //     query(body) {
        //         const jsonBody = JSON.parse(decodeURIComponent(body));
        //         return {
        //             url: `/client/pricesCustomersFamille`,
        //             method: "POST",
        //             body: jsonBody,
        //         };
        //     },
        // }),

        // putTraiterLog: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: "create-log",
        //             method: "PUT",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        // putSettlement: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: "/client/settlement",
        //             method: "PUT",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        // getStatusItem: build.query({
        //     query: (codeArticle) => `/product/statusItem/${codeArticle}`,
        //     transformResponse: (response) => {
        //         return response;
        //     },
        // }),

        searchQuotes: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/quote/search-quotes",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        // readQuoteLines: build.mutation({
        //     query(body) {
        //         const jsonBody = decodeURIComponent(body);
        //         return {
        //             url: "/quote/readQuoteLines",
        //             method: "POST",
        //             body: JSON.parse(jsonBody),
        //         };
        //     },
        // }),

        addQuote: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/quote/add-quote",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        postSendQuote: build.mutation({
            query(body) {
                return {
                    url: "/quote/send-quote",
                    method: "POST",
                    body,
                };
            },
        }),

        // getCarriers: build.query({
        //     query: () => `/order/carrier`,
        //     transformResponse: (response) => {
        //         if (response.success === false) {
        //             return response;
        //         }
        //         if (response.nbResults > 0) {
        //             return response.carriers.carrier;
        //         }
        //     },
        // }),

        // getDefaultCarrier: build.query({
        //     query: (params) => `/order/carrier-default/${params.clientCode}/${params.codeAddress}`,
        // }),

        getCarrierServices: build.query({
            query: (carrierCode) => `/order/carrier-services/${carrierCode}`,
        }),

        orderWebIsLock: build.query({
            query: (id) => `/orderWeb/isLock/${id}`,
        }),

        addCommercialOffer: build.mutation({
            query(body) {
                return {
                    url: "/commercial-offer",
                    method: "POST",
                    body,
                };
            },
        }),

        searchOffers: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/commercial-offer/read-offers",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        postSendOffer: build.mutation({
            query(body) {
                return {
                    url: "/commercial-offer/send-offer",
                    method: "POST",
                    body,
                };
            },
            providesTags: ["SendOffer"],
        }),

        readOfferDetails: build.query({
            query: (id) => `/commercial-offer/read-offer-details/${id}`,
            transformResponse: (response) => {
                return response;
            },
        }),

        quoteDetails: build.query({
            query: (id) => `/quote/quote-details/${id}`,
        }),

        updateQuote: build.mutation({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `/quote/update/${id}`,
                    method: "PUT",
                    body,
                };
            },
        }),

        updateStatusQuote: build.mutation({
            query(body) {
                return {
                    url: "/quote/update-status",
                    method: "POST",
                    body,
                };
            },
        }),

        updateStatusOffer: build.mutation({
            query(body) {
                return {
                    url: "/commercial-offer/update-status",
                    method: "POST",
                    body,
                };
            },
        }),

        updateOffer: build.mutation({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: `/commercial-offer/update/${id}`,
                    method: "PUT",
                    body,
                };
            },
        }),
    }),
});

export const {
    usePostSendFactureMutation,
    // usePutTraiterLogMutation,
    usePostLoginLumenMutation,
    usePostRefreshTokenMutation,
    usePostDeleteUserMutation,
    usePostOrderMutation,
    // usePostOrderByClientMutation,
    usePostOrderByNumMutation,
    useGetOrderQuery,
    useGetOrdersQuery,
    useGetOrderDetailsQuery,
    useGetAllOrdersFromUserQuery,
    // useGetAddressesQuery,
    useGetAllOrdersFromAllUserQuery,
    useUpdateOrderMutation,
    useUpdateProductStatusMutation,
    useUpdateOrderStatusMutation,
    useUpdateProductWebStatusMutation,
    useUpdateOrderWebStatusMutation,
    useDeleteOrderMutation,
    usePostOrderWebMutation,
    usePostReglementMutation,
    useGetOrderWebQuery,
    useGetOrderOriginQuery,
    useGetOrdersWebQuery,
    useGetOrdersWebOneTimeQuery,
    useUpdateOrderWebMutation,
    useLockOrderWebMutation,
    useUnlockOrderWebMutation,
    useDeleteOrderWebMutation,
    usePostRegisterLumenMutation,
    useGetLogoutQuery,
    useUpdatePasswordMutation,
    usePostForgotPasswordMutation,
    usePostSendNewPasswordMutation,
    usePostCheckEmailLumenMutation,
    // usePostDocumentX3Mutation,
    usePostGetLastOrderIdByUserMutation,
    useGetUsersQuery,
    useGetRolesQuery,
    // useGetClientsBySearchQuery,
    usePostGetRolesByUserMutation,
    useAddRolesUserMutation,
    useDeleteRolesUserMutation,
    useGetNbOrdersHandledQuery,
    useGetNbOrdersHandledInWeekQuery,
    usePostGetNbOrdersByUserMutation,
    useGetNbMaxOrdersHandledInWeekQuery,
    useGetNbMaxOrdersHandledInDayQuery,
    useDeleteProductWebMutation,
    useAddProductWebMutation,
    usePostGetNbOrdersPerDayMutation,
    usePostGetNbOrdersPerWeekMutation,
    usePostGetNbOrdersPerHourByUserMutation,
    usePostGetNbProductsByUserMutation,
    usePostGetNbOrdersMutation,
    useGetListInQueueQuery,
    usePostHandlingOrderDocumentsMutation,
    useGetDocX3ByOrderIdQuery,
    useGetDocX3ByOrderWebIdQuery,
    usePostUpdateOrdersX3StatusMutation,
    useUpdateOrderWebStatusX3CreationMutation,
    // usePostArticlesMutation,
    // useGetInfosClientQuery,
    useGetStatusAccountLaGalaxyQuery,
    useGetSendEmailFirstConnexionQuery,
    // usePostAllBillsByDateMutation,
    // useGetSoldeSmileyQuery,
    // useGetSoldeComptableQuery,
    // usePostX3AddressMutation,
    // useGetUnlockClientQuery,
    // useGetAllCountryQuery,
    // usePostSendNotificationMutation,
    // useGetArticleQuery,
    // useGetCreditsQuery,
    useProductsBySearchMutation,
    useProductsByCodeMutation,
    // usePostEmail5Mutation,
    // usePostGetRepartitionMutation,
    // usePostGetTarifClientMutation,
    // usePostGetX3ExistOrderMutation,
    // usePostPricesCustomersFamilleMutation,
    // usePostContactMutation,
    // useGetClientsByPhoneQuery,
    // useCheckAuthorizationQuery,
    // useGetZoneAddressQuery,
    useImportOrderMutation,
    // usePutSettlementMutation,
    // useGetPaymentMethodQuery,
    useGetDBL100Query,
    // useGetStatusItemQuery,
    useGetNbOrderFailsQuery,
    useSearchQuotesMutation,
    // useReadQuoteLinesMutation,
    useAddQuoteMutation,
    usePostSendQuoteMutation,
    // useGetCarriersQuery,
    // useGetDefaultCarrierQuery,
    useGetCarrierServicesQuery,
    useOrderWebIsLockQuery,
    useAddCommercialOfferMutation,
    usePostSendOfferMutation,
    useUpdateQuoteMutation,
    useSearchOffersMutation,
    useUpdateStatusQuoteMutation,
    useUpdateStatusOfferMutation,
    useUpdateOfferMutation,
} = lumenApi;
