import styles from "./customSubmitButton.module.css";

const SubmitButton = (props) => {
    return (
        <button
            id={props.id}
            type={props.type}
            disabled={props.disabled}
            onClick={props.onClick}
            className={`${props.extraClassName}
      ${props.buttonStyle === "dark" ? styles.bannerButtonDark : styles.bannerButtonLight}`}
        >
            {props.buttonText}
        </button>
    );
};

export default SubmitButton;
