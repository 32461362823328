import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { cartSelector, getTotals, updateProduct } from "../../../features/cart/cartSlice";

const CartSelectType = ({ product, inputType }) => {
    // console.log("[INFO] - component CartSelectType is rendered");
    const dispatch = useDispatch();
    const cart = useSelector(cartSelector);

    const initValue = product.grouperepartition != null && product.grouperepartition !== "" ? product.grouperepartition : "";

    const [selectedValue, setSelectedValue] = useState(initValue);

    /* - initialize input - */
    useEffect(() => {
        if (selectedValue === "") {
            if (product.typePossible) {
                product.typePossible.forEach((type) => {
                    if (type.valeurdefault === true && type.grouperepartition === product.grouperepartition) {
                        setSelectedValue(type.grouperepartition);
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* - Handling selected value changes in selectBox - */
    const handleStateChange = (currentProduct, value) => {
        if (value !== selectedValue) {
            setSelectedValue(value);

            const docTypeInArray = cart.cartDocTypes.find((elem) => elem.libelle === value);

            const updatedType = {
                ...currentProduct,
                typedocument: docTypeInArray.code,
                grouperepartition: value,
            };

            dispatch(updateProduct({ product: updatedType, type: "typedocument" }));
            // - Re calculate Total Amount
            dispatch(getTotals());
        }
    };

    let isDisabled = false;
    if (cart?.smiley > 0 || cart.fromQuote || cart.fromOffer) {
        isDisabled = true;
    }
    return (
        <select value={selectedValue} disabled={isDisabled} onChange={(e) => handleStateChange(product, e.target.value)}>
            {/* <option value="" disabled>Séléctionner un type ...</option> */}
            {product.typePossible &&
                product.typePossible.map((elem) => (
                    <option key={Math.random().toString(36).substring(2, 9)} value={elem.grouperepartition}>
                        {elem.grouperepartition}
                    </option>
                ))}
        </select>
    );
};

export default CartSelectType;
