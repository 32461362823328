import { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import CartRowEmpty from "./CartRowEmpty";
import CartRowFull from "./CartRowFull";
import { cartSelector, removeFromCart, getTotals } from "./cartSlice";
import { useDeleteProductWebMutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import styles from "../../styles/cartTable.module.css";
import { updateLastLocation } from "../location/locationSlice";

/* ------------------------------------------------------------------------- */
const CartTable = ({ filter }) => {
    const cart = useSelector(cartSelector);
    const products = cart.cartItems;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const rows = [];

    let initNbEmptyRows = products.length > 7 ? 0 : 7 - products.length;

    if (cart.reglement !== "" || parseInt(cart.smiley, 10) > 0) {
        initNbEmptyRows = 0;
    }
    const [nbEmptyRows, setNbEmptyRows] = useState(initNbEmptyRows);

    const displayEmptyRow = () => {
        for (let index = 0; index < nbEmptyRows; index += 1) {
            rows.push(<CartRowEmpty key={index} filter={filter} />);
        }
        return rows;
    };

    useEffect(() => {
        if (nbEmptyRows > 0) {
            setNbEmptyRows(products.length > 7 ? 0 : 7 - products.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    /* - Handling onClick button Ajouter - */
    const handlePushLine = () => {
        setNbEmptyRows(nbEmptyRows + 1);
    };

    /* - Handling onClick cross icon to remove from cart - */
    const [triggerDelProductWeb] = useDeleteProductWebMutation();

    const handleRemoveFromCart = (product) => {
        if (cart.isOrderWeb && parseInt(cart.smiley, 10) === 0) {
            // - Delete product in product_web BDD table
            if (cart.cartItems.length > 1) {
                triggerDelProductWeb(product.id);
                dispatch(removeFromCart(product));
                dispatch(getTotals());
            } else {
                toast.error(
                    <div>
                        <h4>Suppression impossible</h4>
                        <div>La commande doit contenir au moins un article</div>
                    </div>,
                    {
                        position: "top-center",
                    }
                );
            }
        } else if (cart.isOrderWeb === false) {
            dispatch(removeFromCart(product));
            dispatch(getTotals());
        }
    };

    /* - Handling onClick button Etape suivante - */
    const handleSubmitCart = () => {
        if (products.length > 0) {
            const condition =
                cart.siteSource !== "LDAL" && (!cart.carrier || !cart.carrier.carrierCode || (cart.carrier.carrierCode === "TNT" && !cart.service.serviceCode));
            if (!condition) {
                setIsSubmitting(true);
                dispatch(updateLastLocation(location.pathname));
                navigate("/checkout");
            }
        }
    };

    /* -- Controle tab --------------------------- */

    // Récupérer les éléments de recherche de code et de recherche globale
    const codeSearchInputs = document.getElementsByClassName("codeSearchInput");
    const globalSearchInputs = document.getElementsByClassName("globalSearchInput");

    useEffect(() => {
        const handleKeyTab = (event) => {
            const keyPressed = event.key || event.keyCode;
            if (keyPressed === "Tab") {
                event.preventDefault();

                const currentElement = document.activeElement;

                if (currentElement.classList.contains("codeSearchInput")) {
                    const currentIndex = Array.from(codeSearchInputs).indexOf(currentElement);
                    const nextIndex = (currentIndex + 1) % codeSearchInputs.length;
                    codeSearchInputs[nextIndex].focus();
                } else if (currentElement.classList.contains("globalSearchInput")) {
                    const currentIndex = Array.from(globalSearchInputs).indexOf(currentElement);
                    // si on est sur le dernier élément de la liste, on passe au premier
                    const nextIndex = (currentIndex + 1) % globalSearchInputs.length;
                    globalSearchInputs[nextIndex].focus();
                }
            } else if (keyPressed === "ArrowDown") {
                event.preventDefault();

                const searchResults = document.querySelectorAll(".searchResult");

                if (searchResults.length > 0) {
                    const currentSelected = document.querySelector(".searchResult.selected");
                    if (currentSelected) {
                        currentSelected.classList.remove("selected");
                        currentSelected.style.border = "none";
                    }

                    // Sélectionner l'élément suivant
                    let nextIndex = 0;
                    if (currentSelected) {
                        const currentIndex = Array.from(searchResults).indexOf(currentSelected);
                        nextIndex = (currentIndex + 1) % searchResults.length;
                    }
                    searchResults[nextIndex].classList.add("selected");
                    searchResults[nextIndex].style.border = "solid 1px black";

                    searchResults[nextIndex].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            } else if (keyPressed === "ArrowUp") {
                event.preventDefault();

                const searchResults = document.querySelectorAll(".searchResult");

                if (searchResults.length > 0) {
                    const currentSelected = document.querySelector(".searchResult.selected");

                    if (currentSelected) {
                        currentSelected.classList.remove("selected");
                        currentSelected.style.border = "none";
                    }

                    let prevIndex = 0;
                    if (currentSelected) {
                        const currentIndex = Array.from(searchResults).indexOf(currentSelected);
                        prevIndex = currentIndex - 1;
                        if (prevIndex < 0) {
                            prevIndex = searchResults.length - 1;
                        }
                    } else {
                        prevIndex = searchResults.length - 1;
                    }
                    searchResults[prevIndex].classList.add("selected");
                    searchResults[prevIndex].style.border = "solid 1px black";

                    searchResults[prevIndex].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            } else if (keyPressed === "Enter") {
                event.preventDefault();

                const currentSelected = document.querySelector(".searchResult.selected");

                if (currentSelected) {
                    currentSelected.click();
                }
            } else {
                const searchResults = document.querySelectorAll(".searchResult");

                if (searchResults.length > 0) {
                    const currentSelected = document.querySelector(".searchResult.selected");

                    if (currentSelected) {
                        currentSelected.classList.remove("selected");
                        currentSelected.style.border = "none";
                    }
                }
            }
        };

        document.addEventListener("keydown", handleKeyTab);

        return () => {
            document.removeEventListener("keydown", handleKeyTab);
        };
    }, [codeSearchInputs, globalSearchInputs]);

    /* - display content --------------------------------------------------- */

    return (
        <div className={styles.tableContainer}>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={4}>
                <Grid item xs={12}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="center">
                        <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTableHeader}>
                            {cart.isOrderWeb && cart.siteSource === "LDAL" ? (
                                <>
                                    <Grid item xs={4}>
                                        Produit
                                    </Grid>
                                    <Grid item xs={2}>
                                        Lot
                                    </Grid>
                                    <Grid item xs={2}>
                                        Qté
                                    </Grid>
                                    <Grid item xs={1}>
                                        Stock
                                    </Grid>
                                    <Grid item xs={3}>
                                        Com.
                                    </Grid>{" "}
                                </>
                            ) : (
                                <>
                                    <Grid item xs={5}>
                                        Produit
                                    </Grid>
                                    <Grid item xs={2}>
                                        Qté
                                    </Grid>
                                    <Grid item xs={1}>
                                        Stock
                                    </Grid>
                                    <Grid item xs={3}>
                                        Com.
                                    </Grid>{" "}
                                    <Grid item xs={1}>
                                        Suppr.
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        {products.length > 0 &&
                            products.map((product, index) => <CartRowFull product={product} handleRemoveFromCart={handleRemoveFromCart} key={index} />)}

                        {cart.siteSource !== "LDAL" && displayEmptyRow()}

                        {cart.reglement === "" && parseInt(cart.smiley, 10) === 0 && cart.siteSource !== "LDAL" && (
                            <Grid container direction="row" alignItems="center" className={styles.cartTableRow}>
                                <Grid item xs={1} className={styles.add} onClick={handlePushLine}>
                                    <AddCircleOutlineIcon /> Ajouter
                                </Grid>
                                <Grid item xs={11}></Grid>
                            </Grid>
                        )}

                        <div className={styles.bottomButtons}>
                            <SubmitButton id="next-step-order-button" buttonText="Etape suivante" onClick={handleSubmitCart} disabled={isSubmitting} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default CartTable;
