import { useEffect, useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import LockIcon from "@mui/icons-material/Lock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fr } from "date-fns/locale";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { lumenApi, useGetUsersQuery, useSearchOffersMutation, useUpdateStatusOfferMutation } from "../../../app/services/lumenApi";
import { useGetInfosClientQuery, usePostArticlesMutation, usePutTraiterLogMutation } from "../../../app/services/middleware";
import { authSelector } from "../../../features/auth/authSlice";
import { clearCart, restoreCart, setClient, setFromOffer, setShippingAddress } from "../../../features/cart/cartSlice";
import {
    clearCommercialOffer,
    clearOfferItems,
    commercialOfferSelector,
    restoreItemOffer,
    setDeliveryAddressOffer,
    setFactAddressOffer,
    setIsRecoveryOffer,
    setOfferClient,
    setOfferId,
    setOfferReference,
} from "../../../features/commercialOffer/commercialOfferSlice";
import { updateLastLocation } from "../../../features/location/locationSlice";
import SelectClientOrder from "../../../features/order/SelectClientOrder";
import styles from "../../../styles/searchOffer.module.css";
import CustomSubmitButton from "../../components/buttons/CustomSubmitButton";
import PopUpSendOffer from "../../components/PopUpOffer/PopUpSendOffer";
import { ENV } from "../../utils/apiConstants";
import { seedInfoErrorOccured, strDatetimeFrFormat, toastError } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */

const SearchOffer = () => {
    const fileName = "SearchOffer.jsx";
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const commercialOffer = useSelector(commercialOfferSelector);
    const auth = useSelector(authSelector);
    const [clientSelected, setClientSelected] = useState("");

    // - api service called when component is rendered ------------------------
    const resGetClientInfos = useGetInfosClientQuery(clientSelected.clientCode);
    const resGetUsers = useGetUsersQuery();
    // ------------------------------------------------------------------------
    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);

    const [rows, setRows] = useState([]);

    const [selection, setSelection] = useState([]);
    const [tabUsers, setTabUsers] = useState([]);

    const [selectedAddrEmail, setSelectedAddrEmail] = useState(""); // selected client address email

    const [clickAction, setClickAction] = useState(null);

    const [pageSize, setPageSize] = useState(14);
    const [openSent, setOpenSent] = useState(false);

    const [triggerOffers, resOffers] = useSearchOffersMutation();
    const [triggerLog] = usePutTraiterLogMutation();
    const [triggerArticle] = usePostArticlesMutation();
    const [triggerUpdateStatusOffer] = useUpdateStatusOfferMutation();

    const [triggerReadOfferDetails, resReadOfferDetails] = lumenApi.endpoints.readOfferDetails.useLazyQuery();

    const msgRecovery = "Etes-vous sûr de vouloir reprendre cette offre ?";
    const msgOrder = "Etes-vous sûr de vouloir créer une commande depuis cette offre ?";

    /* --------------------------------------------------------------------------
     * - function to call triggerArticle for each product of the orderweb
     * ----------------------------------------------------------------------- */
    const getItemsDetails = () => {
        resReadOfferDetails.data.items.item.forEach(async (product, index) => {
            const body = {
                article: product.reference,
            };

            const bodyParam = encodeURIComponent(JSON.stringify(body));
            await triggerArticle(bodyParam)
                .unwrap()
                .then((response) => {
                    const item = {
                        ...product,
                        designation: response.articles.article[0].designation,
                        unitedevente: response.articles.article[0].unitedevente,
                        catalogueniv1: response.articles.article[0].catalogueniv1,
                        catalogueniv2: response.articles.article[0].catalogueniv2,
                        catalogueniv3: response.articles.article[0].catalogueniv3,
                        catalogueniv4: response.articles.article[0].catalogueniv4,
                        index: index,
                        indexInCart: index, // required to create order from commercial offer
                        quantity: product.quantity, // required to create order from an commercial offer
                        remise1montant: product.discount_amount_1, // required to create order from an commercial offer
                    };

                    if (clickAction === "createOrder") {
                        dispatch(restoreCart(item));
                    } else {
                        dispatch(restoreItemOffer(item));
                    }
                });
        });
    };

    // -- Handle click icon to set commercial offer status as validated by client
    const handleUpdateStatus = (params) => {
        const bodyParam = {
            offer_id: params.row.id,
            status: 2,
        };
        triggerUpdateStatusOffer(bodyParam)
            .unwrap()
            .then((response) => {
                // Mettre à jour le statut de la ligne dans le tableau
                const updatedRow = { ...params.row, status: 2 };
                const updatedRowsCopy = [...rows]; // Utilisez `rows` au lieu de `updatedRows`
                const rowIndex = updatedRowsCopy.findIndex((row) => row.id === params.row.id);
                updatedRowsCopy[rowIndex] = updatedRow;
                setRows(updatedRowsCopy); // Mettez à jour `rows`
            });
    };

    const mapStatusToDescription = (status) => {
        switch (status) {
            case 0:
                return "En cours de création";
            case 1:
                return "En attente de validation client";
            case 2:
                return "Accepté par le client";
            case 3:
                return "en file d'attente...";
            case 4:
                return "Succès";
            case 5:
                return "Céation de commande en cours";
            case -1:
                return "Erreur";
            default:
                return "Statut inconnu";
        }
    };

    let formDateDebut = "";
    let formDateFin = "";

    if (dateDebut !== null) {
        formDateDebut = `${dateDebut.getFullYear()}-${`0${dateDebut.getMonth() + 1}`.slice(-2)}-${`0${dateDebut.getDate()}`.slice(-2)}`;
    }

    if (dateFin !== null) {
        formDateFin = `${dateFin.getFullYear()}-${`0${dateFin.getMonth() + 1}`.slice(-2)}-${`0${dateFin.getDate()}`.slice(-2)}`;
    }

    /* --------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(clearOfferItems());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resOffers.status === "fulfilled") {
            setRows([]);
            if (resOffers.data.success === true && resOffers.data.nbResults !== 0) {
                setRows(resOffers.data.commercial_offers.commercial_offer);
            }
        }
    }, [resOffers]);

    useEffect(() => {
        if (resGetUsers.data !== undefined && resGetUsers.status === "fulfilled") {
            setTabUsers(resGetUsers.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetUsers]);

    useEffect(() => {
        if (resReadOfferDetails.status === "fulfilled") {
            if (resReadOfferDetails.data.success === false) {
                const msgError = "Une erreur s'est produite lors de la reprise de l'offre. Merci de contacter l'assistance.";
                toastError(msgError, 7000);

                // - send log error
                const errorInfos = seedInfoErrorOccured(fileName, auth.user.email, msgError);
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            } else {
                getItemsDetails(); // call getInfoArticle foreach offerItems

                dispatch(setFromOffer(true));
                if (clickAction === "createOrder") {
                    // prepare all requiered data to go on page order
                    dispatch(setClient(commercialOffer.offerClient));
                    dispatch(setShippingAddress(resReadOfferDetails.data.delivery.numero));
                    dispatch(updateLastLocation(location.pathname));
                    navigate("/order");
                } else {
                    // - update commercialOffer in redux store
                    dispatch(setOfferReference(resReadOfferDetails.data.reference));
                    dispatch(setDeliveryAddressOffer(resReadOfferDetails.data.delivery.numero));
                    dispatch(setFactAddressOffer(resReadOfferDetails.data.billing.code));
                    navigate("/add-commercial-offer");
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resReadOfferDetails]);

    useEffect(() => {
        if (commercialOffer.hasOwnProperty("offerClient")) {
            setSelectedAddrEmail(commercialOffer.offerClient.defaultEmailAddress);
        }
    }, [commercialOffer]);

    /* - useEffects ---------------------------------------------------------- */
    useEffect(() => {
        if (resGetClientInfos.status === "fulfilled" && resGetClientInfos?.data.success === true) {
            if (resGetClientInfos.originalArgs !== undefined) {
                setSelectedAddrEmail(resGetClientInfos.data.client.facturation.email1);
            }
        }
    }, [resGetClientInfos]);

    useEffect(() => {
        const deb = new Date(formDateDebut);
        const fin = new Date(formDateFin);
        if (deb > fin) {
            setDateFin(deb);
            setDateDebut(fin);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateDebut, dateFin]);

    /* ----------------------------------------------------------------------- */

    const stringDateComparator = (str1, str2) => {
        return Date.parse(str1) > Date.parse(str2);
    };

    // let errorInfos = {
    //     service: "espaceADV",
    //     environment: ENV,
    //     userEmail: auth.user.email,
    //     code: 400,
    // };

    const uri =
        ENV === "PROD"
            ? "https://api.web.groupe-upperside.com"
            : ENV === "DEV"
            ? "https://api-dev.web.groupe-upperside.com"
            : "http://api-local.web.groupe-upperside.com";

    /* --------------------------------------------------------------------- */
    const handleClickOnSearch = () => {
        if (clientSelected !== "") {
            let bodyParam = {
                client_code: clientSelected.clientCode,
                start_date: formDateDebut,
                end_date: formDateFin,
            };

            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
            triggerOffers(bodyParam);
        } else {
            toastError("Merci de sélectionner un client pour la recherche", 3000);
        }
    };

    const handleCloseSent = () => {
        setOpenSent(false);
    };

    /* --------------------------------------------------------------------- */
    const handleClickRestart = (params) => {
        dispatch(clearCommercialOffer());

        dispatch(setIsRecoveryOffer(true));
        dispatch(setOfferId(params.row.id));
        dispatch(
            setOfferClient({
                clientCode: params.row.client_code,
                client: params.row.social_reason,
                codepostal: params.row.zip_code,
            })
        );

        // get details offer
        triggerReadOfferDetails(params.row.id);
    };

    const handleRestartOrder = (params) => {
        dispatch(clearCart());

        dispatch(setIsRecoveryOffer(true));
        dispatch(setOfferId(params.row.id));
        dispatch(
            setOfferClient({
                clientCode: params.row.client_code,
                client: params.row.social_reason,
                codepostal: params.row.zip_code,
            })
        );

        // get details offer
        triggerReadOfferDetails(params.row.id);
    };

    const handleSubmitSelectionMultiple = () => {
        setOpenSent(true);
    };

    /* --------------------------------------------------------------------- */

    function confirmPopup(params, msgAction, action) {
        setClickAction(action);
        if ((params.row.status === 0 && params.row.user_id !== auth.user.id) || (params.row.status === 5 && params.row.user_id !== auth.user.id)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    const userObject = tabUsers.find((element) => element.id === params.row.user_id);
                    return (
                        <div className={styles.customUI}>
                            <div className={styles.beatsWarning}>
                                <WarningAmberIcon className={styles.warning} />
                            </div>
                            <span className={styles.titleWarning}>Risque de doublon</span>

                            <p>
                                L&#39;utilisateur {userObject.lastname} {userObject.firstname} est à l&#39;initiative de la création de cette offre commerciale.{" "}
                                <br />
                                {msgAction}
                            </p>
                            <button onClick={onClose}>Annuler</button>
                            {msgAction === msgRecovery ? (
                                <button
                                    onClick={() => {
                                        handleClickRestart(params);
                                        onClose();
                                    }}
                                >
                                    Valider
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        handleRestartOrder(params);
                                        onClose();
                                    }}
                                >
                                    Valider
                                </button>
                            )}
                        </div>
                    );
                },
            });
        } else {
            const condition = msgAction === msgRecovery;
            if (condition) {
                handleClickRestart(params);
            } else {
                handleRestartOrder(params);
            }
        }
    }
    /* --------------------------------------------------------------------- */
    const columns = [
        {
            field: "lock",
            headerName: "",
            sortable: false,
            width: 24,
            maxWidth: 24,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => <LockIcon className={params.row.status === 0 || params.row.status === 5 ? styles.lockOrder : styles.none} />,
        },
        {
            field: "ident",
            headerName: "Identifiant",
            headerClassName: "tableHeader",
            cellClassName: "orderNumber",
            flex: 1,
            headerAlign: "center",
            valueGetter: (params) => params.row.ident,
        },
        {
            field: "status",
            headerName: "Statut",
            headerClassName: "tableHeader",
            cellClassName: "status",
            flex: 1,
            minWidth: 170,
            headerAlign: "center",
            align: "left",
            valueGetter: (params) => mapStatusToDescription(params.value),
        },

        // {
        //     field: "zip_code",
        //     headerName: "Code Postal",
        //     headerClassName: "tableHeader",
        //     cellClassName: "invoiceNumber",
        //     flex: 1,
        //     headerAlign: "center",
        //     align: "center",
        //     valueGetter: (params) => params.row.zip_code,
        // },
        {
            field: "created_at",
            headerName: "Date de  création",
            headerClassName: "tableHeader",
            sortingOrder: ["asc", "desc"],
            flex: 1,
            headerAlign: "center",
            align: "center",
            type: "string",
            valueGetter: (params) => params.row.created_at,
            valueFormatter: (params) => strDatetimeFrFormat(params.value),
            sortComparator: stringDateComparator,
        },
        {
            field: "recovery",
            headerName: "Editer",
            headerClassName: "tableHeader",
            cellClassName: "recovery",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status < 2) {
                    const action = "editOffer";
                    return <RestartAltIcon className={`restart ${styles.icon}`} onClick={() => confirmPopup(params, msgRecovery, action)} />;
                }
            },
        },
        {
            field: "pdf",
            headerName: "Voir PDF",
            headerClassName: "tableHeader",
            cellClassName: "pdf",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <PictureAsPdfIcon className={`pdf ${styles.see}`} onClick={() => window.open(`${uri}/commercial-offer/pdf/${params.row.ident}`)} />
            ),
        },
        {
            field: "Validated",
            headerName: "Validation client",
            headerClassName: "tableHeader",
            cellClassName: "Validated",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status === 1) {
                    return <PublishedWithChangesIcon className={`validate ${styles.icon}`} onClick={() => handleUpdateStatus(params)} />;
                }
                if (params.row.status === 2) {
                    return <DoneIcon className={`done ${styles.icon}`} />;
                }
            },
        },
        {
            field: "order",
            headerName: "Créer une commande",
            flex: 1,
            headerAlign: "center",
            headerClassName: "tableHeader",
            cellClassName: "duplicate",
            align: "center",
            renderCell: (params) => {
                if (params.row.status >= 2) {
                    const action = "createOrder";
                    return <ShoppingCartCheckoutIcon className={styles.icon} onClick={() => confirmPopup(params, msgOrder, action)} />;
                }
            },
        },
    ];

    /* -- display content -------------------------------------------------- */
    if (isOnline) {
        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3}>
                {" "}
                <div>
                    <h2 id="title-search-offer" className={styles.title}>
                        Offres Commerciales
                    </h2>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={8} id="selectClient">
                        <SelectClientOrder setClient={setClientSelected} noClear />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Début"
                                format="dd/MM/yyyy"
                                value={dateDebut}
                                onChange={(newValue) => {
                                    setDateDebut(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Fin"
                                format="dd/MM/yyyy"
                                value={dateFin}
                                onChange={(newValue) => {
                                    setDateFin(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <div className={styles.selectedClient}>
                    <div id="clientselected" className={styles.selectedClientLeft}>
                        Client sélectionné: {clientSelected?.clientCode} - {clientSelected?.client}
                    </div>
                </div>
                <br />
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <CustomSubmitButton id="btnSearchOffer" buttonText="Rechercher" onClick={handleClickOnSearch} />
                    </Grid>

                    <Grid item xs={4}></Grid>

                    <Grid item xs={3}>
                        <div>
                            {resGetClientInfos.status === "pending" ? (
                                ""
                            ) : resGetClientInfos.data.nbresultats === 0 ? (
                                ""
                            ) : (
                                <span>
                                    <select
                                        value={`${selectedAddrEmail}`}
                                        onChange={(e) => {
                                            setSelectedAddrEmail(e.target.value);
                                        }}
                                        className={styles.selectMailClient}
                                    >
                                        {resGetClientInfos.data.client.facturation.email1 === "" ? (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={"Pas d'adresse de facturation"}>
                                                Pas d&#39;adresse de facturation
                                            </option>
                                        ) : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email1}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email1}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email2 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email2}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email2}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email3 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email3}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email3}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email4 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email4}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email4}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email5 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email5}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email5}
                                            </option>
                                        )}
                                    </select>
                                </span>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={3} className={styles.selectedClientRight}>
                        {resGetClientInfos.status === "pending" ? (
                            ""
                        ) : resGetClientInfos.data.nbresultats === 0 ? (
                            ""
                        ) : (
                            <CustomSubmitButton
                                id="sendEmailOffer"
                                buttonText="Envoyer les offres sélectionnées par email"
                                onClick={handleSubmitSelectionMultiple}
                                disabled={!selection.length}
                            />
                        )}
                    </Grid>
                </Grid>
                <br />
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "created_at", sort: "desc" }],
                            },
                        }}
                        getRowId={(row) => row.id}
                        rows={rows}
                        columns={columns}
                        rowHeight={36}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[14, 25, 50]}
                        onRowSelectionModelChange={(e) => {
                            setSelection(e);
                        }}
                        rowSelectionModel={selection}
                        sortingOrder={["asc", "desc"]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </div>
                <Dialog open={openSent} onClose={handleCloseSent} maxWidth="xl">
                    <div className={styles.grey}>
                        {selection.length > 0 && (
                            <PopUpSendOffer clientCode={clientSelected.clientCode} selectedEmail={selectedAddrEmail} listOfferId={selection} />
                        )}
                    </div>
                    <DialogActions className={styles.grey}>
                        <Button onClick={handleCloseSent}>Fermer</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
    return <Offline />;
};

export default SearchOffer;
