import { useState, useRef, useEffect } from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Field, Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import { useImportOrderMutation } from "../../../app/services/lumenApi";
import { clearCartItems, restoreCart, setIsOrderImport } from "../../../features/cart/cartSlice";
import styles from "../../../styles/orderExcelInput.module.css";
import SubmitButton from "../../components/buttons/SubmitButton";

/* ------------------------------------------------------------------------- */
const OrderExcelInput = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [triggerImport, resultImport] = useImportOrderMutation();
    const acceptableFileTypes = ["xlsx", "xls"];
    const [isValid, setIsValid] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState(null);
    const [datas, setDatas] = useState([]);
    const fileRef = useRef(null);

    // file.extension
    const checkFileType = (name) => {
        return acceptableFileTypes.includes(name.split(".").pop().toLowerCase());
    };

    const handleFile = (e) => {
        const myFile = e.target.files[0];
        if (!myFile) return;

        if (myFile && !checkFileType(myFile.name)) {
            toast.error("Le fichier n'est pas au bon format .xlsx ou .xls");
            setIsValid(false);
            return;
        }
        setFile(myFile);
        setFileName(myFile.name);
        setIsValid(true);
    };

    const handleRemove = () => {
        setFile(null);
        setFileName(null);
        fileRef.current.value = "";
        setIsValid(false);
    };

    const onSubmit = async () => {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 2,
            defval: "",
            raw: false,
        });
        if (jsonData.length === 0) {
            toast.error("Le fichier excel est vide");
        }
        setDatas(jsonData);
    };

    useEffect(() => {
        if (datas.length > 0) {
            triggerImport(datas);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datas]);

    useEffect(() => {
        if (resultImport.status === "fulfilled") {
            if (resultImport.data.nbresultats > 0) {
                dispatch(clearCartItems());
                resultImport.data.articles.article.forEach((elem) => {
                    const product = {
                        ...elem,
                        puuid: uuidv4(),
                    };
                    dispatch(setIsOrderImport(true));
                    dispatch(restoreCart(product));
                });
                navigate("/checkout");
                toast.success("Excel importée avec succès");
            } else {
                toast.error(resultImport.data.message);
            }
        } else if (resultImport.status === "rejected") {
            toast.error("Erreur lors de l'importation. Veuillez insérer un fichier excel valide");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultImport]);

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Field name="label" type="file">
                        {({ input }) => (
                            <div>
                                <input id="filename" type="file" accept=".xlsx, .xls" multiple={false} onChange={(e) => handleFile(e)} ref={fileRef} />
                                {fileName && (
                                    <span onClick={handleRemove} className={styles.deleteIcon}>
                                        <DeleteForeverIcon />
                                    </span>
                                )}
                                {isValid && (
                                    <span className={styles.btnImport}>
                                        <SubmitButton buttonText="importer" type="submit" buttonStyle="little" />
                                    </span>
                                )}
                            </div>
                        )}
                    </Field>
                </form>
            )}
        />
    );
};

export default OrderExcelInput;
