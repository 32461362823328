import { useGetAddressesQuery } from "../../../app/services/middleware";
import AddressRow from "../../../features/addresses/AddressRow";
import styles from "../../../styles/addQuote.module.css";
import Offline from "../../pages/Offline";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";

const InfosFact = ({ client, factAddress }) => {
    const isOnline = useOnlineStatus();

    const param = {
        clientCode: client.clientCode,
        isActif: "Oui",
    };

    const resGetAllAddress = useGetAddressesQuery(param);
    const selectedShippingAddress = resGetAllAddress.data?.adresseslivraison.adresselivraison.find((address) => address.numero === factAddress);

    if (isOnline) {
        return <div className={styles.blockWhite}>{selectedShippingAddress && <AddressRow item={selectedShippingAddress} />}</div>;
    }
    return <Offline />;
};

export default InfosFact;
