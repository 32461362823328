import { useEffect, useState } from "react";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import styles from "./scrollToTopButton.module.css";

/* ------------------------------------------------------------------------- */

const ScrollToTopButton = (props) => {
    // console.log("component ScrollToTopButton is render ...");

    const [showButton, setShowButton] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const [targetComponent, setTargetComponent] = useState(document.getElementById(props.targetElement));

    const handleClick = (e) => {
        e.preventDefault();
        targetComponent.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleScroll = (component) => {
        setScrollPosition(component.scrollTop);
    };
    /* ----------------------------------------------------------------------- */

    useEffect(() => {
        if (targetComponent != null) {
            targetComponent.addEventListener("scroll", (event) => handleScroll(targetComponent));

            return () => {
                targetComponent.removeEventListener("scroll", handleScroll);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (document.getElementById(props.targetElement) != null) {
            const target = document.getElementById(props.targetElement);
            target.addEventListener("scroll", (event) => handleScroll(target));
            setTargetComponent(target);

            return () => {
                target.removeEventListener("scroll", handleScroll);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.getElementById(props.targetElement)]);

    useEffect(() => {
        setShowButton(scrollPosition > 400);
    }, [scrollPosition]);

    /* ----------------------------------------------------------------------- */

    return (
        <KeyboardArrowUpIcon
            className={styles.scrollTop}
            onClick={(e) => handleClick(e)}
            fontSize="large"
            style={{ height: 40, width: 40, display: showButton ? "flex" : "none" }}
        />
    );
};

export default ScrollToTopButton;
