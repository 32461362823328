import { useEffect } from "react";

import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import "../../../styles/react-confirm-alert.css";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaidIcon from "@mui/icons-material/Paid";
import SaveIcon from "@mui/icons-material/Save";
import { Grid } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { usePostRefreshTokenMutation } from "../../../app/services/keycloakApi";
import { useAddCommercialOfferMutation, useUpdateOfferMutation } from "../../../app/services/lumenApi";
import { useGetAddressesQuery, usePutTraiterLogMutation } from "../../../app/services/middleware";
import AddressRow from "../../../features/addresses/AddressRow";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import CheckoutOfferTable from "../../../features/commercialOffer/CheckoutOfferTable";
import { clearCommercialOffer, commercialOfferSelector } from "../../../features/commercialOffer/commercialOfferSlice";
import styles from "../../../styles/checkoutQuote.module.css";
import InfosClientCredit from "../../components/infosClient/InfosClientCredit";
import InfosFact from "../../components/infosClient/InfosFact";
import Spinner from "../../components/spinner/Spinner";
import { ENV } from "../../utils/apiConstants";
import { getFormDataToRefreshKeycloakToken, toastError, toastSuccess } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const CheckoutCommercialOffer = () => {
    const isOnline = useOnlineStatus();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const commercialOffer = useSelector(commercialOfferSelector);
    const auth = useSelector(authSelector);

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();
    const [triggerAddOffer, resTriggerAddOffer] = useAddCommercialOfferMutation();
    const [triggerUpdateOffer, resTriggerUpdateOffer] = useUpdateOfferMutation();

    const param = {
        clientCode: commercialOffer.offerClient.clientCode,
        isActif: "Oui",
    };

    const { data, isFetching } = useGetAddressesQuery(param);

    const selectedShippingAddress = data?.adresseslivraison.adresselivraison.find((address) => address.numero === commercialOffer.deliveryAddressOffer);

    const handleModifyOffer = () => {
        const elem = document.getElementById("modifyOfferLink");
        if (elem.getAttribute("disabled") !== "true") {
            navigate("/add-commercial-offer");
        }
    };

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const handleClearOffer = () => {
        dispatch(clearCommercialOffer());
        navigate("/add-commercial-offer");
    };

    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilisateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.className = "CheckoutCommercialOffer.js - event onClick link Modifier";
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);

    useEffect(() => {
        if (resTriggerAddOffer.status === "rejected" || resTriggerUpdateOffer.status === "rejected") {
            toastError("Une erreur s'est produite lors de la création de l'offre commerciale.", 7000);
        }
        if (resTriggerAddOffer.status === "fulfilled") {
            if (resTriggerAddOffer.data?.success === true) {
                toastSuccess("L'offre commerciale a été sauvegardée avec succès", 5000);
                handleClearOffer();
            } else {
                toastError("Une erreur s'est produite lors de la sauvegarde de l'offre commerciale", 5000);
            }
        }

        if (resTriggerUpdateOffer.status === "fulfilled") {
            if (resTriggerUpdateOffer.data?.success === true) {
                toastSuccess("L'offre commerciale a été sauvegardée avec succès", 5000);
                handleClearOffer();
            } else {
                toastError("Une erreur s'est produite lors de la sauvegarde de l'offre commerciale", 5000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerAddOffer, resTriggerUpdateOffer]);

    const msgDelete = "Êtes-vous sûr de vouloir supprimer la saisie en cours ?";

    function confirmPopup(message) {
        // - Check user time session is not expired
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <h2>Confirmer</h2>
                        <p>{message}</p>
                        <button onClick={onClose}>Refuser</button>
                        <button
                            onClick={() => {
                                handleClearOffer();
                                onClose();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    if (isFetching) {
        return <Spinner />;
    }

    function handleSaveOffer() {
        // - Check user time session is not expired
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);

        if (commercialOffer.offerReference === "") {
            toastError("Merci de saisir une référence", 5000);
            return;
        }

        if (
            typeof commercialOffer.offerClient === "object" &&
            commercialOffer.offerClient !== null &&
            commercialOffer.deliveryAddressOffer !== "" &&
            commercialOffer.offerItems.length > 0
        ) {
            const body = {
                reference: commercialOffer.offerReference,
                client_code: commercialOffer.offerClient.clientCode,
                social_reason: commercialOffer.offerClient.client,
                zip_code: commercialOffer.offerClient.codepostal,
                shipping_address: commercialOffer.deliveryAddressOffer,
                status: 0,
                currency: commercialOffer.offerClient.devise,
                amount_ht: commercialOffer.totalAmountCheckout, // Default value actually, waiting Jo answer
                amount_ttc: commercialOffer.totalAmountCheckout,
                items: [
                    ...commercialOffer.offerItems.map((item) => ({
                        id: item.id,
                        reference: item.reference,
                        label: item.designation,
                        custom_label: item.custom_label,
                        quantity: item.quantity,
                        discount_amount_1: parseFloat(item.discount_amount_1),
                        discount_justification_1: null,
                        discount_amount_2: null,
                        discount_justification_2: null,
                        credit_amount: item.credit_amount ? parseFloat(item.credit_amount) : 0.0,
                        tax_code: "001",
                        tax_descritpion: "FRA TVA Taux Normal",
                        tax_rate: 20,
                        unit_price_brut_base: item.unit_price_brut_base ? parseFloat(item.unit_price_brut_base) : 0,
                        unit_price_brut: item.unit_price_brut ? parseFloat(item.unit_price_brut) : 0,
                        unit_price_net: item.unit_price_net ? parseFloat(item.unit_price_net) : 0,
                        comment: item.comment,
                    })),
                ],
            };

            if (!commercialOffer.isRecoveryOffer) {
                triggerAddOffer(body);
            } else {
                triggerUpdateOffer({
                    id: commercialOffer.offerId,
                    ...body,
                });
            }
        } else {
            toastError("L'offre commerciale ne peut pas être sauvegardée car elle est vide.", 5000);
        }
    }

    if (isFetching) {
        return <Spinner />;
    }
    /* -- display content -------------------------------------------------- */
    if (isOnline) {
        return (
            <>
                {/* - Icons action on top right */}
                <div onClick={() => confirmPopup(msgDelete)} className={`${styles.tooltipBtn} ${styles.deleteBtn}`}>
                    <DeleteIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Supprimer la saisie en cours</div>
                </div>
                <div onClick={handleSaveOffer} className={`${styles.tooltipBtn} ${styles.saveBtn}`}>
                    <SaveIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Sauvegarder la saisie en cours</div>
                </div>
                {/* --------------------------------------- */}
                <div>
                    <div>
                        <span id="modifyOfferLink" className={styles.goBack} onClick={handleModifyOffer}>
                            <ArrowBackIosNew />
                            <span>Modifier l&#39;offre</span>
                        </span>
                    </div>
                    <Grid container direction="row" spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                            <InfosClientCredit client={commercialOffer.offerClient} />
                        </Grid>
                        <Grid item xs={6}>
                            <h3>
                                <PaidIcon fontSize="medium" className={styles.adjustPos} />
                                Adresse de facturation
                            </h3>
                            <InfosFact client={commercialOffer.offerClient} factAddress={commercialOffer.factAddressOffer} />
                        </Grid>
                        <Grid item xs={6}>
                            <h3>
                                <LocationOnIcon fontSize="medium" className={styles.adjustPos} />
                                Adresse de livraison
                            </h3>
                            <div className={styles.livraisonInfos}>
                                <AddressRow item={selectedShippingAddress} />
                            </div>
                            <div className={styles.blockInput}>
                                <LocalOfferIcon className={styles.adjustPos} />
                                <span>Référence de l&#39;offre : </span>
                                {commercialOffer.offerReference}
                            </div>
                        </Grid>
                        <CheckoutOfferTable />
                    </Grid>
                </div>
            </>
        );
    }
    return <Offline />;
};

export default CheckoutCommercialOffer;
