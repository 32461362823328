import { useEffect, useState } from "react";

// eslint-disable-next-line import/order
import { useSelector, useDispatch } from "react-redux";

/* Import react-circular-progressbar module and styles */
import "react-circular-progressbar/dist/styles.css";

// eslint-disable-next-line import/order
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import "../../../styles/react-confirm-alert.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, Grid, CardContent } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";

import {
    useGetNbOrdersHandledQuery,
    useGetNbMaxOrdersHandledInWeekQuery,
    useGetNbMaxOrdersHandledInDayQuery,
    usePostGetNbOrdersMutation,
    useGetNbOrdersHandledInWeekQuery,
} from "../../../app/services/lumenApi";
import { usePutTraiterLogMutation } from "../../../app/services/middleware";
import { authSelector } from "../../../features/auth/authSlice";
import {
    setLabelSelection,
    setRefreshTableOrderByUser,
    setSearchOnDuration,
    setSearchOnSpecificDay,
    setSelectedDay,
    statSelector,
} from "../../../features/stat/statSlice";
import styles from "../../../styles/stats.module.css";
import ChartDataGraph from "../../components/chartDataGraph/ChartDataGraph";
import MeterChart from "../../components/meterChart/MeterChart";
import TableOrderByDay from "../../components/tableOrderByDay/TableOrderByDay";
import TableOrderByUser from "../../components/tableOrderByUser/TableOrderByUser";
import TableOrderByWeek from "../../components/tableOrderByWeek/TableOrderByWeek";
import TableOrderPerHourByUser from "../../components/tableOrderPerHourByUser/TableOrderPerHourByUser";
import TableProductByUser from "../../components/tableProductByUser/TableProductByUser";
import { ENV } from "../../utils/apiConstants";
import { countOrdersPerHalfHour, countWebOrdersPerHalfHour, strDateMinusOne, strDatePlusOne, elegantStyleDate } from "../../utils/helperFunctions";

/* ------------------------------------------------------------------------- */
const Stats = () => {
    const auth = useSelector(authSelector);
    const stat = useSelector(statSelector);

    const dispatch = useDispatch();

    // - For cards section
    const [nbMaxPerWeek, setNbMaxPerWeek] = useState(0);
    const [nbMaxPerDay, setNbMaxPerDay] = useState(0);
    const [topDay, setTopDay] = useState("");
    const [topWeek, setTopWeek] = useState("");

    const [nbCmdToday, setNbCmdToday] = useState(0);
    const [nbCmdWeek, setNbCmdWeek] = useState(0);
    const [avgWeek, setAvgWeek] = useState(0);

    const resNbMaxPerWeek = useGetNbMaxOrdersHandledInWeekQuery();
    const resNbMaxPerDay = useGetNbMaxOrdersHandledInDayQuery();
    const resNbOrdersToday = useGetNbOrdersHandledQuery(stat.selectedDay);
    const resNbOrdersInWeek = useGetNbOrdersHandledInWeekQuery();

    // - For Chart Graph
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },

            title: {
                display: false,
                text: "titre display false",
            },
        },
    };

    const [chartData, setChartData] = useState(null);

    const [triggerPostGetAllOrdersForChart, result] = usePostGetNbOrdersMutation();

    /* ----------------------------------------------------------------------- */
    // -  traitement onClick button PRECEDENT au niveau du graph
    const onClickDayNext = () => {
        const dateValue = strDatePlusOne(stat.selectedDay);
        dispatch(setSelectedDay(dateValue));
        dispatch(setSearchOnSpecificDay(true));
        dispatch(setRefreshTableOrderByUser(true));
        dispatch(setLabelSelection(`Chiffres réalisés pour la journée du ${elegantStyleDate(dateValue)}`));
    };

    // - traitement onClick button SUIVANT au niveau du graph
    const onClickDayPrev = () => {
        const dateValue = strDateMinusOne(stat.selectedDay);
        dispatch(setSelectedDay(dateValue));
        dispatch(setSearchOnSpecificDay(true));
        dispatch(setRefreshTableOrderByUser(true));
        dispatch(setLabelSelection(`Chiffres réalisés pour la journée du ${elegantStyleDate(dateValue)}`));
    };

    /* --------------------------------------------------------------------------
     * - declarations for chart graph orders
     * ----------------------------------------------------------------------- */
    /**
     * async function called when
     * - onClick on JOUR PRECEDENT button
     * - onClick on JOUR SUIVANT button
     * - isDisplayGraph is true
     *
     */
    const updateChart = async ({ dateDeb, dateFin }) => {
        const body = {
            dateDeb: dateDeb,
            dateFin: dateFin != null ? dateFin : dateDeb,
        };

        triggerPostGetAllOrdersForChart(encodeURIComponent(JSON.stringify(body)));
    };
    /* ----------------------------------------------------------------------- */
    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        const body = {
            dateDeb: stat.selectedDay,
            dateFin: stat.selectedDay,
        };

        triggerPostGetAllOrdersForChart(encodeURIComponent(JSON.stringify(body)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stat.searchOnDuration === true) {
            updateChart({ dateDeb: stat.selectedDateDeb, dateFin: stat.selectedDateFin }); // - call function asyn to update chart
            dispatch(setSearchOnDuration(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stat.searchOnDuration]);

    useEffect(() => {
        if (stat.searchOnSpecificDay === true) {
            updateChart({ dateDeb: stat.selectedDay }); // - call function asyn to update chart
            dispatch(setSearchOnSpecificDay(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stat.searchOnSpecificDay]);

    useEffect(() => {
        if (resNbMaxPerWeek.status === "fulfilled" && resNbMaxPerWeek.data != null) {
            setNbMaxPerWeek(resNbMaxPerWeek.data.nbMaxOrders);
            setTopWeek(resNbMaxPerWeek.data.semaine);
        }
    }, [resNbMaxPerWeek]);

    useEffect(() => {
        if (resNbMaxPerDay.status === "fulfilled" && resNbMaxPerDay.data != null) {
            setNbMaxPerDay(resNbMaxPerDay.data.nbMaxOrders);
            setTopDay(resNbMaxPerDay.data.jour);
        }
    }, [resNbMaxPerDay]);

    useEffect(() => {
        if (resNbOrdersToday.data != null && resNbOrdersToday.status === "fulfilled") {
            setNbCmdToday(resNbOrdersToday.data.nballhandledorders);
        }
    }, [resNbOrdersToday]);

    useEffect(() => {
        if (resNbOrdersInWeek.data != null && resNbOrdersInWeek.status === "fulfilled") {
            setNbCmdWeek(resNbOrdersInWeek.data.nbOrders);
            // setDateStart(strDate(resNbOrdersInWeek.data.monday))
            // setDateEnd(strDate(resNbOrdersInWeek.data.friday))
            setAvgWeek(resNbOrdersInWeek.data.avgperdaythisweek);
        }
    }, [resNbOrdersInWeek]);

    /* ------------------------------------------------------------------------
     * Handling result of triggerPostGetAllOrdersForChart(day),the call api
     * service to update chart
     * --------------------------------------------------------------------- */
    useEffect(() => {
        if (result.isError) {
            errorInfos.className = "Stats.js::triggerPostGetAllOrdersForChart()";
            const sourceMsg = result.error?.data?.message ? result.error.data.message : result.error.error;
            errorInfos.message = `Echec lors de la récuperation des données du graph - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation des produits par utilisateurs. Merci de contacter le Service IT.</span>, {
                duration: 10000,
            });
        }

        if (result.isSuccess && result.status === "fulfilled" && result.data.hasOwnProperty("orders")) {
            const ordersPerHalfHour = countOrdersPerHalfHour(result.data.orders.order);
            const webOrdersPerHalfHour = countWebOrdersPerHalfHour(result.data.orders.order);

            const dataGraph = {
                labels: [
                    "07:30",
                    "08h00",
                    "08h30",
                    "09:00",
                    "09h30",
                    "10:00",
                    "10h30",
                    "11:00",
                    "11h30",
                    "12:00",
                    "12h30",
                    "13:00",
                    "13h30",
                    "14:00",
                    "14h30",
                    "15:00",
                    "15h30",
                    "16:00",
                    "16h30",
                    "17:00",
                    "17h30",
                    "18:00",
                    "18h30",
                ],
                datasets: [
                    {
                        label: "Commandes manuelles",
                        data: ordersPerHalfHour,
                        backgroundColor: "#1ba9aa",
                    },
                    {
                        label: "Commandes web",
                        data: webOrdersPerHalfHour,
                        backgroundColor: "#ffa9aa",
                    },
                ],
            };

            setChartData(dataGraph);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.data]);

    /* ----------------------------------------------------------------------- */
    return (
        <>
            <h2 className={styles.title}>Statistiques d&#39;activité - Espace ADV</h2>
            <br />

            <Grid container direction="row" spacing={2} justifyContent="center">
                <Grid item xs={2}>
                    <Card className={styles.customCard}>
                        <CardContent className={styles.reduceContent}>
                            <MeterChart dataValue={nbCmdToday} limitValue={600} dataLabel="commandes journalières" />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={2}>
                    <Card className={styles.customCard}>
                        <CardContent className={styles.reduceContent}>
                            <MeterChart dataValue={nbCmdWeek} limitValue={3000} dataLabel="commandes cette semmaine" />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={8}>
                    <Card className={styles.customCard}>
                        <div className={styles.data}>
                            <span>Autres infos</span>
                            <ul>
                                <li>
                                    Moyenne de commandes journalières traitées, cette semaine: <span className={styles.txtBold}>{avgWeek}</span>
                                </li>
                            </ul>
                            <br />
                            <ArrowRightIcon className={styles.relativePos02} />
                            <span>les top résultats</span>
                            <ul>
                                <li>
                                    <span className={styles.txtBold}>{nbMaxPerDay}</span> commandes traitées le{" "}
                                    <span className={styles.txtBold}>{elegantStyleDate(topDay)}</span>
                                </li>

                                <li>
                                    <span className={styles.txtBold}>{nbMaxPerWeek}</span> commandes traitées en semaine{" "}
                                    <span className={styles.txtBold}>{topWeek}</span>
                                </li>
                            </ul>
                        </div>
                    </Card>
                </Grid>

                {/* -------------------------------------------------------------------
                 * - Graph number of orders by day
                 * ----------------------------------------------------------------- */}
                <Grid item xs={1} />
                <Grid item xs={2} className={styles.chartButtonL}>
                    <Button variant="outlined" onClick={() => onClickDayPrev()}>
                        Jour précédent
                    </Button>
                </Grid>

                <Grid item xs={6} className={styles.txtCenter}>
                    <span className={styles.labelDate}>{stat.labelSelection}</span>
                </Grid>

                <Grid item xs={2} className={styles.chartButtonR}>
                    <Button variant="outlined" onClick={() => onClickDayNext()}>
                        Jour suivant
                    </Button>
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <div className={styles.chartContainer}>{chartData != null && <ChartDataGraph options={chartOptions} data={chartData} />}</div>
                </Grid>
                <Grid item xs={1} />

                {/* ---------------------------------------------------------------- */}
                <Grid item xs={12} className={styles.contentSpace} />

                <Grid item xs={12} className={styles.accordionContainer}>
                    <Accordion className={styles.customAccordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={styles.typo_01}>
                            {/* <Typography className={styles.relativePos03}>Nombre de commandes réalisées par utilisateur</Typography> */}
                            <Typography>Nombre de commandes réalisées par utilisateur</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableOrderByUser />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* <Grid item xs={12} className={styles.contentSpace} /> */}

                <Grid item xs={12} className={styles.accordionContainer}>
                    <Accordion className={styles.customAccordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={styles.typo_01}>
                            <Typography>Nombre de commandes réalisées par tranches horaires</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableOrderPerHourByUser />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} className={styles.accordionContainer}>
                    <Accordion className={styles.customAccordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel2a-header" className={styles.typo_01}>
                            <Typography>Nombre de lignes éditées</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableProductByUser />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} className={styles.accordionContainer}>
                    <Accordion className={styles.customAccordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel3a-header" className={styles.typo_01}>
                            <Typography>Nombre de commandes réalisées par jour</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableOrderByDay />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} className={styles.accordionContainer}>
                    <Accordion className={styles.customAccordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel3a-header" className={styles.typo_01}>
                            <Typography>Nombre de commandes réalisées par semaine</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableOrderByWeek />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>
    );
};

export default Stats;
