import { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { cartSelector, decreaseQuantity, increaseQuantity, updateQuantity, getTotals, setIsLoadingQuantity, updateProduct } from "./cartSlice";
import styles from "../../styles/cartQuantityHandler.module.css";

/* ------------------------------------------------------------------------- */
const CartQuantityHandler = ({ product }) => {
    const cart = useSelector(cartSelector);

    const [quantity, setQuantity] = useState(product.quantity);
    const canChangeQte = !(parseInt(cart.smiley, 10) > 0 || cart.reglement !== "" || cart.siteSource === "LDAL");

    const dispatch = useDispatch();

    const handleDecreaseQuantity = (product) => {
        if (quantity > 1) {
            const newQuantity = parseInt(quantity, 10) - 1;
            setQuantity(newQuantity);
        }
        dispatch(decreaseQuantity(product));
        dispatch(getTotals());
    };

    const handleIncreaseQuantity = (product) => {
        let newQuantity = parseInt(quantity, 10) + 1;
        if (newQuantity > 10000) {
            newQuantity = 10000;
        }
        setQuantity(newQuantity);
        dispatch(increaseQuantity(product));
        dispatch(getTotals());
    };

    const handleInputChange = (quantity) => {
        let value;
        if (quantity > 10000) {
            document.getElementById("inputQuantity").value = 10000;
            value = 10000;
        }
        setQuantity(value);
    };

    useEffect(() => {
        setQuantity(product.quantity);
    }, [product.quantity]);

    const handleQuantityChange = (product, quantity) => {
        if (Number.isNaN(quantity) || !quantity.length || parseInt(quantity, 10) === 0) {
            setQuantity(1);
            const updatedProduct = {
                ...product,
                quantity: 0,
            };
            dispatch(updateQuantity(updatedProduct));
            dispatch(getTotals());

            return toast.error("Merci d'entrer une quantité valide.", {
                id: "quantityError",
            });
        }
        const updatedProduct = {
            ...product,
            quantity: parseFloat(quantity),
        };
        dispatch(updateQuantity(updatedProduct));
        dispatch(getTotals());
    };

    const quantityIsLoad = (state, quantity) => {
        if (quantity === state) {
            dispatch(setIsLoadingQuantity(false));
        } else {
            dispatch(setIsLoadingQuantity(true));
        }
    };

    const isLoadingQuantityFalse = () => {
        dispatch(setIsLoadingQuantity(false));
    };

    useEffect(() => {
        isLoadingQuantityFalse();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.cartItems]);

    useEffect(() => {
        if (cart.cartItems[product.indexInCart] !== undefined) {
            quantityIsLoad(quantity, cart.cartItems[product.indexInCart].quantity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);

    return (
        <div className={styles.quantityInput}>
            {cart.isLoadingQuantity === false && (
                <button disabled={!canChangeQte} onClick={() => handleDecreaseQuantity(product)}>
                    <RemoveIcon fontSize="medium" />
                </button>
            )}
            <input
                disabled={!canChangeQte}
                id="inputQuantity"
                value={quantity}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyUp={(e) => handleQuantityChange(product, e.target.value)}
            />
            {cart.isLoadingQuantity === false && (
                <button disabled={!canChangeQte} onClick={() => handleIncreaseQuantity(product)}>
                    <AddIcon fontSize="medium" />
                </button>
            )}
        </div>
    );
};
export default CartQuantityHandler;
