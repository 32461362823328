import { useEffect, useState } from "react";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

import SoldComptable from "./SoldComptable";
import { lumenApi } from "../../../app/services/lumenApi";
import { middleware } from "../../../app/services/middleware";
import { cartSelector, setClient, setEmail5, setEmailInvoice, setIsLoadingInfosClient } from "../../../features/cart/cartSlice";
import CreditsSold from "../../../features/credits/CreditsSold";
import styles from "../../../styles/orderInfos.module.css";
import SubmitButton from "../../components/buttons/SubmitButton";
import PopUpTarifs from "../../components/popUpTarifs/popUpTarifs";
import { ENV } from "../../utils/apiConstants";

/* ------------------------------------------------------------------------- */
const OrderInfos = () => {
    const { client, email5, emailInvoice } = useSelector(cartSelector);
    const dispatch = useDispatch();

    const [triggerInfosClient, resultInfosClient] = middleware.endpoints.getInfosClient.useLazyQuery();
    const [trigger, resultSmiley] = middleware.endpoints.getSoldeSmiley.useLazyQuery();
    const [triggerStatusAccountLaGalaxy, resultStatusAccountLaGalaxy] = lumenApi.endpoints.getStatusAccountLaGalaxy.useLazyQuery();
    const [triggerSendEmailFirstConn, resultSendEmailFirstConn] = lumenApi.endpoints.getSendEmailFirstConnexion.useLazyQuery();
    const [open, setOpen] = useState(false);

    const [statusAccountLaGalaxy, setStatusAccountLaGalaxy] = useState("");
    const [accountAllReadyActivated, setAccountAllReadyActivated] = useState(true);
    const [clientEmailGalaxy, setClientEmailGalaxy] = useState("-");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickSendEmailActivationLaGalaxy = () => {
        if (accountAllReadyActivated === false) {
            if (clientEmailGalaxy !== "-") {
                // TODO: securité actuellement car hors production quel est l'email utilisé ???
                if (ENV !== "PROD") {
                    const email = "test.biotechdental@gmail.com";
                    triggerSendEmailFirstConn(email);
                } else {
                    triggerSendEmailFirstConn(clientEmailGalaxy);
                }
            } else {
                toast(
                    <>
                        <WarningAmberIcon className={styles.simpleWarning} />
                        <span>Opération impossible, le client ne posséde pas d&#39;email Galaxy</span>
                    </>,
                    {
                        duration: 4000,
                    }
                );
            }
        }
    };

    /* ----------------------------------------------------------------------- */
    function warningAccountPopup(raisonSocial) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <div className={styles.beatsWarning}>
                            <WarningAmberIcon className={styles.warning} />
                            <span className={styles.titleWarning}>Compte bloqué</span>
                        </div>

                        <p>Le compte du client {raisonSocial} est actuellement indisponible pour toute opération.</p>
                        <br />
                        <button onClick={onClose}>Accepter</button>
                    </div>
                );
            },
        });
    }

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        triggerInfosClient(client.clientCode);
        const suuid = client.clientSuuid;
        if (suuid !== undefined && suuid.replaceAll(" ", "") !== "") {
            triggerStatusAccountLaGalaxy(client.clientSuuid);
        } else {
            setStatusAccountLaGalaxy("Pas de compte (vérifier SF)");
            setAccountAllReadyActivated(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resultStatusAccountLaGalaxy.status === "fulfilled") {
            if (resultStatusAccountLaGalaxy.data?.success === true && resultStatusAccountLaGalaxy.data.result.Activated === true) {
                setStatusAccountLaGalaxy("Activé");
                setAccountAllReadyActivated(true);
            } else if (resultStatusAccountLaGalaxy.data?.success === true && resultStatusAccountLaGalaxy.data.result.Activated === false) {
                setStatusAccountLaGalaxy("Non activé");
                setAccountAllReadyActivated(false);
            } else if (resultStatusAccountLaGalaxy.data?.success === false) {
                setStatusAccountLaGalaxy("Pas de compte (vérifier SF)");
                setAccountAllReadyActivated(false);
            }
        }
    }, [resultStatusAccountLaGalaxy]);

    useEffect(() => {
        if (resultSendEmailFirstConn.status === "fulfilled") {
            if (resultSendEmailFirstConn.data.success === true) {
                toast.success(`Un email de première connexion vient d'être envoyé à l'adresse ${clientEmailGalaxy}`, {
                    duration: 4000,
                });
            } else {
                toast.error("Une erreur s'est produite lors de l'envoi du mail", {
                    duration: 4000,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSendEmailFirstConn]);

    useEffect(() => {
        if (resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success) {
            const emailFac = resultInfosClient.data.client.facturation.email5;
            dispatch(setEmail5(emailFac));

            const emailInvoice = resultInfosClient.data.client.facturation.email1;
            dispatch(setEmailInvoice(emailInvoice));

            const emailGalaxy = resultInfosClient.data.client.emailgalaxy !== "" ? resultInfosClient.data.client.emailgalaxy : "-";
            setClientEmailGalaxy(emailGalaxy);

            const addClientProperties = {
                clientCode: client.clientCode,
                client: client.client,
                clientSuuid: client.clientSuuid,
                codepostal: client.codepostal,
                adresseLivraisonDefaut: client.adresseLivraisonDefaut,
                devise: resultInfosClient.data.client.devise,
            };

            dispatch(setClient(addClientProperties));

            if (resultInfosClient.data.client.suuid !== "") {
                trigger(resultInfosClient.data.client.suuid);
            }

            if (resultInfosClient.data.client.status === "Bloqué") {
                warningAccountPopup(resultInfosClient.data.client.raisonsociale);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultInfosClient]);

    useEffect(() => {
        if (resultSmiley.isLoading) {
            dispatch(setIsLoadingInfosClient(true));
        }

        if (resultSmiley.status === "fulfilled") {
            dispatch(setIsLoadingInfosClient(false));

            const clientProperties = { ...client };
            clientProperties.soldeSmiley = resultSmiley.data?.success === true ? resultSmiley.data.result : "-";
            dispatch(setClient(clientProperties));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSmiley]);

    if (resultInfosClient.isError) return null;

    return (
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={styles.dataClient}>
            <Grid item xs={4}>
                <h4 className={styles.firstRaw}>Status</h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <div className={resultInfosClient.data.client.status === "Non bloqué" ? styles.txtCenter : styles.txtCenterRed}>
                        {resultInfosClient.data.client.status}
                    </div>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={4}>
                <SoldComptable />
            </Grid>

            <Grid item xs={4}>
                <h4 className={styles.firstRaw}>Commercial </h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <div className={styles.txtCenter}>
                        {resultInfosClient.data.client.representant
                            ? `${resultInfosClient.data.client.representantcode} - ${resultInfosClient.data.client.representant}`
                            : "-"}
                    </div>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={4}>
                <br />
                <h4 className={styles.firstRaw}>Siret </h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <div className={styles.txtCenter}>{resultInfosClient.data.client.siret ? `${resultInfosClient.data.client.siret}` : "-"}</div>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={4}>
                <br />
                <h4 className={styles.firstRaw}>TVA Intracom </h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <div className={styles.txtCenter}>
                        {resultInfosClient.data.client.tvaintracommunautaire ? `${resultInfosClient.data.client.tvaintracommunautaire}` : "-"}
                    </div>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={4}>
                <br />
                <h4 className={styles.firstRaw}>Devise </h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <div className={styles.txtCenter}>{resultInfosClient.data.client.devise ? `${resultInfosClient.data.client.devise}` : "-"}</div>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={12}>
                <hr />
                <h4>Crédits</h4>
                <CreditsSold />
            </Grid>

            <Grid item xs={12}>
                <hr />
                <SubmitButton className={styles.btnTarif} buttonStyle="little" buttonText="voir les tarifs applicables" onClick={handleClickOpen} />
                <Dialog open={open} onClose={handleClose} maxWidth="xl">
                    <div className={styles.popUpTarifs}>
                        <PopUpTarifs client={client.client} codeClient={client.clientCode} />
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>
                </Dialog>
            </Grid>

            <Grid item xs={12}>
                <hr />
                <h4>Solde Smiley </h4>
                {resultSmiley.isLoading ? <BeatLoader /> : <div className={styles.textinfos}>{client.soldeSmiley}</div>}
            </Grid>

            <Grid item xs={12}>
                <h4>Note</h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <div className={styles.textinfos}>{resultInfosClient.data.client.note ? `${resultInfosClient.data.client.note}` : "-"}</div>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={12}>
                <h4>Email</h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <>
                        <div className={styles.textinfos}>{emailInvoice}</div>
                        <div className={styles.textinfos}>{resultInfosClient.data.client.facturation.email2}</div>
                        <div className={styles.textinfos}>{resultInfosClient.data.client.facturation.email3}</div>
                        <div className={styles.textinfos}>{resultInfosClient.data.client.facturation.email4}</div>
                        <div className={styles.textinfos}>{email5}</div>
                    </>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={12}>
                <h4>Email Galaxy</h4>
                {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                    <div className={styles.textinfos}>{clientEmailGalaxy}</div>
                ) : (
                    <BeatLoader />
                )}
            </Grid>

            <Grid item xs={4}>
                <h4>Compte LaGalaxy</h4>
                {resultStatusAccountLaGalaxy.status === "fulfilled" ? (
                    <div className={styles.textinfos}>{statusAccountLaGalaxy}</div>
                ) : (
                    <div className={styles.textinfos}>-</div>
                )}
            </Grid>

            <Grid item xs={8}>
                <SubmitButton
                    buttonStyle={accountAllReadyActivated ? "disabled" : "little"}
                    buttonText="Mail Activation LaGalaxy"
                    onClick={handleClickSendEmailActivationLaGalaxy}
                />
            </Grid>
        </Grid>
    );
};

export default OrderInfos;
