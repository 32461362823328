import { useEffect, useState } from "react";

import CloudOffIcon from "@mui/icons-material/CloudOff";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ValuePopUp from "./ValuePopUp";
import { lumenApi, usePostUpdateOrdersX3StatusMutation, useUpdateOrderStatusMutation, useUpdateOrderWebStatusMutation } from "../../../app/services/lumenApi";
import { authSelector } from "../../../features/auth/authSlice";
import { clearCartPreview } from "../../../features/cart/cartPreviewSlice";
import styles from "../../../styles/chronologie.module.css";
import { escapeRegExp, convertDateFRToEN } from "../../utils/helperFunctions";
import Spinner from "../spinner/Spinner";
import TableSearchInput from "../tableSearchInput/TableSearchInput";

/* ------------------------------------------------------------------------- */

const Chronologie = () => {
    const navigate = useNavigate();

    const auth = useSelector(authSelector);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(16);
    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState([]);

    const [triggerGetAllOrdersFromUser, resultAllOrderFromUser] = lumenApi.endpoints.getAllOrdersFromUser.useLazyQuery();
    const [triggerGetListInQueue, resGetListInQueue] = lumenApi.endpoints.getListInQueue.useLazyQuery(); // get list of orderId and orderWebId in queue ImportX3
    const [triggerUpdateOrdersX3Status, resUpdateOrdersX3Statuts] = usePostUpdateOrdersX3StatusMutation();
    const [triggerUpdateOrderStatus] = useUpdateOrderStatusMutation();
    const [triggerUpdateOrderWebStatus] = useUpdateOrderWebStatusMutation();

    /* ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(clearCartPreview());
        triggerGetListInQueue(auth.user.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resGetListInQueue.status === "fulfilled") {
            let bodyParam = {
                tabOrderId: resGetListInQueue.data.tabOrderId,
                tabOrderWebId: resGetListInQueue.data.tabOrderWebId,
            };

            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
            triggerUpdateOrdersX3Status(bodyParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetListInQueue]);

    useEffect(() => {
        if (resUpdateOrdersX3Statuts.status === "fulfilled") {
            triggerGetAllOrdersFromUser(auth.user.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resUpdateOrdersX3Statuts]);

    useEffect(() => {
        if (resultAllOrderFromUser.status === "fulfilled") {
            setRows(resultAllOrderFromUser.data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultAllOrderFromUser]);

    /* every 2 min - */
    useEffect(() => {
        const interval = setInterval(() => {
            triggerGetListInQueue(auth.user.id);
        }, 120000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* ----------------------------------------------------------------------- */

    const handleClickRestartOrder = async (params) => {
        const orderType = params.row.order_type;
        const orderId = params.row.id.replace(orderType, "");
        if (orderType === "web") {
            await triggerUpdateOrderWebStatus({ id: orderId, status: 0 }).then(navigate(`/webcarts/${orderId}`));
        } else {
            await triggerUpdateOrderStatus({ id: orderId, status: 0 }).then(navigate(`/carts/${orderId}`));
        }
    };

    const refresh = () => {
        triggerGetListInQueue(auth.user.id);
    };

    // liaison chronologie - pop-up par récupération de l'order_id
    const [open, setOpen] = useState(false);

    const [orderId, setOrderId] = useState(0);
    const [orderType, setOrderType] = useState(0);

    const stringDateComparator = (str1, str2) => {
        // let d1 = convertStringToDate(str1)
        // let d2 = convertStringToDate(str2)
        // return d1.getTime() - d2.getTime()

        const d1 = convertDateFRToEN(str1.substring(0, 10)) + str1.substring(10, str1.length);
        const d2 = convertDateFRToEN(str2.substring(0, 10)) + str2.substring(10, str2.length);
        return Date.parse(d1) > Date.parse(d2);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (params) => {
        const type = params.row.order_type;
        const id = params.row.id.replace(type, "");
        setOrderId(id);
        setOrderType(type);
        handleClickOpen();
    };
    // - colonnes tableau de paniers ajoutés en file d'attente
    const columns = [
        {
            field: "clientCode",
            headerName: "Code client",
            headerClassName: "tableHeader",
            cellClassName: "SFNumber",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },

        {
            field: "client",
            headerName: "Client",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },

        {
            field: "codepostal",
            headerName: "Code postal",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "created_at",
            headerName: "Date",
            headerClassName: "tableHeader",
            sortingOrder: ["asc", "desc"],
            flex: 1,
            headerAlign: "center",
            align: "center",
            type: "string",
            sortComparator: stringDateComparator,
        },
        {
            field: "order_type",
            headerName: "Origine",
            headerClassName: "tableHeader",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => params?.value && params.value.charAt(0).toUpperCase() + params.value.slice(1),
        },
        {
            field: "siteSource",
            headerName: "Type",
            headerClassName: "tableHeader",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => params?.value && params.value.charAt(0).toUpperCase() + params.value.slice(1),
        },
        {
            field: "document",
            headerName: "Documents liés",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => <FindInPageIcon className={styles.see} onClick={() => handleClick(params)} />,
        },
        {
            field: "statusX3creation",
            headerName: "Importée dans X3",
            headerClassName: "tableHeader",
            cellClassName: "SFNumber",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <>
                    {params.value === 1 && <div className={styles.successImport}>Succès</div>}
                    {params.value === -1 && <div className={styles.errorImport}>Échec</div>}
                    {params.value === 0 && <div className={styles.errorIco}>en file d&#39;attente...</div>}
                </>
            ),
        },
        {
            field: "Voir",
            headerName: "Voir / Reprendre",
            flex: 1,
            headerAlign: "center",
            headerClassName: "tableHeader",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>{params.row.statusX3creation === -1 && <RestartAltIcon className={styles.see} onClick={() => handleClickRestartOrder(params)} />}</>
            ),
        },
    ];
    /* ----------------------------------------------------------------------- */
    // if (isFetching || rows === undefined) return <></>;

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = resultAllOrderFromUser.data.filter((data) => {
            return Object.keys(data).some((field) => {
                return searchRegex.test(data[field].toString());
            });
        });
        setRows(filteredRows);
    };

    /* ----------------------------------------------------------------------- */
    if (resultAllOrderFromUser.status !== "fulfilled") {
        return <Spinner />;
    }
    if (resultAllOrderFromUser?.data.length === 0) {
        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3} className={styles.boxHisto}>
                <div>
                    <h2 className={styles.title}>Commandes passées</h2>
                </div>
                <div className={styles.noResult}>
                    <p>
                        <CloudOffIcon />
                        Vous n&#39;avez aucune commande passée
                    </p>
                </div>
            </Box>
        );
    }
    return (
        <Box sx={{ flexGrow: 1 }} className={styles.boxHisto}>
            <h2 className={styles.title} id="title-order-tracking">
                Suivi des Commandes
            </h2>

            <div className={styles.gridContainer}>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: "created_at",
                                    sort: "desc",
                                },
                            ],
                        },
                    }}
                    rows={rows}
                    rowHeight={36}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[16, 25, 50]}
                    sortingOrder={["asc", "desc"]}
                    columns={columns}
                    disableRowSelectionOnClick
                    components={{
                        Toolbar: () => (
                            <>
                                <div className={styles.refresh}>
                                    <div className={styles.refreshItem} onClick={refresh}>
                                        <RefreshIcon />
                                        <span className={styles.textRefresh}>Actualiser</span>
                                    </div>
                                </div>
                                <TableSearchInput
                                    value={searchText}
                                    onChange={(event) => requestSearch(event.target.value)}
                                    clearSearch={() => requestSearch("")}
                                />
                            </>
                        ),
                    }}
                />

                <Dialog open={open} onClose={handleClose} fullWidth={false} maxWidth="md">
                    <div className={styles.grey}>
                        <DialogTitle className={styles.dialogTitle}>Détails des documents liés à cette commande</DialogTitle>
                        {/* <div className={styles.tabDocument}> */}
                        <div>{orderId !== 0 && orderType !== 0 && <ValuePopUp type={orderType} id={orderId} />}</div>
                        <DialogActions>
                            <Button onClick={handleClose}>Fermer</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        </Box>
    );
};

export default Chronologie;
