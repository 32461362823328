import { useEffect, useState } from "react";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { toast } from "react-hot-toast";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

import { usePostSendQuoteMutation } from "../../../app/services/lumenApi";
import styles from "../../../styles/popUpSentQuote.module.css";
import { arrayIsEmpty } from "../../utils/helperFunctions";
import SubmitButton from "../buttons/SubmitButton";

/* ------------------------------------------------------------------------- */

const PopUpSentSimpleQuote = ({ clientCode, emailAddress, dataList }) => {
    const [listEmails, setListEmails] = useState([emailAddress]);
    const [listEmailsCC, setListEmailsCC] = useState([]);
    const [isCheckedCGV, setIsCheckedCGV] = useState(true);
    const [isCheckedMandat, setIsCheckedMandat] = useState(false);

    const [valid, setValid] = useState(!arrayIsEmpty(listEmails));

    useEffect(() => {
        setValid(!arrayIsEmpty(listEmails));
    }, [listEmails]);

    const [sendMail, resSendMail] = usePostSendQuoteMutation();

    /* ------------------------------------------------------------------------- */

    const sendQuote = () => {
        if (listEmails.length === 0) {
            toast.error("Merci d&#39;indiquer au moins une adresse email de destination");
        }
        const infosMail = {
            client_code: clientCode,
            client_email: emailAddress,
            list_emails: listEmails,
            list_emails_copy: listEmailsCC,
            list_id: dataList,
            cgv_attachment: isCheckedCGV,
            mandat_attachment: isCheckedMandat,
        };
        sendMail(infosMail);
    };

    useEffect(() => {
        if (resSendMail.status === "fulfilled") {
            if (resSendMail.data.status === false) {
                toast.error("Une erreur s&#39;est produite lors de l&#39;envoi du mail");
            } else {
                toast.success("Le ou les Devis ont bien été envoyé");
            }
        }
    }, [resSendMail]);
    /* ------------------------------------------------------------------------- */

    return (
        <div className={styles.popUp}>
            <h2 className={styles.center}>Informations pour l&#39;envoi de devis</h2>
            <div className={styles.form}>
                <h4>Destinataire(s)</h4>
                <ReactMultiEmail
                    className={styles.inputEmailList}
                    emails={listEmails}
                    validateEmail={(email) => {
                        return isEmail(email);
                    }}
                    onChange={(_emails = []) => {
                        setListEmails(_emails);
                    }}
                    getLabel={(email, index, removeEmail) => {
                        return (
                            <div data-tag key={index}>
                                <div data-tag-item>{email}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />

                <h4>Destinataire(s) en copie</h4>
                <ReactMultiEmail
                    placeholder="Rajouter le commercial de la fiche client"
                    emails={listEmailsCC}
                    validateEmail={(email) => {
                        return isEmail(email);
                    }}
                    onChange={(emailsCC = []) => {
                        setListEmailsCC(emailsCC);
                    }}
                    getLabel={(emailsCC, index, removeEmail) => {
                        return (
                            <div data-tag key={index}>
                                <div data-tag-item>{emailsCC}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />
                <div className={styles.checks}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Rattacher le mandat"
                            onChange={(e) => setIsCheckedMandat(e.target.checked)}
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Rattacher les Conditions Génerales de Vente"
                            checked={isCheckedCGV}
                            onChange={(e) => setIsCheckedCGV(e.target.checked)}
                        />
                    </FormGroup>
                </div>
                <div className={styles.button}>
                    <SubmitButton
                        id="send-quote-client"
                        buttonStyle={!valid && "disabledBig"}
                        buttonText="Envoyer par email"
                        disabled={!valid}
                        onClick={sendQuote}
                    />
                </div>
            </div>
        </div>
    );
};

export default PopUpSentSimpleQuote;
