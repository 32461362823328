import DownloadIcon from "@mui/icons-material/Download";

import styles from "../../../styles/orderExcelInput.module.css";

/* ------------------------------------------------------------------------- */
const OrderExcelDownload = () => {
    const DownloadOnClick = () => {
        // using Java Script method to get PDF file
        fetch("modele_import_commande.xlsx").then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                const alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "modele_import_commande.xlsx";
                alink.click();
            });
        });
    };

    return (
        <button onClick={DownloadOnClick} className={styles.btnDownload}>
            <DownloadIcon className={styles.adjustPos} sx={{ fontSize: 20 }} /> <span>Télécharger le modèle</span>
        </button>
    );
};

export default OrderExcelDownload;
