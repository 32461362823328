import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

import styles from "../../../styles/chartDataGraph.module.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartDataGraph = ({ options, data }) => {
    return (
        <div className={styles.chart}>
            <Bar options={options} data={data} />
        </div>
    );
};

export default ChartDataGraph;
