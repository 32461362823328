import { createSlice } from "@reduxjs/toolkit";

import { lumenApi } from "../../app/services/lumenApi";
import { middleware } from "../../app/services/middleware";

/* ------------------------------------------------------------------------- */
const initialState = {
    clientQuote: localStorage.getItem("clientQuote") ? JSON.parse(localStorage.getItem("clientQuote")) : [],
    shippingAddressClient: localStorage.getItem("shippingAddressClient") ? localStorage.getItem("shippingAddressClient") : "",
    listInfos: localStorage.getItem("listInfos") ? JSON.parse(localStorage.getItem("listInfos")) : [],

    factAddress: localStorage.getItem("factAddress") ? localStorage.getItem("factAddress") : "",
    items: localStorage.getItem("items") ? JSON.parse(localStorage.getItem("items")) : [],
    quoteTotalQuantity: 0,
    quoteTotalAmount: 0,
    quoteTotalAmountCheckout: 0,
    isLoadingQuantity: false,
    isRecoveryQuote: localStorage.getItem("isRecoveryQuote") ? localStorage.getItem("isRecoveryQuote") === "true" : false,
    quoteId: localStorage.getItem("quoteId") ? localStorage.getItem("quoteId") : "",
    refQuote: localStorage.getItem("refQuote") ? JSON.parse(localStorage.getItem("refQuote")) : "",
    quoteNum: localStorage.getItem("quoteNum") ? JSON.parse(localStorage.getItem("quoteNum")) : "",
};

const quoteSlice = createSlice({
    name: "quote",
    initialState,
    reducers: {
        addToQuote(state, action) {
            const tempProduct = {
                ...action.payload,
                indexInQuote: state.items.length,
                quoteQuantity: 1,
            };
            state.items.push(tempProduct);
            localStorage.setItem("items", JSON.stringify(state.items));
        },

        removeFromQuote(state, action) {
            const updatedQuote = state.items.filter((item) => item.indexInQuote !== action.payload.indexInQuote);
            state.items = updatedQuote;
            state.items.forEach((item, index) => {
                item.indexInQuote = index;
            });
            localStorage.setItem("items", JSON.stringify(state.items));
        },

        decreaseQuantity(state, action) {
            const itemExist = state.items.find((item) => item.indexInQuote === action.payload.indexInQuote);
            if (itemExist.quoteQuantity > 1) {
                itemExist.quoteQuantity -= 1;
            } else {
                const updatedQuote = state.items.filter((item) => item.indexInQuote !== action.payload.indexInQuote);
                state.items = updatedQuote;
                state.items.forEach((item, index) => {
                    item.indexInQuote = index;
                });
                localStorage.setItem("items", JSON.stringify(state.items));
            }
            localStorage.setItem("items", JSON.stringify(state.items));
        },

        increaseQuantity(state, action) {
            const itemExist = state.items.find((item) => item.indexInQuote === action.payload.indexInQuote);
            if (itemExist.quoteQuantity < 10000) {
                itemExist.quoteQuantity += 1;
            }
            localStorage.setItem("items", JSON.stringify(state.items));
        },

        setClientQuote(state, action) {
            state.clientQuote = action.payload;
            localStorage.setItem("clientQuote", JSON.stringify(action.payload));
        },

        setShippingAddressClient(state, action) {
            state.shippingAddressClient = action.payload;
            localStorage.setItem("shippingAddressClient", action.payload);
        },

        setFactAddress(state, action) {
            state.factAddress = action.payload;
            localStorage.setItem("factAddress", action.payload);
        },

        addListInfos(state, action) {
            state.listInfos = state.listInfos.concat(action.payload);
            localStorage.setItem("listInfos", JSON.stringify(state.listInfos));
        },

        getTotalsQuote(state, action) {
            const { total, quantity, totalCheckout } = state.items.reduce(
                (quoteTotal, item) => {
                    const { puttc, remise1montant, quoteQuantity } = item;

                    const puttcnet = remise1montant ? puttc * (1 - remise1montant / 100) : puttc;
                    const itemTotal = puttcnet * quoteQuantity;
                    let itemTotalCheckout = 0;
                    item.puttcnet = 0;
                    itemTotalCheckout = puttcnet * quoteQuantity;
                    item.puttcnet = puttcnet;

                    quoteTotal.totalCheckout += itemTotalCheckout;
                    quoteTotal.total += itemTotal;
                    quoteTotal.quantity += quoteQuantity;

                    return quoteTotal;
                },
                {
                    totalCheckout: 0,
                    total: 0,
                    quantity: 0,
                }
            );

            state.quoteTotalAmountCheckout = totalCheckout;
            state.quoteTotalAmount = total;
            state.quoteTotalQuantity = quantity;
        },

        updateQuantity(state, action) {
            const itemExist = state.items.find((item) => item.indexInQuote === action.payload.indexInQuote);
            itemExist.quoteQuantity = action.payload.quoteQuantity;
            localStorage.setItem("items", JSON.stringify(state.items));
        },

        setIsLoadingQuantity(state, action) {
            state.isLoadingQuantity = action.payload;
            localStorage.setItem("isLoadingQuantity", JSON.stringify(state.isLoadingQuantity));
        },

        setRefQuote(state, action) {
            state.refQuote = action.payload;
            localStorage.setItem("refQuote", JSON.stringify(action.payload));
        },

        setQuoteNum(state, action) {
            state.quoteNum = action.payload;
            localStorage.setItem("quoteNum", JSON.stringify(action.payload));
        },

        clearRefQuote(state, action) {
            state.refQuote = "";
            localStorage.setItem("refQuote", "");
        },

        clearQuoteNum(state) {
            state.quoteNum = "";
            localStorage.setItem("quoteNum", "");
        },

        updateProduct(state, action) {
            const itemExist = state.items.find((item) => item.indexInQuote === action.payload.product.indexInQuote);

            const type = action.payload.type;
            // Update product's property typedocument
            itemExist[type] = action.payload.product[type];

            // Update product's property grouperepartition
            itemExist.grouperepartition = action.payload.product.grouperepartition;

            localStorage.setItem("items", JSON.stringify(state.items));
        },

        restoreQuote(state, action) {
            const tempProduct = { ...action.payload };
            state.items.push(tempProduct);
            localStorage.setItem("items", JSON.stringify(state.items));
        },

        setIsRecoveryQuote(state, action) {
            state.isRecoveryQuote = action.payload;
            localStorage.setItem("isRecoveryQuote", JSON.stringify(state.isRecoveryQuote));
        },

        setQuoteId(state, action) {
            state.quoteId = action.payload;
            localStorage.setItem("quoteId", action.payload);
        },

        clearQuote(state) {
            state.clientQuote = [];
            state.shippingAddressClient = "";
            state.listInfos = [];
            state.factAddress = "";
            state.items = [];
            state.quoteTotalQuantity = 0;
            state.quoteTotalAmount = 0;
            state.quoteTotalAmountCheckout = 0;
            state.isLoadingQuantity = false;
            state.refQuote = "";
            state.quoteNum = "";
            state.quoteId = "";
            state.isRecoveryQuote = false;
            localStorage.setItem("clientQuote", []);
            localStorage.setItem("shippingAddressClient", "");
            localStorage.setItem("listInfos", []);
            localStorage.setItem("factAddress", "");
            localStorage.setItem("items", []);
            localStorage.setItem("refQuote", "");
            localStorage.setItem("quoteNum", "");
            localStorage.setItem("quoteId", "");
            localStorage.setItem("isRecoveryQuote", false);
        },
    },

    extraReducers: (builder) => {
        builder.addMatcher(middleware.endpoints.postGetTarifClient.matchFulfilled, (state, { payload }) => {
            if (payload?.articles) {
                payload.articles.map((item) => {
                    state.items.forEach((product) => {
                        if (product.reference === item.code) {
                            product.puttcnet = 0;
                            product.taxetaux = "-";
                            product.puttc = item.prix;
                            product.remise1montant = item.remise;
                            product.prixbrutbase = item.prix;
                            product.remise1montantbase = item.remise;
                            product.remise2montant = 0;
                            product.justificatif = "-";

                            localStorage.setItem("items", JSON.stringify(state.items));
                        } // if
                    });
                    return state;
                });
            } // if
        });

        builder.addMatcher(lumenApi.endpoints.addQuote.matchFulfilled, (state, { payload }) => {
            if (payload && payload.quoteNumber) {
                state.quoteNum = payload.quoteNumber;
                localStorage.setItem("quoteNum", JSON.stringify(state.quoteNum));
                return state;
            }
        });
    },
});

export default quoteSlice.reducer;

export const quoteSelector = (state) => state.quote;

export const {
    addToQuote,
    removeFromQuote,
    decreaseQuantity,
    increaseQuantity,
    setClientQuote,
    setShippingAddressClient,
    addListInfos,
    setFactAddress,
    getTotalsQuote,
    updateQuantity,
    setIsLoadingQuantity,
    updateProduct,
    setRefQuote,
    clearRefQuote,
    clearQuote,
    setQuoteNum,
    clearQuoteNum,
    restoreQuote,
    setIsRecoveryQuote,
    setQuoteId,
} = quoteSlice.actions;
