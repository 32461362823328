import { createSlice } from "@reduxjs/toolkit";

import { BASE_BDD_MIDDLEWARE_API } from "../../common/utils/apiConstants";

/* ------------------------------------------------------------------------- */
const initialState = {
    nomBaseSAGE: BASE_BDD_MIDDLEWARE_API,

    clientPreview: localStorage.getItem("clientPreview") ? JSON.parse(localStorage.getItem("clientPreview")) : [],
    shippingAddressPreview: localStorage.getItem("shippingAddressPreview") ? localStorage.getItem("shippingAddressPreview") : [],
    cartPreviewItems: localStorage.getItem("cartPreviewItems") ? JSON.parse(localStorage.getItem("cartPreviewItems")) : [],
    infosOrderPreview: localStorage.getItem("infosOrderPreview") ? JSON.parse(localStorage.getItem("infosOrderPreview")) : [],
    isOrderWeb: localStorage.getItem("isOrderWeb") ? localStorage.getItem("isOrderWeb") === "true" : false,
};

const cartPreviewSlice = createSlice({
    name: "cartPreview",
    initialState,
    reducers: {
        addToCartPreview(state, action) {
            const tempProduct = { ...action.payload };
            state.cartPreviewItems.push(tempProduct);
            localStorage.setItem("cartPreviewItems", JSON.stringify(state.cartPreviewItems));
        },
        setShippingAddressPreview(state, action) {
            state.shippingAddressPreview = action.payload;
            localStorage.setItem("shippingAddressPreview", JSON.stringify(action.payload));
        },
        setInfosOrderPreview(state, action) {
            state.infosOrderPreview = action.payload;
            localStorage.setItem("infosOrderPreview", JSON.stringify(action.payload));
        },
        setClientPreview(state, action) {
            state.clientPreview = action.payload;
            localStorage.setItem("clientPreview", JSON.stringify(action.payload));
        },

        setIsOrderWeb(state, action) {
            state.isOrderWeb = action.payload;
            localStorage.setItem("isOrderWeb", JSON.stringify(state.isOrderWeb));
        },

        clearCartPreviewItems(state, action) {
            state.cartPreviewItems = [];
            localStorage.setItem("cartPreviewItems", []);
        },

        clearCartPreview(state, action) {
            state.cartPreviewItems = [];
            state.clientPreview = [];
            state.shippingAddressPreview = [];
            state.isOrderWeb = false;
            state.infosOrderPreview = [];

            localStorage.setItem("cartPreviewItems", []);
            localStorage.setItem("infosOrderPreview", []);
            localStorage.setItem("clientPreview", []);
            localStorage.setItem("shippingAddressPreview", []);
            localStorage.setItem("isOrderWeb", false);
        },
    },
});

export default cartPreviewSlice.reducer;

export const cartPreviewSelector = (state) => state.cartPreview;

export const { addToCartPreview, setShippingAddressPreview, setClientPreview, setIsOrderWeb, clearCartPreview, setInfosOrderPreview, clearCartPreviewItems } =
    cartPreviewSlice.actions;
