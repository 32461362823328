import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ROOT_URL_GTICK } from "../../common/utils/apiConstants";
import { timestampToDate } from "../../common/utils/helperFunctions";

const timeInCacheShort = 0;
// eslint-disable-next-line no-unused-vars
const timeInCacheMedium = 1800;
// eslint-disable-next-line no-unused-vars
const timeInCacheLong = 3600;

const baseQuery = fetchBaseQuery({
    baseUrl: ROOT_URL_GTICK,
});

export const tickettingApi = createApi({
    reducerPath: "tickettingApi",
    baseQuery: baseQuery,
    tagTypes: ["Ticket"],
    endpoints: (build) => ({
        getTickets: build.query({
            query: () => "tickets/on",
            transformResponse: (response) => {
                if (response.length > 0) {
                    const newData = response.map((ticket) => ({
                        ...ticket,
                        created_at: timestampToDate(ticket.createdAt.timestamp),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "Ticket", id })), { type: "Ticket", id: "LIST" }] : [{ type: "Ticket", id: "LIST" }],
        }),

        getTicketsOneTime: build.query({
            query: () => "tickets/on",
            transformResponse: (response) => {
                if (response.length > 0) {
                    const newData = response.map((ticket) => ({
                        ...ticket,
                        created_at: timestampToDate(ticket.createdAt.timestamp),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "Ticket", id })), { type: "Ticket", id: "LIST" }] : [{ type: "Ticket", id: "LIST" }],
        }),
    }),
});

export const { useGetTicketsQuery, useGetTicketsOneTimeQuery } = tickettingApi;
