import React from "react";

import { useNavigate } from "react-router-dom";

import logoBd from "../../assets/img/hexagone_blanc.png";
import styles from "../../styles/notFound.module.css";
import SubmitButton from "../components/buttons/SubmitButton";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <img src={logoBd} alt="logo Biotech Dental" />
            </div>
            <div className={styles.code}>
                <div>404</div>
            </div>
            <div className={styles.text}>La page demandée n&#39;existe pas.</div>
            <div className={styles.button}>
                <SubmitButton buttonText="Retourner à l'accueil" buttonStyle="dark" onClick={() => navigate("/")} />
            </div>
        </div>
    );
};

export default NotFound;
