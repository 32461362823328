import { useEffect } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, Container } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { usePostRefreshTokenMutation } from "../../../app/services/keycloakApi";
import { usePostOrderMutation, useUpdateOrderMutation } from "../../../app/services/lumenApi";
import { useGetAddressesQuery, usePutTraiterLogMutation } from "../../../app/services/middleware";
import AddressRow from "../../../features/addresses/AddressRow";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import {
    cartSelector,
    clearCart,
    clearShippingAddress,
    clearClient,
    setIsDisplayInfos,
    clearOriginComment,
    clearReglement,
} from "../../../features/cart/cartSlice";
import CheckoutTable from "../../../features/cart/CheckoutTable";
import EditableOrderOption from "../../../features/cart/EditableOrderInfo";
import styles from "../../../styles/checkout.module.css";
import ListHandling from "../../components/listHandling/ListHandling";
import Spinner from "../../components/spinner/Spinner";
import { ENV } from "../../utils/apiConstants";
import { getFormDataToRefreshKeycloakToken, isoStringToDate, toastError } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";
import OrderInfos from "../order/OrderInfos";
import "../../../styles/react-confirm-alert.css";

/* ------------------------------------------------------------------------- */
const Checkout = () => {
    const isOnline = useOnlineStatus();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);

    const [triggerSaveCart] = usePostOrderMutation();
    const [triggerUpdateCart] = useUpdateOrderMutation();

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();

    const cart = useSelector(cartSelector);
    const { user } = useSelector(authSelector);

    const param = {
        clientCode: cart.client.clientCode,
        isActif: "Oui",
    };

    const { data, isFetching } = useGetAddressesQuery(param);

    const selectedShippingAddress = data?.adresseslivraison.adresselivraison.find((address) => address.numero === cart.shippingAddress);

    const [triggerLog] = usePutTraiterLogMutation();

    const errorInfos = {
        service: "espaceADV",
        className: "Checkout.js",
        criticite: 3,
        environment: ENV,
    };

    /* --------------------------------------------------------------------- */

    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);
    /* --------------------------------------------------------------------- */

    /** -------------------------------------------------------------------------
     * Handling onClick link Modifier la commande
     * When an error occured onClick button Generer les commandes, so the link
     * Modify the order is forbidden; avoid user add new item on an existing
     * order
     */

    const handleModifyOrder = () => {
        const elem = document.getElementById("modifyOrderLink");
        if (elem.getAttribute("disabled") !== "true") {
            navigate("/order");
        }
    };

    /* - Handling onClick button Sauvegarder le panier ----------------------- */
    const handleSaveCart = async () => {
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);

        if (cart.isOrderWeb) {
            toast(
                <>
                    <InfoIcon fontSize="medium" className={styles.posInToast} />
                    Action non autorisée pour les commandes Web
                </>
            );
        } else {
            const cartExists = cart.cartItems.find((product) => product.order_id);
            try {
                const cartToStore = {
                    products: cart.cartItems,
                    status: -1,
                    clientCode: cart.client.clientCode,
                    client: cart.client.client,
                    codepostal: cart.client.codepostal,
                    shippingAddress: cart.shippingAddress,
                    datelivraison: cart.selectedDeliveryDate,
                    origine: cart.originComment,
                    devise: cart.client.devise,
                    emailTracking: cart.emailInvoice,
                    fromQuote: cart.fromQuote ? 1 : 0,
                    fromOffer: cart.fromOffer ? 1 : 0,
                    carrierCode: cart.carrier.hasOwnProperty("carrierCode") ? cart.carrier.carrierCode : "",
                    serviceCode: cart.service.hasOwnProperty("serviceCode") ? cart.service.serviceCode : "",
                };
                if (!cartExists) {
                    await triggerSaveCart({
                        uuid: uuidv4(),
                        ...cartToStore,
                    })
                        .unwrap()
                        .then(() => {
                            navigate("/");
                            toast.success(<>Panier sauvegardé. Vous pouvez la retrouver à tout moment dans l&#39;onglet Commandes en attente.</>, {
                                duration: 5000,
                            });
                            dispatch(clearCart());
                        })
                        .catch((error) =>
                            toast.error("Le panier n'a pas pu être sauvegardé.", {
                                id: "cartError",
                            })
                        );
                } else {
                    await triggerUpdateCart({ id: cartExists.order_id, ...cartToStore })
                        .unwrap()
                        .then(() => {
                            navigate("/");
                            toast.success(<>Panier sauvegardé. Vous pouvez la retrouver à tout moment dans l&#39;onglet Commandes en attente.</>, {
                                duration: 5000,
                            });
                            dispatch(clearCart());
                        })
                        .catch((error) =>
                            toast.error("Le panier n'a pas pu être sauvegardé.", {
                                id: "cartError",
                            })
                        );
                }
            } catch (error) {
                toastError("Le panier n'a pas pu être sauvegardé.", 4000);
            }
        } // else
    };

    function handleDelOrder() {
        if (localStorage.getItem("cartItems").length !== 0) dispatch(clearCart());
        if (localStorage.getItem("shippingAddress") !== null) dispatch(clearShippingAddress());
        if (localStorage.getItem("client") !== null) dispatch(clearClient());
        if (localStorage.getItem("reglement") !== null) dispatch(clearReglement());
        if (localStorage.getItem("originComment") !== null) dispatch(clearOriginComment());
        navigate("/order");
    }

    function confirmPopup(message) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <h2>Confirmer</h2>
                        <p>{message}</p>
                        <button onClick={onClose}>Refuser</button>
                        <button
                            onClick={() => {
                                handleDelOrder();
                                onClose();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    function toggleDisplayStackInfos() {
        dispatch(setIsDisplayInfos(!cart.isDisplayInfos));
    }

    const msgDelete = "Êtes-vous sûr de vouloir supprimer la saisie en cours ?";

    if (!data) {
        return <Spinner />;
    }

    if (isFetching) {
        return <Spinner />;
    }

    if (isOnline) {
        return (
            <Container maxWidth="xxl" className={styles.contentWrap}>
                {cart.isOrderWeb === false ? (
                    <>
                        <div onClick={handleSaveCart} className={`${styles.tooltipBtn} ${styles.saveBtn}`}>
                            <SaveIcon fontSize="medium" />
                            <div className={styles.tooltiptext}>Sauvegarder le panier</div>
                        </div>

                        <div onClick={() => confirmPopup(msgDelete)} className={`${styles.tooltipBtn} ${styles.deleteBtn}`}>
                            <DeleteIcon fontSize="medium" />
                            <div className={styles.tooltiptext}>Supprimer la saisie en cours</div>
                        </div>
                    </>
                ) : null}

                <div onClick={toggleDisplayStackInfos} id="toggleDisplayInfos" className={`${styles.tooltipBtn} ${styles.bookBtn}`}>
                    <MenuBookIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Afficher le journal de traitements</div>
                </div>

                <ListHandling />

                <div>
                    <div>
                        <span id="modifyOrderLink" className={styles.goBack} onClick={handleModifyOrder}>
                            <ArrowBackIosNew /> Modifier la commande
                        </span>
                    </div>
                    <Grid container direction="row" spacing={1} justifyContent="center">
                        <Grid item xs={6}>
                            <div className={styles.clientInfos}>
                                <h3>
                                    <AccountCircleIcon fontSize="medium" className={styles.adjustPos} />
                                    Client : {cart.client.clientCode} - {cart.client.client}
                                </h3>
                            </div>
                            <OrderInfos />
                        </Grid>
                        <Grid item xs={6}>
                            <div className={styles.livraisonInfos}>
                                <h3>
                                    <LocationOnIcon fontSize="medium" className={styles.adjustPos2} />
                                    Adresse de livraison
                                </h3>
                                <div className={styles.address}>
                                    <AddressRow item={selectedShippingAddress} />
                                </div>
                                <EditableOrderOption />

                                {parseInt(cart.smiley, 10) > 0 && (
                                    <div className={styles.smileyContent}>
                                        Smiley : {cart.smiley} (Présence de smiley, seul le champs commentaire est éditable)
                                    </div>
                                )}
                                {cart.reglement !== "" && (
                                    <>
                                        <div className={styles.reglement}>Cette commande est déjà payée, toute modification est impossible.</div>
                                        <div className={styles.reglement_info}>
                                            <span>Mode de réglement : {cart.reglement.modedereglement}</span>
                                            <br />
                                            <span>Date de paiement : {isoStringToDate(cart.reglement.datepaiement)}</span>
                                            <br />
                                            <span>
                                                Montant : {cart.reglement.montant} {cart.client.devise}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <CheckoutTable />
                </div>
            </Container>
        );
    }
    return <Offline />;
};

export default Checkout;
