import { Grid } from "@mui/material";

import SearchProducts from "./SearchProducts";
import styles from "../../styles/cartRowEmpty.module.css";

const CartRowEmpty = ({ filter }) => {
    return (
        <Grid item xs={8} className={styles.cartTableRow}>
            <SearchProducts filter={filter} />
        </Grid>
    );
};

export default CartRowEmpty;
