import Grid from "@mui/material/Grid";

import Chronologie from "../../components/chronologie/Chronologie";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

const History = () => {
    const isOnline = useOnlineStatus();

    if (isOnline) {
        return (
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Chronologie />
                </Grid>
            </Grid>
        );
    }
    return <Offline />;
};

export default History;
