import { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import {
    commercialOfferSelector,
    decreaseQuantityOffer,
    getTotalsOffer,
    increaseQuantityOffer,
    setIsLoadingQuantityOffer,
    updateQuantityOffer,
} from "./commercialOfferSlice";
import styles from "../../styles/cartQuantityHandler.module.css";

/* ------------------------------------------------------------------------- */
const OfferQuantityHandler = ({ product }) => {
    const commercialOffer = useSelector(commercialOfferSelector);

    const [quantity, setQuantity] = useState(product.quantity);
    const canChangeQte = true;

    const dispatch = useDispatch();

    const handleDecreaseQuantity = (product) => {
        if (quantity > 1) {
            const newQuantity = parseInt(quantity, 10) - 1;
            setQuantity(newQuantity);
        }
        dispatch(decreaseQuantityOffer(product));
        dispatch(getTotalsOffer());
    };

    const handleIncreaseQuantity = (product) => {
        let newQuantity = parseInt(quantity, 10) + 1;
        if (newQuantity > 10000) {
            newQuantity = 10000;
        }
        setQuantity(newQuantity);
        dispatch(increaseQuantityOffer(product));
        dispatch(getTotalsOffer());
    };

    const handleInputChange = (quantity) => {
        if (quantity > 10000) {
            document.getElementById("inputQuantityOffer").value = 10000;
            quantity = 10000;
        }
        setQuantity(quantity);
    };

    useEffect(() => {
        setQuantity(product.quantity);
    }, [product.quantity]);

    const handleQuantityChange = (product, quantity) => {
        if (Number.isNaN(quantity) || !quantity.length || parseInt(quantity, 10) === 0) {
            setQuantity(1);
            const updatedProduct = {
                ...product,
                quantity: 0,
            };
            dispatch(updateQuantityOffer(updatedProduct));
            dispatch(getTotalsOffer());

            return toast.error("Merci d'entrer une quantité valide.", {
                id: "quantityError",
            });
        }
        const updatedProduct = {
            ...product,
            quantity: parseFloat(quantity),
        };
        dispatch(updateQuantityOffer(updatedProduct));
        dispatch(getTotalsOffer());
    };

    const quantityIsLoad = (state, quantity) => {
        if (quantity === state) {
            dispatch(setIsLoadingQuantityOffer(false));
        } else {
            dispatch(setIsLoadingQuantityOffer(true));
        }
    };

    const isLoadingQuantityFalse = () => {
        dispatch(setIsLoadingQuantityOffer(false));
    };

    useEffect(() => {
        isLoadingQuantityFalse();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commercialOffer.offerItems]);

    useEffect(() => {
        if (commercialOffer.offerItems[product.index] !== undefined) {
            quantityIsLoad(quantity, commercialOffer.offerItems[product.index].quantity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);

    return (
        <div className={styles.quantityInput}>
            {commercialOffer.isLoadingQuantityOffer === false && (
                <button disabled={!canChangeQte} onClick={() => handleDecreaseQuantity(product)}>
                    <RemoveIcon fontSize="medium" />
                </button>
            )}
            <input
                disabled={!canChangeQte}
                id="inputQuantityOffer"
                value={quantity}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyUp={(e) => handleQuantityChange(product, e.target.value)}
            />
            {commercialOffer.isLoadingQuantityOffer === false && (
                <button disabled={!canChangeQte} onClick={() => handleIncreaseQuantity(product)}>
                    <AddIcon fontSize="medium" />
                </button>
            )}
        </div>
    );
};
export default OfferQuantityHandler;
