import React, { useRef, useEffect } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DangerousIcon from "@mui/icons-material/Dangerous";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { useDispatch, useSelector } from "react-redux";

import { cartSelector, clearListInfos, setIsDisplayInfos } from "../../../features/cart/cartSlice";
import styles from "../../../styles/listHandling.module.css";
import { HREF_X3 } from "../../utils/apiConstants";

/* ------------------------------------------------------------------------- */
const ListHandling = () => {
    // console.log("[INFO] - component ListHandling is rendered");

    const dispatch = useDispatch();
    const cart = useSelector(cartSelector);

    const refInfo = useRef(null);

    const handleClickOutside = (event) => {
        const btn = document.getElementById("toggleDisplayInfos");
        if (btn && refInfo.current && !refInfo.current.contains(event.target) && !btn.contains(event.target)) {
            dispatch(setIsDisplayInfos(false));
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    useEffect(() => {
        if (cart.isDisplayInfos) {
            const element = document.getElementById("stackInfos");
            element.scrollTop += 1000000;
        }
    }, [cart.isDisplayInfos]);

    /* ----------------------------------------------------------------------- */
    return (
        <div ref={refInfo} className={cart.isDisplayInfos ? styles.animeShow : styles.animeHidden}>
            <h4 className={styles.titleList}>Journal des traitements</h4>
            <ul className={styles.listHandling} id="stackInfos">
                {cart?.listInfos.length > 0 &&
                    React.Children.toArray(
                        cart.listInfos.map((e) => (
                            <li key={e.uniqueId}>
                                {parseInt(e.type, 10) === 0 && (
                                    <div className={styles.clientInfo}>
                                        <ArrowDropDownIcon fontSize="small" className={styles.arrow} />
                                        <span className={styles.titleMsg}>Client: {e.client}</span>
                                    </div>
                                )}
                                {parseInt(e.type, 10) === 1 && (
                                    <div className={styles.boxInfo}>
                                        <InfoIcon fontSize="small" className={styles.info} />
                                        <span className={styles.titleMsg}>Information</span>
                                        <p className={styles.contentMsg}>{e.message}</p>
                                    </div>
                                )}
                                {parseInt(e.type, 10) === 2 && (
                                    <div className={styles.boxWarning}>
                                        <WarningIcon fontSize="small" className={styles.warning} />
                                        <span className={styles.titleMsg}>Avertissement</span>
                                        <p className={styles.contentMsg}>{e.message}</p>
                                        <span className={styles.linkX3}>
                                            <a rel="noreferrer" target="_blank" href={`${HREF_X3}?url=/trans/x3/erp/BIOTECH/$sessions?f=GESSOH/1/OBJBPR/M/`}>
                                                Accéder à X3
                                            </a>
                                        </span>
                                    </div>
                                )}
                                {parseInt(e.type, 10) >= 3 && (
                                    <div className={styles.boxError}>
                                        <DangerousIcon fontSize="small" className={styles.danger} />
                                        <span className={styles.titleMsg}>Configuration bloquante dans X3</span>
                                        <p className={styles.contentMsg}>{e.message}</p>
                                        <span className={styles.linkX3}>
                                            <a rel="noreferrer" target="_blank" href={`${HREF_X3}?url=/trans/x3/erp/BIOTECH/$sessions?f=GESSOH/1/OBJBPR/M/`}>
                                                Accéder à X3
                                            </a>
                                        </span>
                                    </div>
                                )}
                            </li>
                        ))
                    )}
            </ul>
            <div className={styles.footerList}>
                <button className={styles.btnClearInfos} onClick={() => dispatch(clearListInfos())}>
                    Tout effacer
                </button>
            </div>
        </div>
    );
};

export default ListHandling;
