import { useSelector } from "react-redux";

import { useGetSoldeComptableQuery } from "../../../app/services/middleware";
import { cartSelector } from "../../../features/cart/cartSlice";
import styles from "../../../styles/orderInfos.module.css";

/* ------------------------------------------------------------------------- */
const SoldComptable = () => {
    const { client } = useSelector(cartSelector);
    const { data, isLoading, isError } = useGetSoldeComptableQuery(client.clientCode);

    if (isLoading) return null;

    if (isError) return null;

    return (
        <div>
            <h4 className={styles.firstRaw}>Encours client </h4>
            <div className={styles.txtCenter}>{data.solde}</div>
        </div>
    );
};

export default SoldComptable;
