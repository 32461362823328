import { useEffect, useState } from "react";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { toast } from "react-hot-toast";
import { ReactMultiEmail, isEmail } from "react-multi-email";

import "react-multi-email/dist/style.css";
import { usePostSendOfferMutation } from "../../../app/services/lumenApi";
import styles from "../../../styles/popUpSendOffer.module.css";
import { arrayIsEmpty, toastError, toastSuccess } from "../../utils/helperFunctions";
import SubmitButton from "../buttons/SubmitButton";

/* ------------------------------------------------------------------------- */

const PopUpSendOffer = ({ clientCode, selectedEmail, listOfferId }) => {
    const [listEmails, setListEmails] = useState([selectedEmail]);
    const [listEmailsCC, setListEmailsCC] = useState([]);
    const [isCheckedCGV, setIsCheckedCGV] = useState(true);
    const [isCheckedMandat, setIsCheckedMandat] = useState(false);

    const [valid, setValid] = useState(!arrayIsEmpty(listEmails));

    const [sendMail, resSendMail] = usePostSendOfferMutation();

    useEffect(() => {
        setValid(!arrayIsEmpty(listEmails));
    }, [listEmails]);

    useEffect(() => {
        if (resSendMail.status === "rejected") {
            toastError("Une erreur s'est produite lors de l'envoi du mail", 5000);
        }

        if (resSendMail.status === "fulfilled") {
            if (!resSendMail.data.success) {
                toastError("Une erreur s'est produite lors de l'envoi du mail", 5000);
            } else {
                toastSuccess("La ou les offres commerciales ont bien été envoyées par email", 5000);
            }
        }
    }, [resSendMail]);

    /* ------------------------------------------------------------------------- */

    const sendOffer = () => {
        if (listEmails.length === 0) {
            toast.error("Merci d'indiquer au moins une adresse email de destination");
        }
        const bodyRequest = {
            client_code: clientCode,
            client_email: selectedEmail,
            list_emails: listEmails,
            list_emails_copy: listEmailsCC,
            list_offers_id: listOfferId,
            cgv_attachment: isCheckedCGV,
            mandat_attachment: isCheckedMandat,
        };
        sendMail(bodyRequest);
    };

    /* ------------------------------------------------------------------------- */

    return (
        <div className={styles.popUp}>
            <h2 className={styles.center}>Informations pour l&#39;envoi d&#39;offre(s) commerciale(s)</h2>
            <div className={styles.form}>
                <h4>Destinataire(s)</h4>
                <ReactMultiEmail
                    className={styles.inputEmailList}
                    emails={listEmails}
                    validateEmail={(email) => {
                        return isEmail(email);
                    }}
                    onChange={(_emails = []) => {
                        setListEmails(_emails);
                    }}
                    getLabel={(email, index, removeEmail) => {
                        return (
                            <div data-tag key={index}>
                                <div data-tag-item>{email}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />

                <h4>Destinataire(s) en copie</h4>
                <ReactMultiEmail
                    placeholder="Rajouter le commercial de la fiche client"
                    emails={listEmailsCC}
                    validateEmail={(email) => {
                        return isEmail(email);
                    }}
                    onChange={(emailsCC = []) => {
                        setListEmailsCC(emailsCC);
                    }}
                    getLabel={(emailsCC, index, removeEmail) => {
                        return (
                            <div data-tag key={index}>
                                <div data-tag-item>{emailsCC}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />
                <div className={styles.checks}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Rattacher le mandat"
                            onChange={(e) => setIsCheckedMandat(e.target.checked)}
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Rattacher les Conditions Génerales de Vente"
                            checked={isCheckedCGV}
                            onChange={(e) => setIsCheckedCGV(e.target.checked)}
                        />
                    </FormGroup>
                </div>
                <div className={styles.button}>
                    <SubmitButton
                        id="send-offer-client"
                        buttonStyle={!valid && "disabledBig"}
                        buttonText="Envoyer par email"
                        disabled={!valid}
                        onClick={sendOffer}
                    />
                </div>
            </div>
        </div>
    );
};

export default PopUpSendOffer;
