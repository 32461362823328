import { useState } from "react";

import { Form, Field } from "react-final-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import styles from "./register.module.css";
import { usePostRegisterLumenMutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import Spinner from "../../common/components/spinner/Spinner";
import Offline from "../../common/pages/Offline";
import { regexMail, regexInputWithoutNumber } from "../../common/utils/helperRegex";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";

const Register = () => {
    const isOnline = useOnlineStatus();
    const navigate = useNavigate();
    const [register] = usePostRegisterLumenMutation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (values) => {
        setIsSubmitting(true);
        register(values)
            .unwrap()
            .then(() => {
                navigate("/login");
                toast.success("Votre mot de passe vous a été envoyé par email.");
            })
            .catch((error) => {
                if (error.data.errors) {
                    const validationErrors = [];
                    for (const key in error.data.errors) {
                        if (Object.hown(error.data.errors, key)) {
                            validationErrors.push(error.data.errors[key][0]);
                        }
                    }
                    validationErrors.map((err, index) => toast.error(err, { id: `${index}` }));
                } else {
                    toast.error(error.data.message, { id: "singleError" });
                }
                setIsSubmitting(false);
            });
    };

    if (isSubmitting) return <Spinner />;

    if (isOnline) {
        return (
            <div className={styles.registerContainer}>
                <div className={styles.register}>
                    <h2 className={styles.title}>Créer un compte</h2>
                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = "Ce champs est requis";
                            }
                            if (regexMail.test(values.email) === false) {
                                errors.email = "Veuillez indiquer un email valide";
                            }
                            if (!values.firstname) {
                                errors.firstname = "Ce champs est requis";
                            }
                            if (regexInputWithoutNumber.test(values.firstname) === false) {
                                errors.firstname = "Veuillez vérifier l'écriture du prénom";
                            }
                            if (!values.lastname) {
                                errors.lastname = "Ce champs est requis";
                            }
                            if (regexInputWithoutNumber.test(values.lastname) === false) {
                                errors.lastname = "Veuillez vérifier l'écriture du nom";
                            }

                            return errors;
                        }}
                        render={({ submitError, handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit} className={styles.registerFormContainer}>
                                <div className={styles.registerForm}>
                                    <Field name="firstname" type="text">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    {" "}
                                                    <input {...input} type="text" placeholder="*Prénom" />
                                                </span>{" "}
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                    {submitError && <div className="error">{submitError}</div>}
                                    <Field name="lastname" type="text">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    {" "}
                                                    <input {...input} type="text" placeholder="*Nom" />
                                                </span>{" "}
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                    {submitError && <div className="error">{submitError}</div>}
                                    <Field name="email" type="email">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    {" "}
                                                    <input {...input} type="email" placeholder="*Email" />
                                                </span>{" "}
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                    {submitError && <div className="error">{submitError}</div>}
                                    <div className={styles.buttons}>
                                        <SubmitButton buttonText="Créer un compte" disabled={isSubmitting} type="submit" buttonStyle="dark" />
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    }
    return <Offline />;
};

export default Register;
