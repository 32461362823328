import { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addToQuote, getTotalsQuote } from "./quoteSlice";
import { useProductsByCodeMutation, useProductsBySearchMutation } from "../../app/services/lumenApi";
import { middleware, usePutTraiterLogMutation } from "../../app/services/middleware";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import { ENV } from "../../common/utils/apiConstants";
import { findImageItem, importAllImages } from "../../common/utils/helperFunctions";
import styles from "../../styles/searchProducts.module.css";
import { authSelector } from "../auth/authSlice";

/* ------------------------------------------------------------------------- */
const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));

const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));
/* ------------------------------------------------------------------------- */
const SearchProductsQuote = ({ index }) => {
    const dispatch = useDispatch();

    const auth = useSelector(authSelector);

    const [showSearchBar, setShowSearchBar] = useState(false);

    const [triggerStatusItem] = middleware.endpoints.getStatusItem.useLazyQuery();

    // ref on div bloc
    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowSearchBar(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const [isResearchGlobal, setIsResearchGlobal] = useState(true);

    const [callRGlobal, setCallRGlobal] = useState(false);
    const [callRCode, setCallRCode] = useState(false);

    /* - errorInfos object defined with only service property - */
    const errorInfos = {
        service: "espaceADV",
        code: 400,
        userEmail: auth.user.email,
        criticite: 3,
        environment: ENV,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    // - handling global research -
    const [globalField, setGlobalField] = useState("");
    const [items, setItems] = useState(null);
    const [animeLoading, setAnimeLoading] = useState(false);

    const [trigger, resultGlobal] = useProductsBySearchMutation();

    // - handling research by code product -
    const [codeField, setCodeField] = useState("");
    const [itemsByCode, setItemsByCode] = useState(null);
    const [animeLoadingC, setAnimeLoadingC] = useState(false);
    const [timer, setTimer] = useState(null);
    const [triggerByCode, resultByCode] = useProductsByCodeMutation();

    useEffect(() => {
        if (!resultGlobal.isLoading) {
            setAnimeLoading(false);

            if (resultGlobal.data?.success === false) {
                errorInfos.className = "SearchProduct.js calls middleware.js::getProductsBySearch()";
                errorInfos.message = resultByCode.data.message;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            }
        }

        if (resultGlobal.isError) {
            setAnimeLoading(false);
            errorInfos.className = "SearchProduct.js calls middleware.js::getProductsBySearch()";
            errorInfos.message = "Can't reach api service lireArticlesParRechercheContientTop10";
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
        }

        if (!resultGlobal.isLoading && resultGlobal.data) setItems(resultGlobal.data);
        if (!resultGlobal.data) setItems(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultGlobal.isLoading, resultGlobal.data, items, resultGlobal.isError]);

    const handleGlobalField = (value) => {
        setGlobalField(value);
        setCodeField("");
        setIsResearchGlobal(true);

        setCallRCode(false);
        setCallRGlobal(true);

        if (value !== "0" && value.length > 0) {
            let bodyParam = {
                searchString: value,
            };
            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));

            clearTimeout(timer);

            const newTimer = setTimeout(() => {
                trigger(bodyParam);
            }, 400);

            setTimer(newTimer);
            setAnimeLoading(true);
            setShowSearchBar(true);
        } else {
            setAnimeLoading(false);
            setShowSearchBar(false);
        }
    };

    /* - useEffect depends on research by code ----------------------------- */
    useEffect(() => {
        if (!resultByCode.isLoading) {
            setAnimeLoadingC(false);

            if (resultByCode.data?.success === false) {
                errorInfos.className = "SearchProduct.js calls middleware.js::getProductsByCode()";
                errorInfos.message = resultByCode.data.message;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            }
        }

        if (resultByCode.isError) {
            setAnimeLoading(false);
            errorInfos.className = "SearchProduct.js calls middleware.js::getProductsByCode()";
            errorInfos.message = "Can't reach api service lireArticlesParRechercheContientCodeArticle";
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
        }
        if (!resultByCode.isLoading && resultByCode.data) setItemsByCode(resultByCode.data);
        if (!resultByCode.data) setItemsByCode(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultByCode.isLoading, resultByCode.data, itemsByCode, resultByCode.isError]);

    const handleCodeField = (value) => {
        setCodeField(value);
        setGlobalField("");
        setIsResearchGlobal(false);

        setCallRCode(true);
        setCallRGlobal(false);

        if (value !== "0" && value.length > 0) {
            let bodyParam = {
                itemCode: value,
            };

            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));

            clearTimeout(timer);

            const newTimer = setTimeout(() => {
                triggerByCode(bodyParam);
            }, 400);

            setTimer(newTimer);
            setAnimeLoadingC(true);
            setShowSearchBar(true);
        } else {
            setAnimeLoadingC(false);
            setShowSearchBar(false);
        }
    };

    const handleAddToCart = async (product) => {
        // Add a puuid for each items
        if (!product.hasOwnProperty("puuid")) {
            const productWithPuuid = {
                ...product,
                puuid: uuidv4(),
            };
            dispatch(addToQuote(productWithPuuid));
        } else {
            dispatch(addToQuote(product));
        }

        dispatch(getTotalsQuote());
        setShowSearchBar(false);
        setGlobalField("");
        setCodeField("");
    };

    const handleCheckStatusItem = async (product) => {
        let hasSubstitute = false;
        let isActive = true;
        await triggerStatusItem(product.reference)
            .unwrap()
            .then((response) => {
                hasSubstitute = response.hasSubstitute;
                isActive = response.item.isActive;

                if (isActive) {
                    handleAddToCart(product);
                }

                if (!isActive && hasSubstitute) {
                    let newProduct;

                    let bodyParam = {
                        itemCode: response.item.substitute,
                    };
                    bodyParam = encodeURIComponent(JSON.stringify(bodyParam));

                    triggerByCode(bodyParam)
                        .unwrap()
                        .then((response) => {
                            newProduct = response[0];
                            handleAddToCart(newProduct);

                            // - Popup notification
                            toast(
                                <div>
                                    <InfoIcon fontSize="medium" className={styles.posInToast} />
                                    L&#39;article <b> {product.reference}</b> est actuellement indisponible. L&#39;artile de remplacement{" "}
                                    <b>{newProduct.reference}</b> vient d&#39;être ajouté au panier.
                                </div>,
                                {
                                    duration: 70000,
                                }
                            );
                        });
                }

                if (!(isActive || hasSubstitute)) {
                    // - Popup notification
                    toast(
                        <div>
                            <InfoIcon fontSize="medium" className={styles.posInToast} />
                            Ajout non autorisé
                            <br />
                            L&#39;article <b> {product.reference}</b> est actuellement indisponible et ne dispose pas d&#39;un article de remplacement.
                        </div>,
                        {
                            duration: 70000,
                        }
                    );
                }
            });
    };

    /* - desplay content --------------------------------------------------- */

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
                <div className={styles.searchContainer} ref={ref}>
                    <div className={styles.codeSearch}>
                        <div className={styles.selectCell}>
                            <input
                                autoComplete="off"
                                type="text"
                                name={`searchItemQuote-${index}`}
                                placeholder="Recherche par code article"
                                className={`${styles.searchInput} codeSearchInput`}
                                value={codeField}
                                onChange={(e) => handleCodeField(e.target.value)}
                            />

                            <span className={styles.anime}>
                                {animeLoadingC ? <CircularProgress color="inherit" /> : <SearchIcon className={styles.searchIcon} />}
                            </span>
                        </div>
                    </div>
                    <div className={styles.globalSearch}>
                        <div className={styles.selectCell}>
                            <input
                                autoComplete="off"
                                type="text"
                                name="text"
                                placeholder="Recherche générique"
                                className={`${styles.searchInput} globalSearchInput`}
                                value={globalField}
                                onChange={(e) => handleGlobalField(e.target.value)}
                            />

                            <span className={styles.anime}>
                                {animeLoading ? <CircularProgress color="inherit" /> : <SearchIcon className={styles.searchIcon} />}
                            </span>
                        </div>
                    </div>

                    <Grid item xs={12}>
                        <div className={showSearchBar ? styles.resultsContainer : styles.none}>
                            <span className={styles.closeSearch}>
                                <CloseIcon onClick={() => setShowSearchBar(false)} />
                            </span>
                            <div className={styles.resultsBox}>
                                {callRGlobal && !resultGlobal.isError && !items ? (
                                    <h5 style={{ textTransform: "none" }}> Aucun produit ne correspond à ces critères.</h5>
                                ) : null}

                                {callRCode && !resultByCode.isError && !itemsByCode ? (
                                    <h5 style={{ textTransform: "none" }}> Aucun produit ne correspond à ces critères.</h5>
                                ) : null}

                                {/* TODO: gerer correctement l'erreur impossible de lire la propriété article puis non présent dans le body de la reponse api */}
                                {callRGlobal && resultGlobal.isError ? (
                                    <h5 style={{ textTransform: "none" }}>
                                        <WarningIcon fontSize="medium" />
                                        Une erreur s&#39;est produit lors de la recherche.
                                    </h5>
                                ) : null}

                                {callRGlobal && resultGlobal.data?.success === false ? (
                                    <h5 style={{ textTransform: "none" }}>
                                        <WarningIcon fontSize="medium" />
                                        Une erreur s&#39;est produit lors de la recherche.
                                    </h5>
                                ) : null}

                                {callRCode && resultByCode.data?.success === false ? (
                                    <h5 style={{ textTransform: "none" }}>
                                        <WarningIcon fontSize="medium" />
                                        Une erreur s&#39;est produit lors de la recherche.
                                    </h5>
                                ) : null}

                                {callRCode && resultByCode.isError ? (
                                    <h5 style={{ textTransform: "none" }}>
                                        <WarningIcon fontSize="medium" />
                                        Une erreur s&#39;est produit lors de la recherche.
                                    </h5>
                                ) : null}

                                {resultGlobal.data?.length > 0 &&
                                    isResearchGlobal &&
                                    items &&
                                    items.map((product, index) => (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="center"
                                            className={`${styles.cartTableRow} searchResult`}
                                            key={index}
                                            onClick={() => handleCheckStatusItem(product)}
                                        >
                                            <Grid item xs={3}>
                                                <div className={styles.image}>
                                                    <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                                                </div>
                                            </Grid>

                                            <Grid item xs={5}>
                                                <h4 className={styles.ref}>{product.reference}</h4>
                                                <p className={styles.productDesignation}>{product.designation}</p>
                                                <div className={styles.containerCatalog}>
                                                    {product.catalogueniv1 !== "" && product.catalogueniv1 !== null && (
                                                        <div className={styles.catalog}>
                                                            <span>{product.catalogueniv1}</span>
                                                        </div>
                                                    )}
                                                    {product.catalogueniv2 !== null &&
                                                        product.catalogueniv2 !== "" &&
                                                        product.catalogueniv2 !== product.catalogueniv1 && (
                                                            <div className={styles.catalog}>
                                                                <span> - {product.catalogueniv2}</span>
                                                            </div>
                                                        )}
                                                    {product.catalogueniv3 !== null &&
                                                        product.catalogueniv3 !== "" &&
                                                        product.catalogueniv3 !== product.catalogueniv2 && (
                                                            <div className={styles.catalog}>
                                                                <span> - {product.catalogueniv3}</span>
                                                            </div>
                                                        )}
                                                </div>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <SubmitButton buttonText="Ajouter" />
                                            </Grid>
                                            {(product.puttc === 0 || product.remise1montantbase === 100) && (
                                                <Grid item xs={10}>
                                                    <div className={styles.stockAlert}>
                                                        <InfoIcon sx={{ color: blue[500] }} fontSize="small" />
                                                        article definit en base comme gratuit
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}

                                {resultByCode.data?.length > 0 &&
                                    !isResearchGlobal &&
                                    itemsByCode &&
                                    itemsByCode.map((product, index) => (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="center"
                                            className={`${styles.cartTableRow} searchResult`}
                                            key={index}
                                            onClick={() => handleCheckStatusItem(product)}
                                            id={`subBtnItemQuote-${index}`}
                                        >
                                            <Grid item xs={3}>
                                                <div className={styles.image}>
                                                    <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                                                </div>
                                            </Grid>

                                            <Grid item xs={5}>
                                                <h4 className={`${styles.ref} label`}>{product.reference}</h4>
                                                <p className={styles.productDesignation}>
                                                    {product.designation}
                                                    <br />
                                                </p>
                                                <div className={styles.containerCatalog}>
                                                    {product.catalogueniv1 !== "" && product.catalogueniv1 !== null && (
                                                        <div className={styles.catalog}>
                                                            <span>{product.catalogueniv1}</span>
                                                        </div>
                                                    )}
                                                    {product.catalogueniv2 !== null &&
                                                        product.catalogueniv2 !== "" &&
                                                        product.catalogueniv2 !== product.catalogueniv1 && (
                                                            <div className={styles.catalog}>
                                                                <span> - {product.catalogueniv2}</span>
                                                            </div>
                                                        )}
                                                    {product.catalogueniv3 !== null &&
                                                        product.catalogueniv3 !== "" &&
                                                        product.catalogueniv3 !== product.catalogueniv2 && (
                                                            <div className={styles.catalog}>
                                                                <span> - {product.catalogueniv3}</span>
                                                            </div>
                                                        )}
                                                </div>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <SubmitButton buttonText="Ajouter" />
                                            </Grid>
                                            {(product.puttc === 0 || product.remise1montantbase === 100) && (
                                                <Grid item xs={10}>
                                                    <div className={styles.stockAlert}>
                                                        <InfoIcon sx={{ color: blue[500] }} fontSize="small" />
                                                        article definit en base comme gratuit
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}
                            </div>
                        </div>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default SearchProductsQuote;
