import { createSlice } from "@reduxjs/toolkit";

/* ------------------------------------------------------------------------- */
const initialState = {
    selectedDay: new Date().toISOString().slice(0, 10),
    selectedDateDeb: new Date().toISOString().slice(0, 10),
    selectedDateFin: new Date().toISOString().slice(0, 10),
    labelSelection: "Chiffres réalisés",
    searchOnDuration: false,
    searchOnSpecificDay: false,
    refreshTableOrderByUser: false,
};

const statSlice = createSlice({
    name: "stat",
    initialState,
    reducers: {
        clearStat(state, action) {
            state.selectedDay = "";
            state.selectedDateDeb = "";
            state.selectedDateFin = "";
            state.labelSelection = "";
            state.searchOnDuration = false;
            state.searchOnSpecificDay = false;
            state.refreshTableOrderByUser = false;

            localStorage.setItem("selectedDay", "");
            localStorage.setItem("selectedDateDeb", "");
            localStorage.setItem("selectedDateFin", "");
            localStorage.setItem("labelSelection", "");
            localStorage.setItem("searchOnDuration", false);
            localStorage.setItem("searchOnSpecificDay", false);
            localStorage.setItem("refreshTableOrderByUser", false);
        },

        setSelectedDay(state, action) {
            state.selectedDay = action.payload;
            localStorage.setItem("selectedDay", action.payload);
        },

        clearSelectedDay(state, action) {
            state.selectedDay = "";
            localStorage.setItem("selectedDay", "");
        },

        setSelectedDateDeb(state, action) {
            state.selectedDateDeb = action.payload;
            localStorage.setItem("selectedDateDeb", action.payload);
        },

        clearSelectedDateDeb(state, action) {
            state.selectedDateDeb = "";
            localStorage.setItem("selectedDateDeb", "");
        },

        setSelectedDateFin(state, action) {
            state.selectedDateFin = action.payload;
            localStorage.setItem("selectedDateFin", action.payload);
        },

        clearSelectedDateFin(state, action) {
            state.selectedDateFin = "";
            localStorage.setItem("selectedDateFin", "");
        },

        setLabelSelection(state, action) {
            state.labelSelection = action.payload;
            localStorage.setItem("labelSelection", action.payload);
        },

        clearLabelSelection(state, action) {
            state.labelSelection = "";
            localStorage.setItem("labelSelection", "");
        },

        setSearchOnDuration(state, action) {
            state.searchOnDuration = action.payload;
            localStorage.setItem("searchOnDuration", action.payload);
        },

        clearSearchOnDuration(state, action) {
            state.searchOnDuration = false;
            localStorage.setItem("searchOnDuration", false);
        },

        setSearchOnSpecificDay(state, action) {
            state.searchOnSpecificDay = action.payload;
            localStorage.setItem("searchOnSpecificDay", action.payload);
        },

        clearSearchOnSpecificDay(state, action) {
            state.searchOnSpecificDay = false;
            localStorage.setItem("searchOnSpecificDay", false);
        },

        setRefreshTableOrderByUser(state, action) {
            state.refreshTableOrderByUser = action.payload;
            localStorage.setItem("refreshTableOrderByUser", action.payload);
        },

        clearRefreshTableOrderByUser(state, action) {
            state.refreshTableOrderByUser = false;
            localStorage.setItem("refreshTableOrderByUser", false);
        },
    },

    extraReducers: (builder) => {},
});

export default statSlice.reducer;

export const statSelector = (state) => state.stat;

export const {
    clearStat,
    setSelectedDay,
    clearSelectedDay,
    setSelectedDateDeb,
    clearSelectedDateDeb,
    setSelectedDateFin,
    clearSelectedDateFin,
    setLabelSelection,
    clearLabelSelection,
    setSearchOnDuration,
    clearSearchOnDuration,
    setSearchOnSpecificDay,
    clearSearchOnSpecificDay,
    setRefreshTableOrderByUser,
    clearRefreshTableOrderByUser,
} = statSlice.actions;
