import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { usePostRefreshTokenMutation } from "../../../app/services/keycloakApi";
import { lumenApi } from "../../../app/services/lumenApi";
import { usePostArticlesMutation } from "../../../app/services/middleware";
import { authSelector } from "../../../features/auth/authSlice";
import { setClient, setShippingAddress, addListInfos, clearListInfos, clearCart, restoreCart } from "../../../features/cart/cartSlice";
import SelectClientOrderDuplicate from "../../../features/order/SelectClientOrderDuplicate";
import styles from "../../../styles/duplicatePopUp.module.css";
import { getFormDataToRefreshKeycloakToken } from "../../utils/helperFunctions";
import CustomSubmitButton from "../buttons/CustomSubmitButton";

/* ------------------------------------------------------------------------- */

const PopUpDuplicate = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);

    const [clientOrder, setClientOrder] = useState(props.clientOrigin);
    const [triggerRefreshToken] = usePostRefreshTokenMutation();
    const [shippingAdr, setShippingAdr] = useState(props.order.livraison);
    const [products, setProducts] = useState([]);
    const [triggerGetOrderDetails, resGetOrderDetails] = lumenApi.endpoints.getOrderDetails.useLazyQuery();
    const [triggerArticle] = usePostArticlesMutation();

    /* --------------------------------------------------------------------------
     * - function to call triggerArticle for each product of the order
     * ----------------------------------------------------------------------- */
    const getProductsDetails = () => {
        let index = 0;
        products.forEach(async (product) => {
            const body = {
                article: product.code,
            };
            const bodyParam = encodeURIComponent(JSON.stringify(body));
            await triggerArticle(bodyParam)
                .unwrap()
                .then((response) => {
                    const articles = {
                        ...response.articles.article[0],
                        ...product,
                        puuid: uuidv4(),
                        indexInCart: index,
                        quantity: product.quantite,
                    };
                    dispatch(restoreCart(articles));
                    index += 1;
                });
        });
    };

    const handleDuplicate = (clientData) => {
        // call refreshToken
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);
        dispatch(
            setClient({
                clientCode: clientData.clientCode,
                client: clientData.client,
                codepostal: clientData.codepostal,
            })
        );
        dispatch(setShippingAddress(clientData.adresseLivraison));

        const info = [
            {
                uniqueId: Math.random().toString(36).substring(2, 9),
                type: "0",
                client: `${clientData.clientCode} - ${clientData.client}`,
            },
        ];
        dispatch(addListInfos(info));
        navigate("/order");
    };

    /* --------------------------------------------------------------------- */

    useEffect(() => {
        dispatch(clearListInfos());
        dispatch(clearCart());

        const order = {
            codeClient: clientOrder.clientCode,
            numeroCommande: props.order.numero,
        };
        triggerGetOrderDetails(order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resGetOrderDetails.status === "fulfilled") {
            if (resGetOrderDetails.data.success === true) {
                setProducts(resGetOrderDetails.data.commandes.commande[0].articles.article);
            }
        }
    }, [resGetOrderDetails]);

    useEffect(() => {
        if (products.length !== 0) {
            getProductsDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    /* --------------------------------------------------------------------- */

    return (
        <div className={styles.container}>
            <h2 className={styles.center}>Dupliquer la commande : {props.order.numero}</h2>
            <div className={styles.choice}>
                <h4>Pour quel client souhaitez vous dupliquer la commande ?</h4>
                <div className={styles.margin3}>
                    <SelectClientOrderDuplicate setClient={setClientOrder} setAddress={setShippingAdr} />
                </div>
            </div>

            <Grid container spacing={1}>
                <Grid item xs={12} id="selectOrder">
                    <div className={styles.selectedClient}>
                        Client sélectionné:{" "}
                        <span className={styles.bold}>
                            {clientOrder.clientCode} - {clientOrder.client}
                        </span>
                    </div>
                </Grid>
                <div className={styles.buttonDuplicate}>
                    <Grid item xs={3}>
                        <CustomSubmitButton
                            buttonText="Dupliquer"
                            onClick={() =>
                                handleDuplicate({
                                    clientCode: clientOrder.clientCode,
                                    client: clientOrder.client,
                                    codepostal: props.order.livraison.codepostal,
                                    adresseLivraison: shippingAdr.numero,
                                })
                            }
                        />
                    </Grid>
                </div>
            </Grid>
        </div>
    );
};

export default PopUpDuplicate;
