import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { authSelector } from "../../features/auth/authSlice";

const RequireBDSupervisor = ({ children }) => {
    const { roles } = useSelector(authSelector);

    if (roles.indexOf("BD_Supervisor") === -1) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default RequireBDSupervisor;
