import { useState, useEffect } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Form, Field } from "react-final-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { usePostTokenMutation } from "../../app/services/keycloakApi";
import { usePostLoginLumenMutation, usePostForgotPasswordMutation } from "../../app/services/lumenApi";
import { usePutTraiterLogMutation, usePostSendNotificationMutation } from "../../app/services/middleware";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import Offline from "../../common/pages/Offline";
import { ENV, KEYCLOAK_CLIENT_ID, KEYCLOAK_CLIENT_SECRET, KEYCLOAK_PASSWORD, KEYCLOAK_USERNAME } from "../../common/utils/apiConstants";
import { toastError } from "../../common/utils/helperFunctions";
import { regexMail } from "../../common/utils/helperRegex";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import styles from "../../styles/login.module.css";

/* ------------------------------------------------------------------------- */
const Login = () => {
    const isOnline = useOnlineStatus();

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";

    const [login] = usePostLoginLumenMutation();

    const [showPassword, setShowPassord] = useState(false);

    const errorInfos = {
        service: "espaceADV",
        className: "Login.js::login()",
        criticite: 3,
        environment: ENV,
    };
    const errorInfosPassword = {
        service: "espaceADV",
        className: "Login.js::forgotpassword()",
    };

    const [triggerKeyCloakToken, resKeyCloakToken] = usePostTokenMutation();

    const formData = new URLSearchParams();
    formData.append("client_id", KEYCLOAK_CLIENT_ID);
    formData.append("client_secret", KEYCLOAK_CLIENT_SECRET);
    formData.append("grant_type", "password");
    formData.append("username", KEYCLOAK_USERNAME);
    formData.append("password", KEYCLOAK_PASSWORD);

    const [triggerAlert] = usePostSendNotificationMutation();
    const [triggerLog] = usePutTraiterLogMutation();

    const [forgotpassword] = usePostForgotPasswordMutation();

    const handleShowPassword = () => {
        setShowPassord(!showPassword);
    };

    const onSubmit = async (values) => {
        try {
            await login(values)
                .unwrap()
                .then((response) => {
                    if (response.success) {
                        triggerKeyCloakToken(formData);
                    }
                });
        } catch (error) {
            if (values.email) {
                errorInfos.userEmail = values.email;
            }

            if (error.hasOwnProperty("data")) {
                if (error.data.errors) {
                    const validationErrors = [];
                    for (const key in error.data.errors) {
                        if (Object.hasOwn(error.data.errors, key)) {
                            validationErrors.push(error.data.errors[key][0]);
                        }
                    }
                    validationErrors.map((err) => toast.error(err));
                } else {
                    if (error.data.message === "Invalid credentials") {
                        toast(
                            <>
                                <WarningAmberIcon fontSize="medium" className={styles.posInToast} />
                                Vous avez saisi une adresse e-mail ou un mot de passe incorrect, veuillez réessayer.
                            </>,
                            {
                                duration: 7000,
                            }
                        );
                        // toast.error("Vous avez saisi une adresse e-mail ou un mot de passe incorrect, veuillez réessayer.");
                    } else {
                        toast.error(error.data.message);
                    }
                    errorInfos.message = error.data.message;
                    errorInfos.code = 400;
                    errorInfos.criticite = 2;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);
                }
            } else {
                toast.error(
                    <div>
                        <h4>Le service n&#39;est pas accessible</h4>
                        <div>DNS Error: ERR_NAME_NOT_RESOLVED</div>
                    </div>,
                    {
                        position: "top-center",
                    }
                );
                errorInfos.message = "Le service n'est pas accessible, DNS Error: ERR_NAME_NOT_RESOLVED";
                errorInfos.code = 400;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            }
        }
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleForgotPassword = () => {
        const emailValue = { email: document.getElementById("NewPassword").value };
        try {
            forgotpassword(emailValue)
                .unwrap()
                .then(() => {
                    navigate("/login");
                    toast.success("Votre nouveau mot de passe vous a été envoyé par email.");
                });
        } catch (error) {
            toast.error(error.data.message);
            errorInfosPassword.message = error.data.message;
            errorInfosPassword.code = 400;
            errorInfosPassword.userEmail = emailValue;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfosPassword));
            triggerAlert(bodyParam);
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (resKeyCloakToken.status === "rejected") {
            toast.error(
                <div>
                    <div>
                        Erreur lors de l&#39;authentifcation de l&#39;utilisateur
                        <br />
                        (Keycloak security)
                    </div>
                </div>,
                {
                    position: "top-center",
                }
            );

            errorInfos.message = `Error get keycloak access_token: requestId: ${resKeyCloakToken.requestId}, details:, ${resKeyCloakToken.error.error}, formData:, ${formData}`;
            errorInfos.code = 400;
            errorInfos.criticite = 3;

            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
        } else if (resKeyCloakToken.status === "fulfilled" && resKeyCloakToken.isSuccess === false) {
            toast.error(
                <div>
                    <div>
                        Erreur lors de l&#39;authentifcation de l&#39;utilisateur
                        <br />
                        (Keycloak security)
                    </div>
                </div>,
                {
                    position: "top-center",
                }
            );

            errorInfos.message = `Error get keycloak access_token: requestId: ${resKeyCloakToken.requestId}, details:, ${resKeyCloakToken.error.error}, formData:, ${formData}`;
            errorInfos.code = 400;
            errorInfos.criticite = 3;

            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
        } else if (resKeyCloakToken.status === "fulfilled" && resKeyCloakToken.isSuccess === true) {
            navigate(from, { replace: true });
        }
    }, [resKeyCloakToken]);

    // - display render component ---------------------------------------------
    if (isOnline) {
        return (
            <div className={styles.loginContainer}>
                <div className={styles.login}>
                    <h2 className={styles.title}>Se connecter</h2>
                    <p className={styles.description}>Pour accéder à votre compte</p>

                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = "Ce champs est requis";
                            }
                            if (regexMail.test(values.email) === false) {
                                errors.email = "Veuillez indiquer un email valide";
                            }
                            if (!values.password) {
                                errors.password = "Ce champs est requis";
                            }
                            return errors;
                        }}
                        render={({ submitError, handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit} className={styles.loginFormContainer}>
                                <div className={styles.loginForm}>
                                    <Field name="email" type="email">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <input {...input} type="text" placeholder="*Email" />
                                                </span>{" "}
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="password">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <input {...input} type={showPassword ? "text" : "password"} placeholder="*Mot de passe" />
                                                    <IconButton onClick={handleShowPassword}>
                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </span>{" "}
                                                <div>
                                                    <Button variant="text" onClick={handleClickOpen} id={styles.buttonforgottenpassword}>
                                                        Mot de passe oublié ?
                                                    </Button>
                                                    <Dialog open={open} onClose={handleClose}>
                                                        <DialogTitle>Mot de passe oublié</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                Merci de renseigner votre adresse mail pour recevoir un nouveau mot de passe
                                                            </DialogContentText>
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                id="NewPassword"
                                                                label="Adresse Mail"
                                                                type="email"
                                                                fullWidth
                                                                variant="standard"
                                                            />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleClose}>Annuler</Button>
                                                            <Button onClick={handleForgotPassword}>Valider</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                                {meta.error && meta.touched && <span className={styles.errorText}>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    {submitError && <div className="error">{submitError}</div>}{" "}
                                    <div className={styles.buttons}>
                                        <SubmitButton buttonText="Me connecter" disabled={submitting} type="submit" buttonStyle="dark" />
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    }
    return <Offline />;
};

export default Login;
