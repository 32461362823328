import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { useGetCreditsQuery } from "../../app/services/middleware";
import styles from "../../styles/creditsSold.module.css";
import { cartSelector } from "../cart/cartSlice";

const CreditsSold = () => {
    const { client } = useSelector(cartSelector);

    const { data, isLoading, isError } = useGetCreditsQuery(client.clientCode);

    if (isLoading) return null;
    if (isError) return null;
    return (
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" className={styles.txtCenter}>
            {data ? (
                data?.map((credit, index) => (
                    <Grid item xs={2} key={index}>
                        <h4>{credit.libelle}</h4>
                        {credit.solderestant}
                    </Grid>
                ))
            ) : (
                <br />
            )}
        </Grid>
    );
};

export default CreditsSold;
