import React, { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import PaidIcon from "@mui/icons-material/Paid";
import SaveIcon from "@mui/icons-material/Save";
import { Grid } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { usePostRefreshTokenMutation } from "../../../app/services/keycloakApi";
import { useAddQuoteMutation, useUpdateQuoteMutation } from "../../../app/services/lumenApi";
import { usePutTraiterLogMutation } from "../../../app/services/middleware";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import { clearLocation, updateLastLocation } from "../../../features/location/locationSlice";
import { clearQuote, quoteSelector } from "../../../features/quote/quoteSlice";
import QuoteTable from "../../../features/quote/QuoteTable";
import RefInput from "../../../features/quote/RefInput";
import SelectAddressGeneric from "../../../features/quote/SelectAddressGeneric";
import SelectClientQuote from "../../../features/quote/SelectClientQuote";
import styles from "../../../styles/addQuote.module.css";
import InfosClient from "../../components/infosClient/InfosClient";
import InfosFact from "../../components/infosClient/InfosFact";
import { ENV } from "../../utils/apiConstants";
import { getFormDataToRefreshKeycloakToken, toastError } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const AddQuote = () => {
    const isOnline = useOnlineStatus();
    const quote = useSelector(quoteSelector);
    const auth = useSelector(authSelector);
    const dispatch = useDispatch();
    const location = useLocation();

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();
    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();
    const [triggerAddQuote, resTriggerAddQuote] = useAddQuoteMutation();
    const [triggerUpdateQuote, resTriggerUpdateQuote] = useUpdateQuoteMutation();

    const handleClearQuote = () => {
        dispatch(clearQuote());
        dispatch(clearLocation());
    };

    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(updateLastLocation(location.pathname));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilisateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.className = "AddQuote.js - event onClick link Modifier";
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
                dispatch(clearLocation());
                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);

    const msgDelete = "Êtes-vous sûr de vouloir supprimer la saisie en cours ?";

    function confirmPopup(message) {
        // - Check user time session is not expired
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <h2>Confirmer</h2>
                        <p>{message}</p>
                        <button onClick={onClose}>Refuser</button>
                        <button
                            onClick={() => {
                                handleClearQuote();
                                onClose();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    /* --------------------------------------------------------------------- */

    useEffect(() => {
        if (resTriggerAddQuote.status === "fulfilled") {
            if (resTriggerAddQuote.data?.success === true) {
                toast.success(<>Le devis a été sauvegardée avec succès.</>, {
                    duration: 7000,
                });
                dispatch(clearQuote());
            } else {
                toast.error(<>Une erreur s&#39;est produite lors de la sauvegarde du devis.</>, {
                    duration: 7000,
                });
            }
        }
        if (resTriggerUpdateQuote.status === "fulfilled") {
            if (resTriggerUpdateQuote.data?.success === true) {
                toast.success(<>Le devis modifié a été sauvegardée avec succès.</>, {
                    duration: 7000,
                });
                dispatch(clearQuote());
            } else {
                toast.error(<>Une erreur s&#39;est produite lors de la sauvegarde du devis.</>, {
                    duration: 7000,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerAddQuote, resTriggerUpdateQuote]);

    function handleSaveQuote() {
        if (quote.refQuote === "") {
            toastError("Merci de saisir une référence", 7000);
            return;
        }
        // - Check user time session is not expired
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);
        if (typeof quote.clientQuote === "object" && quote.clientQuote !== null && quote.shippingAddressClient !== "" && quote.items.length > 0) {
            const body = {
                status: 0,
                client_code: quote.clientQuote.clientCode,
                client: quote.clientQuote.client,
                zip_code: quote.clientQuote.codepostal,
                shipping_address: quote.shippingAddressClient,
                reference: quote.refQuote,
                currency: quote.clientQuote.devise,
                amount_ttc: quote.quoteTotalAmountCheckout,
                items: [
                    ...quote.items.map((item) => ({
                        reference: item.reference,
                        label: item.designation,
                        quantity: item.quoteQuantity,
                        puttc: item.puttc,
                        discount: parseInt(item.remise1montant, 10),
                        comment: item.comment ? item.comment : "",
                    })),
                ],
            };

            if (!quote.isRecoveryQuote) {
                const bodyAdd = {
                    uuid: uuidv4(),
                    ...body,
                };
                triggerAddQuote(encodeURIComponent(JSON.stringify(bodyAdd)));
            } else {
                triggerUpdateQuote({
                    id: quote.quoteId,
                    ...body,
                });
            }
        } else {
            toast.error(<>Le devis ne peut pas être sauvegardée car il est vide.</>, {
                duration: 7000,
            });
        }
    }

    // -- display content -------------------------------------------------- */
    if (isOnline) {
        return (
            <>
                {/* - Icons action on top right */}
                <div onClick={() => confirmPopup(msgDelete)} className={`${styles.tooltipBtn} ${styles.deleteBtn}`}>
                    <DeleteIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Supprimer la saisie en cours</div>
                </div>
                <div onClick={handleSaveQuote} className={`${styles.tooltipBtn} ${styles.saveBtn}`}>
                    <SaveIcon className="save-icon" fontSize="medium" />
                    <div className={styles.tooltiptext}>Sauvegarder le panier</div>
                </div>
                {/* --------------------------------------- */}
                <Grid container direction="row" spacing={1} justifyContent="center">
                    {!quote.clientQuote.clientCode ? (
                        <Grid item xs={9}>
                            <SelectClientQuote tiers={false} />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <h2 className={styles.txtCenter} id="titleAddQuote">
                                    Création de devis
                                </h2>
                            </Grid>
                            <Grid item xs={12}>
                                <InfosClient />
                            </Grid>
                            <Grid item xs={6}>
                                <h3>
                                    <PaidIcon fontSize="medium" className={styles.adjustPos} />
                                    Adresse de facturation
                                </h3>
                                <InfosFact client={quote.clientQuote} factAddress={quote.factAddress} />
                            </Grid>

                            <Grid item xs={6}>
                                {quote.clientQuote.clientCode && (
                                    <div className={styles.livraisonInfos}>
                                        <SelectAddressGeneric shippingAddress={quote.shippingAddressClient} client={quote.clientQuote} />
                                    </div>
                                )}
                                <div className={styles.blockInput}>
                                    <RefInput />
                                </div>
                            </Grid>

                            <Grid item xs={12} className={styles.pdR10}>
                                {quote.clientQuote.clientCode && quote.shippingAddressClient && <QuoteTable />}
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    }
    return <Offline />;
};

export default AddQuote;
