import { useState, useEffect } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import Select from "react-select";

import {
    usePostGetRolesByUserMutation,
    useGetRolesQuery,
    useAddRolesUserMutation,
    useDeleteRolesUserMutation,
    usePostDeleteUserMutation,
    lumenApi,
} from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import Spinner from "../../common/components/spinner/Spinner";
import TableSearchInput from "../../common/components/tableSearchInput/TableSearchInput";
import * as helperFunctions from "../../common/utils/helperFunctions";
import styles from "../../styles/updateRoleUser.module.css";

/* ------------------------------------------------------------------------- */

const UpdateRoleUser = () => {
    const [triggerQueryGetUsers, resQueryGetUsers] = lumenApi.endpoints.getUsers.useLazyQuery();
    const [selectedUser, setSelectedUser] = useState(0);
    const [listUsers, setListUsers] = useState([]);
    const [allUser, setAllUser] = useState([]);

    const resQueryGetRoles = useGetRolesQuery();
    const [listRoles, setListRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState(null);

    const options = [];

    const [triggerRolesByUser, resRolesByUser] = usePostGetRolesByUserMutation();
    const [triggerAddRolesUser, resAddRolesUser] = useAddRolesUserMutation();
    const [triggerDelRolesUser, resDelRolesUser] = useDeleteRolesUserMutation();
    const [triggerDeleteUser, resDeleteUser] = usePostDeleteUserMutation();
    const [searchText, setSearchText] = useState("");

    const deleteUser = () => {
        triggerDeleteUser({ id: selectedUser });
    };

    const confirmPopupTreatment = () => {
        if (selectedUser !== 0) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className={styles.customUI2}>
                            <div className={styles.beatsWarning}>
                                <WarningAmberIcon className={styles.warning} />
                            </div>
                            <span className={styles.titleWarning}>Supprimer</span>

                            <p>
                                {" "}
                                Attention vous êtes sur le point de supprimer l&#39;utilisateur.
                                <br />
                                <span className={styles.bold}>Il s&#39;agit d&#39;une action irrréversible êtes vous sûr de votre choix ?</span>
                            </p>
                            <button onClick={onClose}>Non</button>
                            <button
                                onClick={async () => {
                                    deleteUser();
                                    onClose();
                                }}
                            >
                                Oui
                            </button>
                        </div>
                    );
                },
            });
        }
    };

    const handleListUserClick = (event, index) => {
        setSelectedUser(index);
        triggerRolesByUser({ user_id: index });
    };

    /**
     * function call onClick button Enregister
     */
    const handleSaveRoleUser = () => {
        const listAddRoleUser = [];

        selectedRoles.forEach((element) => {
            listAddRoleUser.push(element.value);
        });

        triggerAddRolesUser({ user_id: selectedUser, roles: listAddRoleUser });

        const listDelRoleUser = [];
        listRoles.forEach((element) => {
            if (!listAddRoleUser.includes(element.value)) {
                listDelRoleUser.push(element.value);
            }
        });
        triggerDelRolesUser({ user_id: selectedUser, roles: listDelRoleUser });
    };

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        triggerQueryGetUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resDeleteUser.status === "fulfilled") {
            if (resDeleteUser.data.success === true) {
                toast.success("Utilisateur supprimé avec succès");
                triggerQueryGetUsers();
            } else {
                toast.error("Une erreur est survenue");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resDeleteUser]);

    useEffect(() => {
        if (resQueryGetUsers.status === "fulfilled") {
            const sort = [...resQueryGetUsers.data].sort((a, b) => (a.lastname > b.lastname ? 1 : -1));
            setListUsers(sort);
            setAllUser(sort);
        }
    }, [resQueryGetUsers]);

    useEffect(() => {
        if (resQueryGetRoles.status === "fulfilled") {
            if (resQueryGetRoles.data.length > 0) {
                resQueryGetRoles.data.forEach((elem) => {
                    const itemRole = {
                        value: elem.id,
                        label: elem.name,
                    };

                    options.push(itemRole);
                });
                setListRoles(options);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resQueryGetRoles]);

    useEffect(() => {
        if (resRolesByUser.status === "fulfilled") {
            const rolesToSelect = [];
            resRolesByUser.data.roles.forEach((element) => {
                let index = 0;
                listRoles.forEach((existingRole) => {
                    if (existingRole.value === element.id) {
                        rolesToSelect.push(listRoles[index]);
                    }
                    index += 1;
                });
            });

            setSelectedRoles(rolesToSelect);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRolesByUser]);

    useEffect(() => {
        if (resAddRolesUser.isError || resDelRolesUser.isError) {
            toast.error("Une erreur s'est produite lors de la mise à jour des droits de l'utilisateur");
        }
        if (resAddRolesUser.status === "fulfilled" && resDelRolesUser.status === "fulfilled") {
            toast.success("Droits utilisateur mis à jour.");
        }
    }, [resAddRolesUser, resDelRolesUser]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(helperFunctions.escapeRegExp(searchValue), "i");
        const filteredRows = allUser.filter((data) => {
            return Object.keys(data).some((field) => {
                return data[field] !== null ? searchRegex.test(data[field].toString()) : null;
            });
        });
        setListUsers(filteredRows);
    };
    /* ----------------------------------------------------------------------- */

    if (allUser.length === 0 || listRoles.length === 0) {
        return <Spinner />;
    }

    if (allUser.length > 0 && listRoles.length > 0) {
        return (
            <div className={styles.registerContainer}>
                <h2 className={styles.title}>Paramétrer les droits utilisateurs</h2>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={3}>
                        <div className={styles.usr}>
                            <div className={styles.searchBar}>
                                <TableSearchInput
                                    value={searchText}
                                    onChange={(event) => requestSearch(event.target.value)}
                                    clearSearch={() => requestSearch("")}
                                />
                            </div>
                            <div className={styles.listUsr}>
                                <List component="nav" aria-label="main mailbox folders">
                                    {listUsers.map((role, index) => (
                                        <ListItemButton
                                            selected={selectedUser === role.id}
                                            onClick={(event) => handleListUserClick(event, role.id)}
                                            key={index}
                                        >
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`${role.firstname} ${role.lastname}`} />
                                            <IconButton aria-label="delete" size="small" onClick={confirmPopupTreatment}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <div className={styles.selectRoles}>
                            <Select
                                defaultValue={selectedRoles}
                                value={selectedRoles}
                                isMulti
                                name="colors"
                                onChange={setSelectedRoles}
                                options={listRoles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.bottomButtons}>
                            <SubmitButton buttonText="Enregistrer" onClick={handleSaveRoleUser} id="SubmitUpdateRoleUser" />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

export default UpdateRoleUser;
