import { createSlice } from "@reduxjs/toolkit";

import { lumenApi } from "../../app/services/lumenApi";
import { middleware } from "../../app/services/middleware";
import { BASE_BDD_MIDDLEWARE_API } from "../../common/utils/apiConstants";

/* ------------------------------------------------------------------------- */
const initialState = {
    nomBaseSAGE: BASE_BDD_MIDDLEWARE_API,
    cartItems: localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [],
    cartDocTypes: localStorage.getItem("cartDocTypes") ? JSON.parse(localStorage.getItem("cartDocTypes")) : [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    cartTotalAmountCheckout: 0,
    shippingAddress: localStorage.getItem("shippingAddress") ? localStorage.getItem("shippingAddress") : "",
    zoneShippingAddress: localStorage.getItem("zoneShippingAddress") ? localStorage.getItem("zoneShippingAddress") : "",
    email5: localStorage.getItem("email5") ? localStorage.getItem("email5") : "",
    emailInvoice: localStorage.getItem("emailInvoice") ? localStorage.getItem("emailInvoice") : "",
    service: localStorage.getItem("service") ? JSON.parse(localStorage.getItem("service")) : [],
    client: localStorage.getItem("client") ? JSON.parse(localStorage.getItem("client")) : [],
    carrier: localStorage.getItem("carrier") ? JSON.parse(localStorage.getItem("carrier")) : [],
    selectedDeliveryDate: new Date().toISOString().slice(0, 10),
    reglement: localStorage.getItem("reglement") ? JSON.parse(localStorage.getItem("reglement")) : "",
    listInfos: localStorage.getItem("listInfos") ? JSON.parse(localStorage.getItem("listInfos")) : [],
    originComment: localStorage.getItem("originComment") ? JSON.parse(localStorage.getItem("originComment")) : "",
    isRecoveryOrder: localStorage.getItem("isRecoveryOrder") ? localStorage.getItem("isRecoveryOrder") === "true" : false,
    isLoadingInfosClient: false,
    isDisplayInfos: false,
    isDisplaySubstitute: false,
    isLoadingQuantity: false,
    isDisplayOriginOrder: false,
    country: localStorage.getItem("country") ? localStorage.getItem("country") : "",
    isOrderWeb: localStorage.getItem("isOrderWeb") ? localStorage.getItem("isOrderWeb") === "true" : false,
    fromQuote: localStorage.getItem("fromQuote") ? localStorage.getItem("fromQuote") === "true" : false,
    fromOffer: localStorage.getItem("fromOffer") ? localStorage.getItem("fromOffer") === "true" : false,
    siteSource: localStorage.getItem("siteSource") ? localStorage.getItem("siteSource") : "",
    isOrderImport: localStorage.getItem("isOrderImport") ? localStorage.getItem("isOrderImport") === "true" : false,
    orderFrom: localStorage.getItem("orderFrom") ? localStorage.getItem("orderFrom") : "",
    smiley: localStorage.getItem("smiley") ? localStorage.getItem("smiley") : 0,
    orderType: localStorage.getItem("orderType") ? localStorage.getItem("orderType") : "",
    orderX3Type: localStorage.getItem("orderX3Type") ? localStorage.getItem("orderX3Type") : [],
    numberDocument: localStorage.getItem("numberDocument") ? localStorage.getItem("numberDocument") : [],
    orderWebId: localStorage.getItem("orderWebId") ? localStorage.getItem("orderWebId") : "",
    orderSaisieId: localStorage.getItem("orderSaisieId") ? localStorage.getItem("orderSaisieId") : "",
    orderOriginId: localStorage.getItem("orderOriginId") ? localStorage.getItem("orderOriginId") : "",
    clientFacturation: localStorage.getItem("clientFacturation") ? localStorage.getItem("clientFacturation") : "",
    offerClient: localStorage.getItem("offerClient") ? localStorage.getItem("offerClient") : "",
    blockingError: false,
    wsuid: "",
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart(state, action) {
            const tempProduct = {
                ...action.payload,
                indexInCart: state.cartItems.length,
                quantity: 1,
            };
            state.cartItems.push(tempProduct);
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        restoreCart(state, action) {
            const tempProduct = { ...action.payload };
            state.cartItems.push(tempProduct);
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        removeFromCart(state, action) {
            const updatedCart = state.cartItems.filter((item) => item.indexInCart !== action.payload.indexInCart);
            state.cartItems = updatedCart;
            state.cartItems.forEach((item, index) => {
                item.indexInCart = index;
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        decreaseQuantity(state, action) {
            const itemExist = state.cartItems.find((item) => item.indexInCart === action.payload.indexInCart);
            if (itemExist.quantity > 1) {
                itemExist.quantity -= 1;
            } else {
                const updatedCart = state.cartItems.filter((item) => item.indexInCart !== action.payload.indexInCart);
                state.cartItems = updatedCart;
                state.cartItems.forEach((item, index) => {
                    item.indexInCart = index;
                });
                localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        increaseQuantity(state, action) {
            const itemExist = state.cartItems.find((item) => item.indexInCart === action.payload.indexInCart);
            if (itemExist.quantity < 10000) {
                itemExist.quantity += 1;
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        updateQuantity(state, action) {
            const itemExist = state.cartItems.find((item) => item.indexInCart === action.payload.indexInCart);
            itemExist.quantity = action.payload.quantity;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        updateComment(state, action) {
            const itemExist = state.cartItems.find((item) => item.indexInCart === action.payload.indexInCart);
            itemExist.comment = action.payload.comment;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        updatePuuid(state, action) {
            const itemExist = state.cartItems.find((item) => item.indexInCart === action.payload.indexInCart);
            itemExist.puuid = action.payload.puuid;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        clearCart(state, action) {
            state.cartItems = [];
            state.cartDocTypes = [];
            state.cartTotalQuantity = 0;
            state.cartTotalAmount = 0;
            state.cartTotalAmountCheckout = 0;
            state.shippingAddress = "";
            state.zoneShippingAddress = "";
            state.email5 = "";
            state.emailInvoice = "";
            state.client = [];
            state.selectedDeliveryDate = new Date().toISOString().slice(0, 10);
            state.country = "";
            // state.listInfos = [];
            state.isRecoveryOrder = false;
            state.isLoadingInfosClient = false;
            state.isLoadingQuantity = false;
            state.isDisplayInfos = false;
            state.isDisplayOriginOrder = false;
            state.isOrderWeb = false;
            state.fromQuote = false;
            state.fromOffer = false;
            state.siteSource = "";
            state.isOrderImport = false;
            state.orderOriginId = "";
            state.orderWebId = "";
            state.originComment = "";
            state.smiley = 0;
            state.reglement = "";
            state.blockingError = false;
            state.wsuid = "";
            state.carrier = [];
            state.service = [];

            // - Be sure to update all vars in localStorage -
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            localStorage.setItem("cartDocTypes", JSON.stringify(state.cartDocTypes));
            localStorage.setItem("cartTotalQuantity", 0);
            localStorage.setItem("cartTotalAmount", 0);
            localStorage.setItem("cartTotalAmountCheckout", 0);
            localStorage.setItem("shippingAddress", "");
            localStorage.setItem("zoneShippingAddress", "");
            localStorage.setItem("email5", "");
            localStorage.setItem("emailInvoice", "");
            localStorage.setItem("client", []);
            localStorage.setItem("selectedDeliveryDate", JSON.stringify(state.selectedDeliveryDate));
            localStorage.setItem("country", "");
            // localStorage.setItem("listInfos", JSON.stringify(state.listInfos));
            localStorage.setItem("isLoadingInfosClient", false);
            localStorage.setItem("isLoadingQuantity", false);
            localStorage.setItem("isDisplayInfos", false);
            localStorage.setItem("isDisplayOriginOrder", false);
            localStorage.setItem("isOrderWeb", false);
            localStorage.setItem("fromQuote", false);
            localStorage.setItem("fromOffer", false);
            localStorage.setItem("siteSource", "");
            localStorage.setItem("isOrderImport", false);
            localStorage.setItem("orderOriginId", "");
            localStorage.setItem("orderWebId", "");
            localStorage.setItem("originComment", "");
            localStorage.setItem("smiley", 0);
            localStorage.setItem("reglement", []);
            localStorage.setItem("blockingError", false);
            localStorage.setItem("originComment", "");
            localStorage.setItem("service", []);
            localStorage.setItem("carrier", []);
        },

        clearCartItems(state, action) {
            state.cartItems = [];
            // - Be sure to update all vars in localStorage -
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        getTotals(state, action) {
            const { total, quantity, totalCheckout } = state.cartItems.reduce(
                (cartTotal, item) => {
                    const { puttc, remise1montant, quantity } = item;

                    const puttcnet = remise1montant ? puttc * (1 - remise1montant / 100) : puttc;
                    const itemTotal = puttcnet * quantity;
                    let itemTotalCheckout = 0;
                    item.puttcnet = 0;
                    if (item.grouperepartition && item.grouperepartition === "Commande") {
                        itemTotalCheckout = puttcnet * quantity;
                        item.puttcnet = puttcnet;
                    }
                    cartTotal.totalCheckout += itemTotalCheckout;
                    cartTotal.total += itemTotal;
                    cartTotal.quantity += quantity;

                    return cartTotal;
                },
                {
                    totalCheckout: 0,
                    total: 0,
                    quantity: 0,
                }
            );

            state.cartTotalAmountCheckout = totalCheckout;
            state.cartTotalAmount = total;
            state.cartTotalQuantity = quantity;
        },

        setShippingAddress(state, action) {
            state.shippingAddress = action.payload;
            localStorage.setItem("shippingAddress", action.payload);
        },

        clearShippingAddress(state, action) {
            state.shippingAddress = "";
            localStorage.setItem("shippingAdress", "");
            state.zoneShippingAddress = "";
            localStorage.setItem("zoneShippingAddress", "");
        },

        setZoneShippingAddress(state, action) {
            state.zoneShippingAddress = action.payload;
            localStorage.setItem("zoneShippingAddress", action.payload);
        },

        setSiteSource(state, action) {
            state.siteSource = action.payload;
            localStorage.setItem("siteSource", action.payload);
        },

        clearSiteSource(state, action) {
            state.siteSource = "";
            localStorage.setItem("siteSource", "");
        },

        setEmail5(state, action) {
            state.email5 = action.payload;
            localStorage.setItem("email5", action.payload);
        },

        clearEmail5(state, action) {
            state.email5 = "";
            localStorage.setItem("email5", "");
        },

        setEmailInvoice(state, action) {
            state.emailInvoice = action.payload;
            localStorage.setItem("emailInvoice", action.payload);
        },

        clearEmailInvoice(state, action) {
            state.emailInvoice = "";
            localStorage.setItem("emailInvoice", "");
        },

        setCountry(state, action) {
            state.country = action.payload;
            localStorage.setItem("country", action.payload);
        },

        clearCountry(state, action) {
            state.country = "";
            /* localStorage.removeItem("shippingAdress"); */
            localStorage.setItem("country", "");
        },

        setClient(state, action) {
            state.client = action.payload;
            localStorage.setItem("client", JSON.stringify(action.payload));
        },

        clearClient(state, action) {
            state.client = "";
            localStorage.setItem("client", []);
        },

        setSelectedDeliveryDate(state, action) {
            state.selectedDeliveryDate = action.payload;
            localStorage.setItem("selectedDeliveryDate", JSON.stringify(action.payload));
        },

        clearSelectedDeliveryDate(state, action) {
            state.selectedDeliveryDate = "";
            localStorage.setItem("selectedDeliveryDate", "");
        },

        setWsuid(state, action) {
            state.wsuid = action.payload;
            localStorage.setItem("wsuid", JSON.stringify(action.payload));
        },

        clearWsuid(state, action) {
            state.wsuid = "";
            localStorage.setItem("wsuid", "");
        },

        setReglement(state, action) {
            state.reglement = action.payload;
            localStorage.setItem("reglement", JSON.stringify(action.payload));
        },

        clearReglement(state, action) {
            state.reglement = "";
            localStorage.setItem("reglement", []);
        },

        addListInfos(state, action) {
            state.listInfos = state.listInfos.concat(action.payload);
            localStorage.setItem("listInfos", JSON.stringify(state.listInfos));
        },

        clearListInfos(state, action) {
            state.listInfos = [];
            localStorage.setItem("listInfos", []);
        },

        setIsRecoveryOrder(state, action) {
            state.isRecoveryOrder = action.payload;
            localStorage.setItem("isRecoveryOrder", JSON.stringify(state.isRecoveryOrder));
        },

        setIsLoadingInfosClient(state, action) {
            state.isLoadingInfosClient = action.payload;
            localStorage.setItem("isLoadingInfosClient", JSON.stringify(state.isLoadingInfosClient));
        },

        setIsLoadingQuantity(state, action) {
            state.isLoadingQuantity = action.payload;
            localStorage.setItem("isLoadingQuantity", JSON.stringify(state.isLoadingQuantity));
        },

        setIsDisplayInfos(state, action) {
            state.isDisplayInfos = action.payload;
            localStorage.setItem("isDisplayInfos", JSON.stringify(state.isDisplayInfos));
        },

        setIsDisplaySubstitute(state, action) {
            state.isDisplaySubstitute = action.payload;
            localStorage.setItem("isDisplaySubstitute", JSON.stringify(state.isDisplaySubstitute));
        },

        setIsDisplayOriginOrder(state, action) {
            state.isDisplayOriginOrder = action.payload;
            localStorage.setItem("isDisplayOriginOrder", JSON.stringify(state.isDisplayOriginOrder));
        },

        setIsOrderWeb(state, action) {
            state.isOrderWeb = action.payload;
            localStorage.setItem("isOrderWeb", JSON.stringify(state.isOrderWeb));
        },

        setFromQuote(state, action) {
            state.fromQuote = action.payload;
            localStorage.setItem("fromQuote", JSON.stringify(state.fromQuote));
        },

        setFromOffer(state, action) {
            state.fromOffer = action.payload;
            localStorage.setItem("fromOffer", JSON.stringify(state.fromOffer));
        },

        setIsOrderImport(state, action) {
            state.isOrderImport = action.payload;
            localStorage.setItem("isOrderImport", JSON.stringify(state.isOrderImport));
        },

        setBlockingError(state, action) {
            state.blockingError = action.payload;
            localStorage.setItem("blockingError", JSON.stringify(state.blockingError));
        },

        setSmiley(state, action) {
            state.smiley = action.payload;
            localStorage.setItem("smiley", JSON.stringify(state.smiley));
        },

        setOrderOriginId(state, action) {
            state.orderOriginId = action.payload;
            localStorage.setItem("orderOriginId", JSON.stringify(state.orderOriginId));
        },

        updateProduct(state, action) {
            const itemExist = state.cartItems.find((item) => item.indexInCart === action.payload.product.indexInCart);

            const type = action.payload.type;
            // Update product's property typedocument
            itemExist[type] = action.payload.product[type];

            // Update product's property grouperepartition
            itemExist.grouperepartition = action.payload.product.grouperepartition;

            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },

        setOriginComment(state, action) {
            state.originComment = action.payload;
            localStorage.setItem("originComment", JSON.stringify(action.payload));
        },

        clearOriginComment(state, action) {
            state.originComment = "";
            localStorage.setItem("originComment", "");
        },

        setOrderFrom(state, action) {
            state.orderFrom = action.payload;
            localStorage.setItem("orderFrom", JSON.stringify(action.payload));
        },

        clearOrderFrom(state, action) {
            state.orderFrom = "";
            localStorage.setItem("orderFrom", "");
        },

        setOrderX3Type(state, action) {
            state.orderX3Type = action.payload;
            localStorage.setItem("orderX3Type", JSON.stringify(action.payload));
        },

        clearOrderX3Type(state, action) {
            state.orderX3Type = [];
            localStorage.setItem("orderX3Type", []);
        },

        setOrderWebId(state, action) {
            state.orderWebId = action.payload;
            localStorage.setItem("orderWebId", JSON.stringify(action.payload));
        },

        setCarrier(state, action) {
            state.carrier = action.payload;
            localStorage.setItem("carrier", JSON.stringify(action.payload));
        },

        clearCarrier(state, action) {
            state.carrier = "";
            // localStorage.setItem("carrier", { carrierCode: "" });
            localStorage.setItem("carrier", []);
        },

        setService(state, action) {
            state.service = action.payload;
            localStorage.setItem("service", JSON.stringify(action.payload));
        },

        clearService(state, action) {
            state.service = "";
            localStorage.setItem("service", []);
        },

        clearOrderWebId(state, action) {
            state.orderWebId = "";
            localStorage.setItem("orderWebId", "");
        },

        setOrderSaisieId(state, action) {
            state.orderSaisieId = action.payload;
            localStorage.setItem("orderSaisieId", JSON.stringify(action.payload));
        },

        clearOrderSaisieId(state, action) {
            state.orderSaisieId = "";
            localStorage.setItem("orderSaisieId", "");
        },

        setClientFacturation(state, action) {
            state.clientFacturation = action.payload;
            localStorage.setItem("clientFacturation", JSON.stringify(action.payload));
        },

        clearClientFacturation(state, action) {
            state.clientFacturation = "";
            localStorage.setItem("clientFacturation", "");
        },

        getErrorValue(state, action) {
            return state.blockingError;
        },
    },

    extraReducers: (builder) => {
        builder.addMatcher(middleware.endpoints.postGetRepartition.matchFulfilled, (state, { payload }) => {
            if (payload && payload?.articles?.article) {
                /* - clean array typePossible for each item - */
                for (let i = 0; i < state.cartItems.length; i += 1) {
                    state.cartItems[i].typePossible = [];
                    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
                }

                const arrayRepartition = payload.articles.article;

                arrayRepartition.map((item) => {
                    const itemExist = state.cartItems.find((product) => product.reference === item.code && product.indexInCart === item.index);

                    if (itemExist) {
                        if (itemExist.typePossible == null) {
                            itemExist.typePossible = [];
                        }
                        itemExist.typePossible.push({
                            valeurdefault: item.valeurdefaut,
                            grouperepartition: item.grouperepartition,
                            typedocument: item.typedocument,
                        });

                        if (item.valeurdefaut === true) {
                            itemExist.type = {
                                valeurdefault: item.valeurdefaut,
                                grouperepartition: item.grouperepartition,
                                typedocument: item.typedocument,
                            };
                            itemExist.grouperepartition = item.grouperepartition;
                            itemExist.typedocument = item.typedocument;
                        }
                    }

                    if (state.cartDocTypes.find((elem) => elem.libelle === item.grouperepartition) === undefined) {
                        state.cartDocTypes.push({
                            libelle: item.grouperepartition,
                            code: item.typedocument,
                        });
                    }

                    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
                    return state;
                });

                // Add hard coding values in item.typePossible array
                // state.cartItems.forEach((item) => {
                //   item.typePossible.push(
                //     {
                //       valeurdefault: false,
                //       grouperepartition: "Forcer DCICP",
                //       typedocument: "DCICP",
                //     }
                //   );

                //   item.typePossible.push(
                //     {
                //       valeurdefault: false,
                //       grouperepartition: "Forcer DCPIL",
                //       typedocument: "DCPIL",
                //     }
                //   );
                // })

                localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
                // return state;

                // - Add hard values in array cartDocTypes
                // state.cartDocTypes.push({ libelle: "Forcer DCICP", code: "DCICP" });
                // state.cartDocTypes.push({ libelle: "Forcer DCPIL", code: "DCPIL" });
                // - update cartDocTypes in global state
                localStorage.setItem("cartDocTypes", JSON.stringify(state.cartDocTypes));

                /* ------------------------------------------------------------
                 * Handling case if lireRepartionArticles api service doesn't
                 * return a default type for an item:
                 * For each item if it hasn't a default type possible, so set
                 * Commande as default type possible
                 * --------------------------------------------------------- */
                for (let i = 0; i < state.cartItems.length; i += 1) {
                    if (!state.cartItems[i].type || state.fromQuote || state.fromOffer) {
                        state.cartItems[i].type = {
                            valeurdefault: true,
                            grouperepartition: "Commande",
                            typedocument: "SON",
                        };
                        state.cartItems[i].grouperepartition = "Commande";
                        state.cartItems[i].typedocument = "SON";
                    }
                    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
                }
            }
        });

        builder.addMatcher(middleware.endpoints.postGetTarifClient.matchFulfilled, (state, { payload }) => {
            if (payload?.articles) {
                payload.articles.map((item) => {
                    state.cartItems.forEach((product) => {
                        if (product.reference === item.code) {
                            product.puttcnet = 0;
                            product.taxetaux = "-";

                            // Si c'est une cmd Web et que la remise et le prixbrut ont été renseignés est apparaissent déjà en BDD dans product_web
                            // alors on garde ceux-là. Sinon on prend ceux de getTarifClient
                            if (state.isOrderWeb === false && state.isOrderImport === false && state.fromOffer === false) {
                                product.puttc = item.prix;
                                product.remise1montant = item.remise;
                            } else if (state.isOrderWeb) {
                                if (product.prixbrut !== null && product.prixbrut !== undefined) {
                                    // - Si la commande web indique le prixbrut de l'article alors puttc prend prixbrut
                                    product.puttc = product.prixbrut;
                                } else {
                                    // - Sinon puttc prend prix retourné par getTarifClient
                                    product.puttc = item.prix;
                                }

                                if (product.remise1montant !== null && product.remise1montant !== undefined) {
                                    // la remise est celle en BDD récupéré lors de la reprise de la commande
                                } else {
                                    product.remise1montant = item.remise;
                                }
                            } else if (state.isOrderImport) {
                                // Si c'est une cmd importée depuis excel et que la remise et le puttc ont été renseignés alors on garde ceux-là.
                                // Sinon on prend ceux de getTarifClient
                                if (product.puttc !== null && product.puttc !== undefined) {
                                    // le puttc est celui du json généré via excel
                                } else {
                                    // - Sinon puttc prend prix retourné par getTarifClient
                                    product.puttc = item.prix;
                                }

                                if (product.remise1montant !== null && product.remise1montant !== undefined) {
                                    // la remise est celle du json généré via excel
                                } else {
                                    product.remise1montant = item.remise;
                                }
                            } else if (state.fromOffer) {
                                product.puttc = item.prix;
                                if (product.remise1montant !== null && product.remise1montant !== undefined) {
                                    // la remise est celle en BDD récupéré lors de la reprise de la commande
                                } else {
                                    product.remise1montant = item.remise;
                                }
                            }

                            product.prixbrutbase = item.prix;

                            product.remise1montantbase = item.remise;
                            product.remise2montant = 0;
                            product.justificatif = "-";

                            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
                        }
                    });

                    return state;
                });
            }
        });
    },
});

export default cartSlice.reducer;

export const cartSelector = (state) => state.cart;

export const {
    addToCart,
    removeFromCart,
    decreaseQuantity,
    increaseQuantity,
    clearCart,
    clearCartItems,
    getTotals,
    updateQuantity,
    openCartDrawer,
    closeCartDrawer,
    checkStock,
    setShippingAddress,
    setSiteSource,
    clearSiteSource,
    setZoneShippingAddress,
    clearShippingAddress,
    setEmail5,
    clearEmail5,
    setEmailInvoice,
    clearEmailInvoice,
    setCountry,
    clearCountry,
    setClient,
    clearClient,
    setSelectedDeliveryDate,
    clearSelectedDeliveryDate,
    setWsuid,
    clearWsuid,
    setReglement,
    clearReglement,
    addListInfos,
    clearListInfos,
    setIsRecoveryOrder,
    setIsLoadingInfosClient,
    setIsLoadingQuantity,
    setIsDisplayInfos,
    setIsDisplaySubstitute,
    setIsDisplayOriginOrder,
    setIsOrderWeb,
    setFromQuote,
    setFromOffer,
    setIsOrderImport,
    setBlockingError,
    setSmiley,
    setOrderOriginId,
    updateComment,
    updatePuuid,
    updateProduct,
    restoreCart,
    setOriginComment,
    clearOriginComment,
    setOrderFrom,
    clearOrderFrom,
    setOrderX3Type,
    clearOrderX3Type,
    setOrderWebId,
    clearOrderWebId,
    setOrderSaisieId,
    clearOrderSaisieId,
    setClientFacturation,
    clearClientFacturation,
    getErrorValue,
    setCarrier,
    clearCarrier,
    setService,
    clearService,
} = cartSlice.actions;
