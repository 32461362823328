import { useState, useEffect } from "react";

import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid } from "@mui/material";
import { orange, blue } from "@mui/material/colors";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { cartSelector, clearCart, addListInfos, setIsDisplayInfos, updatePuuid, setWsuid, getTotals } from "./cartSlice";
import { usePostRefreshTokenMutation } from "../../app/services/keycloakApi";
import {
    usePostOrderMutation,
    usePostReglementMutation,
    useUpdateOrderMutation,
    useUpdateProductStatusMutation,
    useUpdateOrderStatusMutation,
    useUpdateProductWebStatusMutation,
    useUpdateOrderWebStatusMutation,
    useUpdateOrderWebMutation,
    usePostGetLastOrderIdByUserMutation,
    usePostHandlingOrderDocumentsMutation,
} from "../../app/services/lumenApi";
import {
    usePutTraiterLogMutation,
    usePostGetRepartitionMutation,
    usePostGetTarifClientMutation,
    usePostGetX3ExistOrderMutation,
} from "../../app/services/middleware";
import store from "../../app/store";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import CartInput from "../../common/components/cartInput/CartInput";
import CartSelectType from "../../common/components/cartSelectType/CartSelectType";
import Spinner from "../../common/components/spinner/Spinner";
import { BASE_BDD_MIDDLEWARE_API, ENV } from "../../common/utils/apiConstants";
import {
    importAllImages,
    createX3Credits,
    sortOrderAndCredit,
    createX3Commande,
    escapeStringField,
    strDate,
    findImageItem,
    createX3CommandeFromQuote,
    createX3CommandeFromOffer,
    getFormDataToRefreshKeycloakToken,
} from "../../common/utils/helperFunctions";
import styles from "../../styles/checkoutTable.module.css";
import { authSelector, resetUser } from "../auth/authSlice";
import { clearLocation, updateLastLocation } from "../location/locationSlice";
/* ------------------------------------------------------------------------- */

const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));
const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));

const orderX3TypeSON = "SON";
const orderX3TypeDCICP = "DCICP";
const orderX3TypeDCIPL = "DCPIL";

const CheckoutTable = () => {
    // console.log("[INFO] - component CheckouTable is rendered");
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const cart = useSelector(cartSelector, shallowEqual);
    const { client, cartItems } = useSelector(cartSelector);
    const auth = useSelector(authSelector);

    const [isErrorHandling, setIsErrorHandling] = useState(false);
    const [typeDocSONHandled, setTypeDocSONHandled] = useState(false);
    const [typeDocDCPILHandled, setTypeDocDCPILHandled] = useState(false);
    const [typeDocDCICPHandled, setTypeDocDCICPHandled] = useState(false);

    const [orderId, setOrderId] = useState("");

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
        criticite: 3,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const replaceInOriginComment = escapeStringField(cart.originComment);

    const listArticles = [];

    cart.cartItems.forEach((elem) => {
        listArticles.push({
            index: parseInt(elem.indexInCart + 1, 10),
            codearticle: elem.reference,
        });
    });

    const bodyParamRequest = encodeURIComponent(
        JSON.stringify({
            base: BASE_BDD_MIDDLEWARE_API,
            client: cart.client.clientCode,
            articles: listArticles,
        })
    );

    const [triggerGetTarifs, resTriggerGetTarifs] = usePostGetTarifClientMutation();

    const headOrder = {
        clientCode: cart.client.clientCode,
        shippingAddress: cart.shippingAddress,
        originComment: replaceInOriginComment,
        initials: (auth.user.firstname.substring(0, 1) + auth.user.lastname.substring(0, 1)).toUpperCase(),
        devise: cart.client.devise,
        dateDepart: cart.selectedDeliveryDate,
        carrier: cart.carrier.carrierCode,
        service: cart.service.serviceCode,
    };

    const headDcicp = {
        clientCode: cart.client.clientCode,
        type: orderX3TypeDCICP,
        shippingAddress: cart.shippingAddress,
        originComment: replaceInOriginComment,
        initials: (auth.user.firstname.substring(0, 1) + auth.user.lastname.substring(0, 1)).toUpperCase(),
        devise: cart.client.devise,
        dateDepart: cart.selectedDeliveryDate,
        carrier: cart.carrier.carrierCode,
        service: cart.service.serviceCode,
    };

    const headDcpil = {
        clientCode: cart.client.clientCode,
        type: orderX3TypeDCIPL,
        shippingAddress: cart.shippingAddress,
        originComment: replaceInOriginComment,
        initials: (auth.user.firstname.substring(0, 1) + auth.user.lastname.substring(0, 1)).toUpperCase(),
        devise: cart.client.devise,
        dateDepart: cart.selectedDeliveryDate,
        carrier: cart.carrier.carrierCode,
        service: cart.service.serviceCode,
    };

    const { itemsTypDocSON, itemsTypDocDCICP, itemsTypDocDCPIL } = sortOrderAndCredit(cart.cartItems);

    // - Rule updated 2023-09-13:
    // Manual orders can't be prepaid, so prepaid = 1 ( 1 == false)
    // Web orders and web LDA orders can be prepaid
    // let prepaid = cart.reglement !== "" ? 2 : 1; // - Dans le model d'importation 2 signifie oui

    let prepaidValue = 1;
    if (cart.isOrderWeb && (cart.reglement !== "" || (cart.smiley !== "" && cart.smiley !== 0))) {
        prepaidValue = 2;
    }

    const infosReglement = {
        smileyAmount: cart.smiley,
        wsuid: undefined,
        prepaid: prepaidValue,
    };

    // - definitions pour service lireRepartition ---------------------------- */
    const [triggerRepartition] = usePostGetRepartitionMutation();

    const bodyParam = encodeURIComponent(
        JSON.stringify({
            nomBaseSAGE: BASE_BDD_MIDDLEWARE_API,
            codeclient: client.clientCode,
            articles: {
                article: cartItems.map((item) => ({
                    index: parseInt(item.indexInCart, 10),
                    code: item.reference,
                    quantite: item.quantity,
                })),
            },
        })
    );

    const [triggerPostGetX3Order] = usePostGetX3ExistOrderMutation();

    const [isSubmitting, setIsSubmitting] = useState(false); // lié au traitement suite au bouton générer la commande et à l'affichage du spinner
    const [isLireRepDone, setIsLireRepDone] = useState(false); // lié à l'appel du service usePostGetTarifClientMutation

    const [triggerGetLastOrderId] = usePostGetLastOrderIdByUserMutation();
    const [triggerSaveCart] = usePostOrderMutation(); // save order in BDD
    const [triggerSaveReglement] = usePostReglementMutation(); // save reglement in BDD

    const [triggerUpdateProductStatus] = useUpdateProductStatusMutation();
    const [triggerUpdateOrderStatus, resUpdateOrderStatus] = useUpdateOrderStatusMutation();

    const [triggerUpdateProductWebStatus] = useUpdateProductWebStatusMutation();
    const [triggerUpdateOrderWebStatus, resUpdateOrderWebStatus] = useUpdateOrderWebStatusMutation();

    const [triggerUpdateCart] = useUpdateOrderMutation();
    const [triggerUpdateCartWeb] = useUpdateOrderWebMutation();

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();

    const [triggerHandlingOrder] = usePostHandlingOrderDocumentsMutation();

    const SubTotal = document.getElementsByClassName("SubTotal");

    /* ----------------------------------------------------------------------- */
    const noticeInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 200,
        className: "CheckoutTable.js::creerCommande()",
        criticite: 1,
    };

    /* ----------------------------------------------------------------------- */

    /* ------------------------------------------------------------------------
     *  - useEffect definitions
     *  -------------------------------------------------------------------- */
    useEffect(() => {
        for (let i = 0; i < SubTotal.length; i += 1) {
            if (SubTotal[i].textContent.indexOf("-") !== -1) {
                document.getElementById("AlertValueNegative").style.display = "block";
                break;
            } else {
                document.getElementById("AlertValueNegative").style.display = "none";
            }
        }
    });

    useEffect(() => {
        if (SubTotal.length === 0) {
            if (document.getElementById("AlertValueNegative")) {
                document.getElementById("AlertValueNegative").style.display = "none";
            }
        }
    });

    // - Call getTotals() when last product has obtained its property
    // defined by extraReducer (line 552 in cartSlice.js)
    useEffect(() => {
        if (cartItems[cartItems.length - 1].hasOwnProperty("type")) {
            dispatch(getTotals());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems[cartItems.length - 1].hasOwnProperty("type")]);

    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);
    /* --------------------------------------------------------------------- */

    useEffect(() => {
        const getTarifsFct = async () => {
            await triggerGetTarifs(bodyParamRequest)
                .unwrap()
                .then((response) => {
                    if (response.success === false) {
                        const msg = "Une erreur s'est produite lors de la récupération des tarifs. Merci de cliquer à nouveau sur 'Etape suivante'";
                        toast.error(
                            <>
                                Une erreur s&#39;est produite lors de la récupération des tarifs
                                <br />
                                Merci de cliquer à nouveau sur le bouton &#39;ETAPE SUIVANTE&#39;.
                            </>
                        );

                        // - send log warning
                        errorInfos.message = msg;
                        errorInfos.userEmail = auth.user.email;
                        errorInfos.code = 400;
                        errorInfos.criticite = 3;
                        const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                        triggerLog(bodyParam);
                        dispatch(updateLastLocation(location.pathname));
                        navigate("/order");
                    }
                });
        };
        getTarifsFct().catch((e) => {
            const msg = "Une erreur s'est produite lors de la récupération des tarifs. Merci de cliquer à nouveau sur le bonton 'Etape suivante'";
            toast.error(
                <>
                    Une erreur s&#39;est produite lors de la récupération des tarifs.
                    <br />
                    Merci de cliquer à nouveau sur le bouton &#39;ETAPE SUIVANTE&#39;.
                </>
            );

            // - send log warning
            errorInfos.message = msg;
            errorInfos.userEmail = auth.user.email;
            errorInfos.code = 400;
            errorInfos.criticite = 3;

            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
            dispatch(updateLastLocation(location.pathname));
            navigate("/order");
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* ------------------------------------------------------------------------
     * useEffect() on cart.cartItems.length
     * triggered by dispatch(removeFromCart(product)); when
     * x3Document linked to item(s) is created successfully (line 354)
     *
     * WARNING: NOT USE ANYMORE
     * --------------------------------------------------------------------- */
    useEffect(() => {
        if (cart.cartItems.length === 0) {
            const updateOrderFct = async () => {
                if (cart.isOrderWeb) {
                    if (orderId !== "") {
                        await triggerUpdateOrderWebStatus({
                            id: orderId,
                            status: 1,
                        });
                    } // if
                } else if (orderId !== "") {
                    await triggerUpdateOrderStatus({
                        id: orderId,
                        status: 1,
                    });
                } // else
            }; // updateOrderFunction

            // - call updateOrderFct()
            updateOrderFct().catch((e) => {
                toast.error(<>Une erreur s&#39;est produite lors de la mise à jour de la commande.</>);
            });
        } // if

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.cartItems]);
    /* ----------------------------------------------------------------------- */

    useEffect(() => {
        if (resTriggerGetTarifs.status === "fulfilled" && cart.cartItems.length !== 0) {
            const repartitionFct = async () => {
                await triggerRepartition(bodyParam)
                    .unwrap()
                    .then((response) => {
                        if (response.success === true) {
                            setIsLireRepDone(true);

                            // Add a puuid for each items if they haven't a puuid
                            for (let i = 0; i < cart.cartItems.length; i += 1) {
                                if (!cart.cartItems[i].hasOwnProperty("puuid") || cart.cartItems[i].puuid === null) {
                                    const updatedProduct = {
                                        ...cart.cartItems[i],
                                        puuid: uuidv4(),
                                    };
                                    dispatch(updatePuuid(updatedProduct));
                                }
                            }
                        } else {
                            setIsLireRepDone(false);
                            setIsErrorHandling(true);
                            toast.error("Une erreur s'est produite lors du chargement d'informations. Merci de contacter l'assistance des ventes.");

                            errorInfos.className = "CheckoutTable.js::postGetRepartition()";
                            errorInfos.message = "Erreur lors de l'appel du service postGetRepartition - response.data.success = false";
                            errorInfos.criticite = 3;
                            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                            triggerLog(bodyParam);
                        }
                    });
            };

            // - call repartitionFct()
            repartitionFct().catch((e) => {
                setIsLireRepDone(false);
                errorInfos.className = "CheckoutTable.js::postGetRepartition()";
                errorInfos.message = "Erreur lors de l'appel du service postGetRepartition - catch exception";
                errorInfos.criticite = 3;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerGetTarifs]);

    /* ------------------------------------------------------------------------
     * Handling result of triggerUpdateOrderWebStatus in a useEffect block.
     * status has to be equal to fulfilled in order to be sure the call is
     * finished and request executed. Permit to simulate sync handling
     * --------------------------------------------------------------------- */
    useEffect(() => {
        if (resUpdateOrderWebStatus.status === "fulfilled" && resUpdateOrderWebStatus.data.success === true) {
            noticeInfos.className = "CheckoutTable.js::creerCommande()";
            noticeInfos.criticite = 1;
            noticeInfos.message = `Tous les documents X3 liés à la commande Web numéro ${resUpdateOrderWebStatus.data.order_web_id} ont été ajoutés en file d'attente avec succès - statut mis à 1.`;
            const bodyParam = encodeURIComponent(JSON.stringify(noticeInfos));
            triggerLog(bodyParam);
            dispatch(clearCart());
            dispatch(clearLocation());
            if (cart.siteSource === "LDAL") {
                navigate("/webcarts/LDA");
            } else if (cart.isOrderWeb) {
                navigate("/webcarts");
            } else {
                navigate("/history");
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resUpdateOrderWebStatus]);

    /* ------------------------------------------------------------------------
     * Handling result of triggerUpdateOrderStatus in a useEffect block. status
     * has to be equal to fulfilled in order to be sure the call is finished and
     * request executed. Permit to simulate sync handling
     * --------------------------------------------------------------------- */
    useEffect(() => {
        if (resUpdateOrderStatus.status === "fulfilled" && resUpdateOrderStatus.data?.success === 1) {
            noticeInfos.className = "CheckoutTable.js::creerCommande()";
            noticeInfos.criticite = 1;
            noticeInfos.message = `Tous les documents X3 liés à la commande numéro ${resUpdateOrderStatus.originalArgs.id} ont été ajoutés en file d'attente avec succès - statut mis à 1.`;
            const bodyParam = encodeURIComponent(JSON.stringify(noticeInfos));
            triggerLog(bodyParam);
            dispatch(clearCart());
            dispatch(clearLocation());
            navigate("/history");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resUpdateOrderStatus]);

    /* ------------------------------------------------------------------------
     * Handling all var typDocHandled are true
     * Maj status passe à 1 pour indiquer que tous les documents liés à la
     * commande ont été ajoutés dans la file d'attente ImportX3 queue laravel
     * --------------------------------------------------------------------- */
    useEffect(() => {
        if (typeDocDCICPHandled && typeDocDCPILHandled && typeDocSONHandled) {
            const updateOrderFct = async () => {
                if (cart.isOrderWeb) {
                    if (orderId !== "") {
                        await triggerUpdateOrderWebStatus({
                            id: orderId,
                            status: 1,
                        });
                    } // if
                } else if (orderId !== "") {
                    await triggerUpdateOrderStatus({
                        id: orderId,
                        status: 1,
                    });
                } // else
            }; // updateOrderFunction

            // - call updateOrderFct()
            updateOrderFct().catch((e) => {
                toast.error(<>Une erreur s&#39;est produite lors de la mise à jour de la commande.</>);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeDocDCICPHandled, typeDocDCPILHandled, typeDocSONHandled]);
    /* ----------------------------------------------------------------------- */

    const ArrayNumberNegativeValue = [];

    /* ------------------------------------------------------------------------
     * - Handling onClick button Generer les commandes
     * Row handling: for each row if X3 Document is created so the line item
     * in cart is remove
     * --------------------------------------------------------------------- */

    /* - Traitement principal ------------------------------------------------- */
    const handleSubmitCart = async () => {
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);

        for (let i = 0; i < SubTotal.length; i += 1) {
            if (SubTotal[i].textContent.indexOf("-") !== -1) {
                ArrayNumberNegativeValue.push(-1);
            } else {
                ArrayNumberNegativeValue.push(1);
            }
        }

        if (ArrayNumberNegativeValue.indexOf(-1) !== -1) {
            toast.error("Le Total de votre commande est inférieur à 0. Merci de vérifier les remises.");
        } else {
            setIsSubmitting(true);

            try {
                let isDCICPX3Exist = false;
                let isDCPILX3Exist = false;
                let isSONX3Exist = false;

                /* --------------------------------------------------------------------
                 * - 1) Enregistrement de la commande en BDD avec status = 0
                 * ----------------------------------------------------------------- */
                let currentStore = store.getState();
                let errorOccured = currentStore.cart.blockingError;
                let orderIdInProcess;

                if (errorOccured) {
                    setIsSubmitting(false);
                } else {
                    // - Save order and products in BDD with status = 0 -
                    const cartExists = cart.cartItems.find((product) => product.order_id);

                    const cartToStore = {
                        products: cart.cartItems,
                        status: 0,
                        clientCode: cart.client.clientCode,
                        client: cart.client.client,
                        codepostal: cart.client.codepostal,
                        shippingAddress: cart.shippingAddress,
                        datelivraison: cart.selectedDeliveryDate,
                        origine: cart.originComment,
                        devise: cart.client.devise,
                        emailTracking: cart.emailInvoice,
                        fromQuote: cart.fromQuote,
                        fromOffer: cart.fromOffer,
                    };

                    if (!cartExists) {
                        if (cart.isOrderWeb) {
                            // - CAS panier inexistant et commande de type web == cas IMPOSSIBLE
                            // await triggerSaveCartWeb(cartToStore);
                        } else {
                            // - CAS panier inexistant et commande de type saisie
                            await triggerSaveCart({
                                uuid: uuidv4(),
                                ...cartToStore,
                                carrierCode: cart.carrier.carrierCode,
                                serviceCode: cart.service.serviceCode,
                            });
                            await triggerSaveReglement(cart.reglement);
                        }
                    } else {
                        if (cart.isOrderWeb) {
                            if (cart.siteSource === "LDAL") {
                                // - CAS panier existant et commande de type web LDAL
                                await triggerUpdateCartWeb({
                                    id: cart.orderWebId,
                                    ...cartToStore,
                                });
                            } else {
                                // - CAS panier existant et commande de type web CRAP
                                await triggerUpdateCartWeb({
                                    id: cart.orderWebId,
                                    ...cartToStore,
                                    carrierCode: cart.carrier.carrierCode,
                                    serviceCode: cart.service.serviceCode,
                                });
                            }
                        } else {
                            // - CAS panier existant, saisie manuelle: reprise d'un panier
                            // enregistré
                            // - CAS panier existant, saisie manuelle: reprise d'un panier
                            // dont la création d'un ou de plusieurs documentX3 a échoué
                            await triggerUpdateCart({
                                id: cartExists.order_id,
                                ...cartToStore,
                                carrierCode: cart.carrier.carrierCode,
                                serviceCode: cart.service.serviceCode,
                            });
                        }
                    }

                    // - récuperation de l'orderIdInProcess une fois l'enregistrement en BDD réalisé
                    if (cartExists) {
                        orderIdInProcess = cart.isOrderWeb ? cart.orderWebId : cartExists.order_id;
                        setOrderId(cartExists.order_id);
                    } else {
                        const requestDate = new Date();
                        // - get lastOrderId for current user -
                        await triggerGetLastOrderId({ user_id: auth.user.id })
                            .unwrap()
                            .then((response) => {
                                if (response.success) {
                                    const responseDate = new Date(response.dateheure);

                                    if (requestDate.toLocaleString() > responseDate.toLocaleString()) {
                                        toast.error("Une erreur s'est produite lors du traitement. Merci de recharger la page.");
                                    } else {
                                        orderIdInProcess = response.lastId;
                                        setOrderId(orderIdInProcess);
                                    }
                                } else {
                                    toast.error("Une erreur s'est produite lors du traitement. Merci de recharger la page.");
                                }
                            });
                    }
                } // else

                /* --------------------------------------------------------------------
                 * - 2) Vérification existance de la commande dans X3, liée au panier
                 * ----------------------------------------------------------------- */
                if (itemsTypDocSON.length !== 0 && orderIdInProcess !== undefined) {
                    // - Check doublon order type SON in X3 SORDERS bdd
                    let composedwsuid = `${orderX3TypeSON}-`;
                    composedwsuid += cart.isOrderWeb ? `WEB-${orderIdInProcess}` : orderIdInProcess;

                    dispatch(setWsuid(composedwsuid));

                    const bodyParamGetX3Order = encodeURIComponent(
                        JSON.stringify({
                            nomBaseSAGE: BASE_BDD_MIDDLEWARE_API,
                            wsuid: composedwsuid,
                        })
                    );

                    await triggerPostGetX3Order(bodyParamGetX3Order)
                        .unwrap()
                        .then((response) => {
                            if (response.nbresultats > 0) {
                                const msg = `La commande ${response.commande.numero} (type SON) liée à ce panier existe déjà dans X3`;

                                const newInfo = [];
                                newInfo.push({
                                    uniqueId: Math.random().toString(36).substring(2, 9),
                                    type: 2,
                                    message: msg,
                                });
                                toast(
                                    <>
                                        <WarningAmberIcon />
                                        <span>{msg}</span>
                                    </>
                                );

                                errorInfos.className = "CheckoutTable.js::postGetX3OrderMutation()";
                                errorInfos.message = msg;
                                errorInfos.criticite = 2;
                                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                                triggerLog(bodyParam);

                                dispatch(addListInfos(newInfo));
                                // dispatch(setIsSONX3Created(true))
                                isSONX3Exist = true;
                                setTypeDocSONHandled(true);
                                dispatch(setIsDisplayInfos(true));
                            }
                        });
                }

                /* ----------------------------------------------------------------- */
                if (itemsTypDocDCPIL.length !== 0 && orderIdInProcess !== undefined) {
                    // - Check doublon order type DCPIL in X3 SORDERS bdd
                    let composedwsuid = `${orderX3TypeDCIPL}-`;
                    composedwsuid += cart.isOrderWeb ? `WEB-${orderIdInProcess}` : orderIdInProcess;

                    dispatch(setWsuid(composedwsuid));

                    const bodyParamGetX3Order = encodeURIComponent(
                        JSON.stringify({
                            nomBaseSAGE: BASE_BDD_MIDDLEWARE_API,
                            wsuid: composedwsuid,
                        })
                    );

                    await triggerPostGetX3Order(bodyParamGetX3Order)
                        .unwrap()
                        .then((response) => {
                            if (response.nbresultats > 0) {
                                const msg = `La commande ${response.commande.numero} (type DCPIL) liée à ce panier existe déjà dans X3`;

                                const newInfo = [];
                                newInfo.push({
                                    uniqueId: Math.random().toString(36).substring(2, 9),
                                    type: 2,
                                    message: msg,
                                });
                                toast(
                                    <>
                                        <WarningAmberIcon />
                                        <span>{msg}</span>
                                    </>
                                );

                                errorInfos.className = "CheckoutTable.js::postGetX3OrderMutation()";
                                errorInfos.message = msg;
                                errorInfos.criticite = 2;
                                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                                triggerLog(bodyParam);

                                dispatch(addListInfos(newInfo));
                                // dispatch(setIsDCPILX3Created(true));
                                isDCPILX3Exist = true;
                                setTypeDocDCPILHandled(true);
                                dispatch(setIsDisplayInfos(true));
                            }
                        });
                }

                /* ----------------------------------------------------------------- */
                if (itemsTypDocDCICP.length !== 0 && orderIdInProcess !== undefined) {
                    // - Check doublon order type DCICP in X3 SORDERS bdd
                    let composedwsuid = `${orderX3TypeDCICP}-`;
                    composedwsuid += cart.isOrderWeb ? `WEB-${orderIdInProcess}` : orderIdInProcess;

                    dispatch(setWsuid(composedwsuid));

                    const bodyParamGetX3Order = encodeURIComponent(
                        JSON.stringify({
                            nomBaseSAGE: BASE_BDD_MIDDLEWARE_API,
                            wsuid: composedwsuid,
                            articles: listArticles,
                        })
                    );

                    await triggerPostGetX3Order(bodyParamGetX3Order)
                        .unwrap()
                        .then((response) => {
                            if (response.nbresultats > 0) {
                                const msg = `La commande ${response.commande.numero} (type DCICP) liée à ce panier existe déjà dans X3`;

                                const newInfo = [];
                                newInfo.push({
                                    uniqueId: Math.random().toString(36).substring(2, 9),
                                    type: 2,
                                    message: msg,
                                });
                                toast(
                                    <>
                                        <WarningAmberIcon />
                                        <span>{msg}</span>
                                    </>
                                );

                                errorInfos.className = "CheckoutTable.js::postGetX3OrderMutation()";
                                errorInfos.message = msg;
                                errorInfos.criticite = 2;
                                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                                triggerLog(bodyParam);

                                dispatch(addListInfos(newInfo));
                                // dispatch(setIsDCICPX3Created(true));
                                isDCICPX3Exist = true;
                                setTypeDocDCICPHandled(true);
                                dispatch(setIsDisplayInfos(true));
                            }
                        });
                }
                /* ----------------------------------------------------------------- */

                /* --------------------------------------------------------------------
                 * - 3) Ajout en file d'attente importX3
                 * ----------------------------------------------------------------- */

                infosReglement.wsuid = cart.isOrderWeb ? `WEB-${orderIdInProcess}` : orderIdInProcess;

                // - Mock erreur: utilisation d'anciens modèles d'import -
                // let bodyReqSON   = createX3Commande("ZSOH3", headOrder, itemsTypDocSON, infosReglement);
                // let bodyReqDCICP = createX3Credits("ZSOHC2", headDcicp, itemsTypDocDCICP, infosReglement);
                // let bodyReqDCPIL = createX3Credits("ZSOHC2", headDcpil, itemsTypDocDCPIL, infosReglement);

                // - Nouveaux modèles à utiliser
                if (cart.fromQuote) {
                    createX3CommandeFromQuote("ZSOH7", headOrder, itemsTypDocSON, infosReglement);
                } else if (cart.fromOffer) {
                    createX3CommandeFromOffer("ZSOH8", headOrder, itemsTypDocSON, infosReglement);
                } else {
                    createX3Commande("ZSOH4", headOrder, itemsTypDocSON, infosReglement);
                }

                createX3Credits("ZSOHC3", headDcicp, itemsTypDocDCICP, infosReglement);
                createX3Credits("ZSOHC3", headDcpil, itemsTypDocDCPIL, infosReglement);

                currentStore = store.getState();
                errorOccured = currentStore.cart.blockingError;

                if (errorOccured) {
                    toast(
                        <>
                            <WarningAmberIcon className={styles.adjustPos2} />
                            <span>
                                Une erreur s&#39;est produite lors de la dernière tentative de génération.
                                <br />
                                Merci de vérifier les infos de votre commande.
                            </span>
                        </>,
                        {
                            duration: 3000,
                        }
                    );
                    setIsSubmitting(false);
                } else {
                    /* -----------------------------------------------------------------
                     * Put order DCICP, DCPIL, SON in laravel queue importX3
                     *
                     * Update product status foreach handlingOrder in success
                     * HandlingOrder add orders documents in laravel queue
                     * ImportX3
                     * call triggerHandlingOrder for Each
                     * TypeCommande (SON, DCPIL, DCICP) XDoc
                     *
                     * Régle: ordre de traitement souhaité:
                     * - DCICP
                     * - DCPIL
                     * - SON
                     * ----------------------------------------------------- */

                    // -- handling items order type DCICP --------------------
                    if (itemsTypDocDCICP.length !== 0 && isDCICPX3Exist === false && orderIdInProcess !== undefined) {
                        const tabDocuments = [];
                        const lignes = [];

                        for (const index in itemsTypDocDCICP) {
                            if (Object.hasOwn(itemsTypDocDCICP, index)) {
                                const item = itemsTypDocDCICP[index];

                                // - Fix against unitedevente undefined -
                                let valUnitVente = item.unitedevente;

                                if (valUnitVente !== undefined && valUnitVente !== null) {
                                    valUnitVente = valUnitVente.trim();
                                }

                                if (valUnitVente == null || valUnitVente === undefined || valUnitVente === "" || valUnitVente === "und") {
                                    valUnitVente = "UN";
                                    // - write supervision log
                                    noticeInfos.className = "CheckoutTable.js::creerCommande()";
                                    noticeInfos.criticite = 2;
                                    noticeInfos.message = `[- WARNING -] unitedevente undefined pour l'article ${item.reference} - réaffectation avec la valeur par defaut 'UN' `;
                                    const bodyParam = encodeURIComponent(JSON.stringify(noticeInfos));
                                    triggerLog(bodyParam);
                                }

                                const ligne = {
                                    reference: item.reference,
                                    designation1: item.designation,
                                    designation2: item.designation,
                                    lot: item.lot,
                                    unitevente: valUnitVente,
                                    quantite: item.quantity,
                                    comment: item.comment,
                                };
                                lignes.push(ligne);
                            } // if
                        } // for

                        const properties = {
                            typecommand: orderX3TypeDCICP,
                            siteSource: cart.siteSource,
                            codeclient: cart.client.clientCode,
                            currentdate: strDate(new Date()),
                            expeditiondate: cart.selectedDeliveryDate,
                            initialandorigincomment: `${(
                                auth.user.firstname.substring(0, 1) + auth.user.lastname.substring(0, 1)
                            ).toUpperCase()} ${escapeStringField(cart.originComment)}`,
                            shippingaddress: cart.shippingAddress,
                            devise: cart.client.devise,
                            importws: 2,
                            smileyamount: cart.smiley,
                            wsuid: `${orderX3TypeDCICP}-${infosReglement.wsuid}`,
                            fromQuote: cart.fromQuote,
                            fromOffer: cart.fromOffer,
                            prepaid: prepaidValue,
                            carrierCode: cart.carrier?.carrierCode !== "" ? cart.carrier.carrierCode : "",
                            serviceCode: cart.service?.serviceCode !== "" ? cart.service.serviceCode : "",
                            lignes: lignes,
                        };

                        if (cart.isOrderWeb) {
                            properties.order_web_id = orderIdInProcess;
                        } else {
                            properties.order_id = orderIdInProcess;
                        }

                        // - Ajout de la propriété user_id
                        properties.user_id = auth.user.id;

                        tabDocuments.push(properties);

                        const payload = encodeURIComponent(
                            JSON.stringify({
                                documents: tabDocuments,
                            })
                        );

                        await triggerHandlingOrder(payload)
                            .unwrap()
                            .then((response) => {
                                if (response.success === true) {
                                    toast.success("Document de commande DCICP mis en file d'attente avec succès");
                                    setTypeDocDCICPHandled(true);

                                    itemsTypDocDCICP.forEach((product) => {
                                        if (cart.isOrderWeb) {
                                            triggerUpdateProductWebStatus({
                                                puuid: product.puuid,
                                                status: 1,
                                            });
                                        } else {
                                            triggerUpdateProductStatus({
                                                puuid: product.puuid,
                                                status: 1,
                                            });
                                        }
                                    });
                                } else {
                                    toast.error("Erreur lors de la mise en file d'attente du document de commande DCICP");
                                }
                            });
                    } else {
                        setTypeDocDCICPHandled(true);
                    }

                    // -- handling items order type DCPIL -------------------------------
                    if (itemsTypDocDCPIL.length !== 0 && isDCPILX3Exist === false && orderIdInProcess !== undefined) {
                        const tabDocuments = [];
                        const lignes = [];

                        for (const index in itemsTypDocDCPIL) {
                            if (Object.hasOwn(itemsTypDocDCPIL, index)) {
                                const item = itemsTypDocDCPIL[index];

                                // - Fix against unitedevente undefined -
                                let valUnitVente = item.unitedevente;

                                if (valUnitVente !== undefined && valUnitVente !== null) {
                                    valUnitVente = valUnitVente.trim();
                                }

                                if (valUnitVente == null || valUnitVente === undefined || valUnitVente === "" || valUnitVente === "und") {
                                    valUnitVente = "UN";
                                    // - write supervision log
                                    noticeInfos.className = "CheckoutTable.js::creerCommande()";
                                    noticeInfos.criticite = 2;
                                    noticeInfos.message = `[- WARNING -] unitedevente undefined pour l'article ${item.reference} - réaffectation avec la valeur par defaut 'UN' `;
                                    const bodyParam = encodeURIComponent(JSON.stringify(noticeInfos));
                                    triggerLog(bodyParam);
                                }

                                const ligne = {
                                    reference: item.reference,
                                    designation1: item.designation,
                                    designation2: item.designation,
                                    lot: item.lot,
                                    unitevente: valUnitVente,
                                    quantite: item.quantity,
                                    comment: item.comment,
                                };
                                lignes.push(ligne);
                            }
                        }

                        const properties = {
                            typecommand: orderX3TypeDCIPL,
                            siteSource: cart.siteSource,
                            codeclient: cart.client.clientCode,
                            currentdate: strDate(new Date()),
                            expeditiondate: cart.selectedDeliveryDate,
                            initialandorigincomment: `${(
                                auth.user.firstname.substring(0, 1) + auth.user.lastname.substring(0, 1)
                            ).toUpperCase()} ${escapeStringField(cart.originComment)}`,
                            shippingaddress: cart.shippingAddress,
                            devise: cart.client.devise,
                            importws: 2,
                            smileyamount: cart.smiley,
                            wsuid: `${orderX3TypeDCIPL}-${infosReglement.wsuid}`,
                            fromQuote: cart.fromQuote,
                            fromOffer: cart.fromOffer,
                            prepaid: prepaidValue,
                            carrierCode: cart.carrier?.carrierCode !== "" ? cart.carrier.carrierCode : "",
                            serviceCode: cart.service?.serviceCode !== "" ? cart.service.serviceCode : "",
                            lignes: lignes,
                        };

                        if (cart.isOrderWeb) {
                            properties.order_web_id = orderIdInProcess;
                        } else {
                            properties.order_id = orderIdInProcess;
                        }

                        // - Ajout de la propriété user_id
                        properties.user_id = auth.user.id;

                        tabDocuments.push(properties);

                        const payload = encodeURIComponent(
                            JSON.stringify({
                                documents: tabDocuments,
                            })
                        );

                        await triggerHandlingOrder(payload)
                            .unwrap()
                            .then((response) => {
                                if (response.success === true) {
                                    toast.success("Document de commande DCPIL mis en file d'attente avec succès");
                                    setTypeDocDCPILHandled(true);

                                    itemsTypDocDCPIL.forEach((product) => {
                                        if (cart.isOrderWeb) {
                                            triggerUpdateProductWebStatus({
                                                puuid: product.puuid,
                                                status: 1,
                                            });
                                        } else {
                                            triggerUpdateProductStatus({
                                                puuid: product.puuid,
                                                status: 1,
                                            });
                                        }
                                    });
                                } else {
                                    toast.error("Erreur lors de la mise en file d'attente du document de commande DCPIL");
                                }
                            });
                    } else {
                        setTypeDocDCPILHandled(true);
                    }

                    // -- handling items order type SON ---------------------------------
                    if (itemsTypDocSON.length !== 0 && isSONX3Exist === false && orderIdInProcess !== undefined) {
                        const tabDocuments = [];
                        const lignes = [];

                        for (const index in itemsTypDocSON) {
                            if (Object.hasOwn(itemsTypDocSON, index)) {
                                const item = itemsTypDocSON[index];

                                // - Fix against unitedevente undefined -
                                let valUnitVente = item.unitedevente;

                                if (valUnitVente !== undefined && valUnitVente !== null) {
                                    valUnitVente = valUnitVente.trim();
                                }

                                if (valUnitVente == null || valUnitVente === undefined || valUnitVente === "" || valUnitVente === "und") {
                                    valUnitVente = "UN";
                                    // - write supervision log
                                    noticeInfos.className = "CheckoutTable.js::creerCommande()";
                                    noticeInfos.criticite = 2;
                                    noticeInfos.message = `[- WARNING -] unitedevente undefined pour l'article ${item.reference} - réaffectation avec la valeur par defaut 'UN' `;
                                    const bodyParam = encodeURIComponent(JSON.stringify(noticeInfos));
                                    triggerLog(bodyParam);
                                }

                                const ligne = {
                                    reference: item.reference,
                                    designation1: item.designation,
                                    designation2: item.designation,
                                    lot: item.lot,
                                    unitevente: valUnitVente,
                                    quantite: item.quantity,
                                    puttc: item.puttc,
                                    remise1: item.remise1montant,
                                    remise2: item.remise2montant,
                                    credit_amount: item.credit_amount,
                                    comment: item.comment,
                                };
                                lignes.push(ligne);
                            } // if
                        } // for

                        const properties = {
                            typecommand: orderX3TypeSON,
                            siteSource: cart.siteSource,
                            codeclient: cart.client.clientCode,
                            currentdate: strDate(new Date()),
                            expeditiondate: cart.selectedDeliveryDate,
                            initialandorigincomment: `${(
                                auth.user.firstname.substring(0, 1) + auth.user.lastname.substring(0, 1)
                            ).toUpperCase()} ${escapeStringField(cart.originComment)}`,
                            // - START Mock error -------------------------------------------
                            shippingaddress: cart.shippingAddress,
                            // shippingaddress: cart.mock_error_property,
                            // - END Mock error ---------------------------------------------
                            devise: cart.client.devise,
                            importws: 2,
                            smileyamount: cart.smiley,
                            wsuid: `${orderX3TypeSON}-${infosReglement.wsuid}`,
                            prepaid: prepaidValue,
                            carrierCode: cart.carrier?.carrierCode !== "" ? cart.carrier.carrierCode : "",
                            serviceCode: cart.service?.serviceCode !== "" ? cart.service.serviceCode : "",
                            fromQuote: cart.fromQuote,
                            fromOffer: cart.fromOffer,
                            lignes: lignes,
                        };

                        if (cart.isOrderWeb) {
                            properties.order_web_id = orderIdInProcess;
                        } else {
                            properties.order_id = orderIdInProcess;
                        }

                        // - Ajout de la propriété user_id
                        properties.user_id = auth.user.id;

                        tabDocuments.push(properties);

                        const payload = encodeURIComponent(
                            JSON.stringify({
                                documents: tabDocuments,
                            })
                        );

                        await triggerHandlingOrder(payload)
                            .unwrap()
                            .then((response) => {
                                if (response.success === true) {
                                    toast.success("Document de commande SON mis en file d'attente avec succès");
                                    setTypeDocSONHandled(true);

                                    itemsTypDocSON.forEach((product) => {
                                        if (cart.isOrderWeb) {
                                            triggerUpdateProductWebStatus({
                                                puuid: product.puuid,
                                                status: 1,
                                            });
                                        } else {
                                            triggerUpdateProductStatus({
                                                puuid: product.puuid,
                                                status: 1,
                                            });
                                        }
                                    });
                                } else {
                                    toast.error("Erreur lors de la mise en file d'attente du document de commande SON");
                                }
                            });
                    } else {
                        setTypeDocSONHandled(true);
                    }
                } // if
                /* ----------------------------------------------------------------- */
            } catch (error) {
                errorInfos.className = "CheckoutTable.js::handleOnClick()";
                errorInfos.message = "Une erreur s'est produite lors du traitement du panier.";

                toast.error(<span>{errorInfos.message}</span>);

                if (error.hasOwnProperty("error")) {
                    errorInfos.message += ` - Détails: ${error.error}`;
                }
                errorInfos.criticite = 3;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                setIsSubmitting(false);
            }
        }
    };
    /* - End handling onClick button Generer les commandes ------------------- */

    function confirmPopupGenerateCmd(ref, quantite) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <h2>Confirmer</h2>
                        <p>L&#39;article référence {ref} apparait sur plusieurs lignes de votre panier. Comfirmez-vous la saisie ?</p>
                        <button onClick={onClose}>Refuser</button>
                        <button
                            onClick={() => {
                                onClose();
                                handleSubmitCart();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    /* - pre traitement ---------------------------------------------------- */
    const checkShoppingCart = async () => {
        if (cart.siteSource !== "LDAL" && (!cart.carrier || !cart.carrier.carrierCode || (cart.carrier.carrierCode === "TNT" && !cart.service.serviceCode))) {
            setIsErrorHandling(true);
        }
        /* - Popup alerte si un article apparait dans plusieurs lignes du panier - */
        const tabRefArticle = [];

        cart.cartItems.forEach((elem) => {
            if (elem.reference in tabRefArticle) {
                tabRefArticle[elem.reference] += 1;
            } else {
                tabRefArticle[elem.reference] = 1;
            }
        });

        let ref = "";
        let refOnMultiLnes = false;
        for (const i in tabRefArticle) {
            if (tabRefArticle[i] > 1) {
                ref = i;
                refOnMultiLnes = true;
                break;
            }
        }

        if (refOnMultiLnes) {
            confirmPopupGenerateCmd(ref);
        } else {
            handleSubmitCart();
        }
    };
    if (!cart.blockingError && isSubmitting) {
        return <Spinner />;
    }

    if (!(cart.blockingError || isLireRepDone)) {
        return <Spinner />;
    }

    return (
        <Grid container direction="column" justifyContent="space-between" alignItems="center" className={styles.pdR10}>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTableHeader}>
                <Grid item xs={3}>
                    Produit
                </Grid>
                <Grid item xs={1}>
                    PU T.T.C. brut{" "}
                </Grid>
                <Grid item xs={1}>
                    Remise
                </Grid>
                <Grid item xs={1} fontWeight={600}>
                    PU T.T.C. Net
                </Grid>

                {cart.fromOffer === false && (
                    <Grid item xs={1}>
                        Unité de vente
                    </Grid>
                )}

                {/* <Grid item xs={1}>Taxe taux</Grid> */}
                {cart.fromOffer && (
                    <Grid item xs={1} className={styles.creditAmount}>
                        Montant crédit
                    </Grid>
                )}
                <Grid item xs={1} className={styles.totalQte}>
                    Qté
                </Grid>

                <Grid item xs={1}>
                    Sous-total
                </Grid>
                <Grid item xs={2}>
                    Commentaire
                </Grid>

                <Grid item xs={1}>
                    Type(s) possible(s)
                </Grid>
            </Grid>

            {cart.cartItems.map((product, index) => (
                <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-top" className={styles.cartTableRow} key={index}>
                    <Grid item xs={1}>
                        <div className={styles.blockImg}>
                            <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                        </div>
                    </Grid>
                    <Grid item xs={2} className={styles.alignL}>
                        <h4 className={styles.ref}>{product.reference}</h4>
                        <p className={styles.productDesignation}>{product.designation}</p>
                        <div className={styles.containerCatalog}>
                            {product.catalogueniv1 !== "" && product.catalogueniv1 !== null && (
                                <div className={styles.catalog}>
                                    <span>{product.catalogueniv1}</span>
                                </div>
                            )}
                            {product.catalogueniv2 !== "" && product.catalogueniv2 !== null && product.catalogueniv2 !== product.catalogueniv1 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv2}</span>
                                </div>
                            )}
                            {product.catalogueniv3 !== "" && product.catalogueniv3 !== null && product.catalogueniv3 !== product.catalogueniv2 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv3}</span>
                                </div>
                            )}
                        </div>
                        {product.puttc * (1 - product.remise1montant / 100) === 0 && (
                            <div className={styles.offreAlert}>
                                <InfoIcon sx={{ color: blue[500] }} fontSize="small" />
                                article offert
                            </div>
                        )}
                    </Grid>

                    {/* - condition ternaire si le type est 'Commande' -------------- */}
                    {product.grouperepartition === "Commande" ? (
                        <>
                            <Grid item xs={1} className={styles.customInput}>
                                <div>
                                    <CartInput product={product} inputType="puttc" type="number" isDisabled={cart.reglement !== ""} /> {cart.client.devise}
                                    <div className={styles.stockAlert}>
                                        <strong>Base :</strong> {product.prixbrutbase ? Number.parseFloat(product.prixbrutbase).toFixed(4) : "0.0000"}{" "}
                                        {cart.client.devise}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={1} className={styles.customInput}>
                                <div>
                                    <CartInput product={product} inputType="remise1montant" type="number" isDisabled={cart.reglement !== ""} /> %
                                    <div className={styles.stockAlert}>
                                        <strong>Base :</strong> {product.remise1montantbase ? Number.parseFloat(product.remise1montantbase).toFixed(4) : "0.00"}{" "}
                                        %
                                        <div className={product.justificatif !== "" ? styles.justificationField : styles.justificationFieldNone}>
                                            {product.justificatif}
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={1}>
                                {product.puttc && product.remise1montant
                                    ? Number.parseFloat(product.puttc * (1 - product.remise1montant / 100)).toFixed(4)
                                    : product.puttc
                                    ? Number.parseFloat(product.puttc).toFixed(4)
                                    : "0.0000"}{" "}
                                {cart.client.devise}
                            </Grid>

                            {cart.fromOffer === false && (
                                <Grid item xs={1}>
                                    {product.unitedevente}
                                </Grid>
                            )}

                            {cart.fromOffer && (
                                <Grid item xs={1} className={styles.customInput}>
                                    <div>
                                        <CartInput product={product} inputType="credit_amount" type="number" isDisabled={false} /> {cart.client.devise}
                                    </div>
                                </Grid>
                            )}

                            {/* <Grid item xs={1} >{product.taxetaux && product.taxetaux} %</Grid> */}

                            <Grid item xs={1} className={styles.totalQte}>
                                {Number.parseFloat(product.quantity).toFixed(4)}
                                {product.stockdisponible < product.quantity && (
                                    <div className={styles.stockAlert}>
                                        <WarningAmberIcon sx={{ color: orange[500] }} fontSize="small" /> stock
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={1} className="SubTotal">
                                {product.puttc
                                    ? product.remise1montant
                                        ? Number.parseFloat(product.quantity * (product.puttc * (1 - product.remise1montant / 100))).toFixed(2)
                                        : Number.parseFloat(product.puttc * product.quantity).toFixed(2)
                                    : "0.00"}{" "}
                                {cart.client.devise}
                            </Grid>

                            <Grid item xs={2} className={styles.customInputTop}>
                                <CartInput product={product} inputType="comment" />
                                <div className={styles.rawspace} />
                            </Grid>

                            <Grid item xs={1}>
                                <CartSelectType product={product} inputType="grouperepartition" />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={4}>
                                Crédits {product.grouperepartition}
                            </Grid>
                            <Grid item xs={1} className={styles.totalQte}>
                                {product.quantity}
                                {product.stockdisponible < product.quantity && (
                                    <div className={styles.stockAlert}>
                                        <WarningAmberIcon sx={{ color: orange[500] }} fontSize="small" /> stock
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={1}>
                                {" "}
                                -{" "}
                            </Grid>

                            <Grid item xs={2} className={styles.customInputTop}>
                                <CartInput product={product} inputType="comment" />
                            </Grid>
                            <Grid item xs={1}>
                                <CartSelectType product={product} inputType="grouperepartition" />
                            </Grid>
                        </>
                    )}
                </Grid>
            ))}

            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTotalContainer}>
                <Grid item xs={7}>
                    Totaux :
                </Grid>
                <Grid item xs={1} className={styles.totalQte}>
                    {cart.cartTotalQuantity ? Number.parseInt(cart.cartTotalQuantity, 10) : "0"}
                </Grid>
                <Grid item xs={1} className={styles.total}>
                    {cart.cartTotalAmountCheckout ? Number.parseFloat(cart.cartTotalAmountCheckout).toFixed(2) : "0.00"} {cart.client.devise}
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
            <div id="AlertValueNegative" style={{ fontWeight: 600, color: "red" }}>
                Attention, il est interdit d&#39;avoir un PU T.T.C. NET négatif
            </div>

            <div className={styles.bottomButtons}>
                <SubmitButton buttonText="Générer les commandes" onClick={checkShoppingCart} disabled={isErrorHandling} id="SubmitGenerateOrder" />
            </div>
        </Grid>
    );
};

export default CheckoutTable;
