import { useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import styles from "../../../styles/bdArea.module.css";
import AllChronologie from "../../components/chronologie/AllChronologie";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";
import Stats from "../stats/Stats";
/* ------------------------------------------------------------------------- */

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const BdArea = () => {
    const [value, setValue] = useState(0);
    const isOnline = useOnlineStatus();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isOnline) {
        return (
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                <Grid item xs={12} className={styles.tabContainer}>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={value} onChange={handleChange} aria-label="BD Supervisor tab menu">
                                <Tab label="Historique" {...a11yProps(0)} />
                                <Tab label="Statistiques" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <AllChronologie />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Stats />
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
        );
    }
    return <Offline />;
};

export default BdArea;
