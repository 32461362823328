import WifiOffIcon from "@mui/icons-material/WifiOff";
import { useNavigate } from "react-router-dom";

import logoBd from "../../assets/img/hexagone_blanc.png";
import styles from "../../styles/checkConnection.module.css";
import SubmitButton from "../components/buttons/SubmitButton";

/* ------------------------------------------------------------------------- */
const Offline = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <img src={logoBd} alt="logo Biotech Dental" />
            </div>

            <div className={styles.noWifi}>
                <div className={styles.contentnoWifi}>
                    <WifiOffIcon sx={{ fontSize: 100 }} />
                    <h1>Pas de connexion</h1>
                    <h4>Merci de bien vouloir verifier votre connexion internet</h4>
                </div>
            </div>

            <div className={styles.button}>
                <SubmitButton buttonText="Retourner à l'accueil" buttonStyle="dark" onClick={() => navigate("/")} />
            </div>
        </div>
    );
};

export default Offline;
