import { useState, useEffect } from "react";

import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid } from "@mui/material";
import { orange, blue } from "@mui/material/colors";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { clearCommercialOffer, commercialOfferSelector, getTotalsOffer } from "./commercialOfferSlice";
import { usePostRefreshTokenMutation } from "../../app/services/keycloakApi";
import { useAddCommercialOfferMutation, useUpdateOfferMutation } from "../../app/services/lumenApi";
import { usePutTraiterLogMutation, usePostGetTarifClientMutation } from "../../app/services/middleware";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import OfferInput from "../../common/components/commercialOfferInput/OfferInput";
import { BASE_BDD_MIDDLEWARE_API, ENV } from "../../common/utils/apiConstants";
import {
    importAllImages,
    findImageItem,
    toastError,
    seedInfoErrorOccured,
    toastSuccess,
    getFormDataToRefreshKeycloakToken,
} from "../../common/utils/helperFunctions";
import styles from "../../styles/checkoutTable.module.css";
import { authSelector, resetUser } from "../auth/authSlice";

/* ------------------------------------------------------------------------- */

const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));
const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));
const CheckoutOfferTable = () => {
    // console.log("[INFO] - component CheckouTable is rendered");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [triggerAddOffer, resTriggerAddOffer] = useAddCommercialOfferMutation();
    const [triggerUpdateOffer, resTriggerUpdateOffer] = useUpdateOfferMutation();
    const commercialOffer = useSelector(commercialOfferSelector, shallowEqual);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { offerItems } = useSelector(commercialOfferSelector);
    const auth = useSelector(authSelector);

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
        criticite: 3,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const listArticles = [];

    commercialOffer.offerItems.forEach((elem) => {
        listArticles.push({
            index: parseInt(elem.index + 1, 10),
            codearticle: elem.reference,
        });
    });

    const bodyParamRequest = encodeURIComponent(
        JSON.stringify({
            base: BASE_BDD_MIDDLEWARE_API,
            client: commercialOffer.offerClient.clientCode,
            articles: listArticles,
        })
    );

    const [triggerGetTarifs] = usePostGetTarifClientMutation();

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();

    const SubTotal = document.getElementsByClassName("SubTotal");

    function handleClearOffer() {
        dispatch(clearCommercialOffer());
    }

    /* ------------------------------------------------------------------------
     * - useEffect definitions
     * --------------------------------------------------------------------- */
    useEffect(() => {
        for (let i = 0; i < SubTotal.length; i += 1) {
            if (SubTotal[i].textContent.indexOf("-") !== -1) {
                document.getElementById("AlertValueNegative").style.display = "block";
                break;
            } else {
                document.getElementById("AlertValueNegative").style.display = "none";
            }
        }
    });

    useEffect(() => {
        if (SubTotal.length === 0) {
            if (document.getElementById("AlertValueNegative")) {
                document.getElementById("AlertValueNegative").style.display = "none";
            }
        }
    });

    useEffect(() => {
        if (offerItems[offerItems.length - 1].hasOwnProperty("type")) {
            dispatch(getTotalsOffer());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerItems[offerItems.length - 1].hasOwnProperty("type")]);

    useEffect(() => {
        if (resTriggerAddOffer.status === "rejected" || resTriggerUpdateOffer.status === "rejected") {
            toastError("Une erreur s'est produite lors de la création de l'offre commerciale.", 5000);
        }

        if (resTriggerAddOffer.status === "fulfilled") {
            if (resTriggerAddOffer.data?.success === true) {
                setIsSubmitting(true);
                toastSuccess("L'offre commerciale a été enregistrée avec succès", 5000);
                handleClearOffer();
                navigate("/search-offer");
            } else {
                toastError("Une erreur s'est produite lors de la création de l'offre commerciale.", 5000);
            }
        }

        if (resTriggerUpdateOffer.status === "fulfilled") {
            if (resTriggerUpdateOffer.data?.success === true) {
                toastSuccess("L'offre commerciale a été enregistrée avec succès", 5000);
                handleClearOffer();
                navigate("/search-offer");
            } else {
                toastError("Une erreur s'est produite lors de la sauvegarde de l'offre commerciale", 5000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerAddOffer, resTriggerUpdateOffer]);

    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */

    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);

    useEffect(() => {
        const getTarifsFct = async () => {
            await triggerGetTarifs(bodyParamRequest)
                .unwrap()
                .then((response) => {
                    if (response.status === "fulfilled" && response.isSuccess === false) {
                        const msgError = "Une erreur s'est produite lors de la récupération des tarifs. Merci de cliquer à nouveau sur 'Etape suivante'";
                        toast.error(
                            <>
                                Une erreur s&#39;est produite lors de la récupération des tarifs
                                <br />
                                Merci de cliquer à nouveau sur le bouton &#39;ETAPE SUIVANTE&#39;.
                            </>
                        );

                        // - send log warning
                        const errorInfos = seedInfoErrorOccured(__filename, auth.user.email, msgError);
                        const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                        triggerLog(bodyParam);
                        navigate("/add-commercial-offer");
                    } else {
                        dispatch(getTotalsOffer());
                    }
                });
        };
        getTarifsFct().catch((e) => {
            const msgError = "Une erreur s'est produite lors de la récupération des tarifs. Merci de cliquer à nouveau sur le bouton 'Etape suivante'";
            toast.error(
                <>
                    Une erreur s&#39;est produite lors de la récupération des tarifs.
                    <br />
                    Merci de cliquer à nouveau sur le bouton &#39;ETAPE SUIVANTE&#39;.
                </>
            );

            // - send log warning
            const errorInfos = seedInfoErrorOccured(__filename, auth.user.email, msgError);
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
            navigate("/add-commercial-offer");
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* - main handling ----------------------------------------------------- */
    const ArrayNumberNegativeValue = [];

    const handleSubmitOffer = async () => {
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);

        if (commercialOffer.offerReference === "") {
            toastError("Merci de saisir une référence", 7000);
            return;
        }

        for (let i = 0; i < SubTotal.length; i += 1) {
            if (SubTotal[i].textContent.indexOf("-") !== -1) {
                ArrayNumberNegativeValue.push(-1);
            } else {
                ArrayNumberNegativeValue.push(1);
            }
        }

        if (ArrayNumberNegativeValue.indexOf(-1) !== -1) {
            toastError("Le Total de votre offre commerciale est inférieur à 0. Merci de vérifier les remises.", 4000);
        } else {
            try {
                const body = {
                    reference: commercialOffer.offerReference,
                    client_code: commercialOffer.offerClient.clientCode,
                    social_reason: commercialOffer.offerClient.client,
                    zip_code: commercialOffer.offerClient.codepostal,
                    shipping_address: commercialOffer.deliveryAddressOffer,
                    status: 1,
                    currency: commercialOffer.offerClient.devise,
                    amount_ht: commercialOffer.totalAmountCheckout, // Default value actually, waiting Jo answer
                    amount_ttc: commercialOffer.totalAmountCheckout,
                    items: [
                        ...commercialOffer.offerItems.map((item) => ({
                            id: item.id,
                            reference: item.reference,
                            label: item.designation,
                            custom_label: item.custom_label,
                            quantity: item.quantity,
                            discount_amount_1: parseFloat(item.discount_amount_1),
                            discount_justification_1: null,
                            discount_amount_2: null,
                            discount_justification_2: null,
                            credit_amount: item.credit_amount ? parseFloat(item.credit_amount) : 0.0,
                            tax_code: "001",
                            tax_descritpion: "FRA TVA Taux Normal",
                            tax_rate: 20,
                            unit_price_brut_base: item.unit_price_brut_base ? parseFloat(item.unit_price_brut_base) : 0,
                            unit_price_brut: item.unit_price_brut ? parseFloat(item.unit_price_brut) : 0,
                            unit_price_net: item.unit_price_net ? parseFloat(item.unit_price_net) : 0,
                            comment: item.comment,
                        })),
                    ],
                };

                if (!commercialOffer.isRecoveryOffer) {
                    triggerAddOffer(body);
                } else {
                    triggerUpdateOffer({
                        id: commercialOffer.offerId,
                        ...body,
                    });
                }
            } catch (error) {
                errorInfos.className = "CheckoutOfferTable.js::handleOnClick()";
                errorInfos.message = "Une erreur s'est produite lors de l'enregistrement de l'offre commerciale";

                toast.error(<span>{errorInfos.message}</span>);

                if (error.hasOwnProperty("error")) {
                    errorInfos.message += ` - Détails: ${error.error}`;
                }
                errorInfos.criticite = 3;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                setIsSubmitting(false);
            }
        }
    };

    function confirmPopupGenerate(ref) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <h2>Confirmer</h2>
                        <p>L&#39;article référence {ref} apparait sur plusieurs lignes de votre offre commerciale. Comfirmez-vous la saisie ?</p>
                        <button onClick={onClose}>Refuser</button>
                        <button
                            onClick={() => {
                                onClose();
                                handleSubmitOffer();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    /* - pre traitement ------------------------------------------------------ */
    const checkOffer = async () => {
        /* - Popup alerte si un article apparait dans plusieurs lignes du panier - */
        const tabRefArticle = [];

        commercialOffer.offerItems.forEach((elem) => {
            if (elem.reference in tabRefArticle) {
                // eslint-disable-next-line operator-assignment
                tabRefArticle[elem.reference] = tabRefArticle[elem.reference] + 1;
            } else {
                tabRefArticle[elem.reference] = 1;
            }
        });

        let ref = "";
        let refOnMultiLnes = false;
        for (const i in tabRefArticle) {
            if (tabRefArticle[i] > 1) {
                ref = i;
                refOnMultiLnes = true;
                break;
            }
        }

        if (refOnMultiLnes) {
            confirmPopupGenerate(ref);
        } else {
            handleSubmitOffer();
        }
    };

    const handlingCreateOrder = async () => {};

    /* -- display content -------------------------------------------------- */
    return (
        <Grid container direction="column" justifyContent="space-between" alignItems="center" className={styles.pdR10}>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTableHeader}>
                <Grid item xs={2.5}>
                    Produit
                </Grid>
                <Grid item xs={2}>
                    Désignation personnalisée
                </Grid>
                <Grid item xs={1}>
                    PU T.T.C. brut{" "}
                </Grid>
                <Grid item xs={1}>
                    Remise
                </Grid>
                <Grid item xs={1} fontWeight={600}>
                    PU T.T.C. Net
                </Grid>
                <Grid item xs={1}>
                    Montant crédit
                </Grid>
                <Grid item xs={1} className={styles.totalQte}>
                    Qté
                </Grid>
                <Grid item xs={1}>
                    Sous-total
                </Grid>
                <Grid item xs={1.5}>
                    Commentaire
                </Grid>
            </Grid>

            {commercialOffer.offerItems.map((product, index) => (
                <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-top" className={styles.cartTableRow} key={index}>
                    <Grid item xs={0.5}>
                        <div className={styles.blockImg}>
                            <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                        </div>
                    </Grid>

                    <Grid item xs={2} className={styles.alignL}>
                        <h4 className={styles.ref}>{product.reference}</h4>
                        <p className={styles.productDesignation}>{product.designation}</p>
                        <div className={styles.containerCatalog}>
                            {product.catalogueniv1 !== "" && product.catalogueniv1 !== null && (
                                <div className={styles.catalog}>
                                    <span>{product.catalogueniv1}</span>
                                </div>
                            )}
                            {product.catalogueniv2 !== "" && product.catalogueniv2 !== null && product.catalogueniv2 !== product.catalogueniv1 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv2}</span>
                                </div>
                            )}
                            {product.catalogueniv3 !== "" && product.catalogueniv3 !== null && product.catalogueniv3 !== product.catalogueniv2 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv3}</span>
                                </div>
                            )}
                        </div>
                        {product.unit_price_brut * (1 - product.remise1montant / 100) === 0 && (
                            <div className={styles.offreAlert}>
                                <InfoIcon sx={{ color: blue[500] }} fontSize="small" />
                                article offert
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={2} className={styles.customInputTopComm}>
                        <OfferInput product={product} inputType="custom_label" />
                    </Grid>

                    <Grid item xs={1} className={styles.customInput}>
                        <div>
                            <OfferInput product={product} inputType="unit_price_brut" type="number" /> {commercialOffer.offerClient.devise}
                            <div className={styles.stockAlert}>
                                <strong>Base :</strong> {product.unit_price_brut_base ? Number.parseFloat(product.unit_price_brut_base).toFixed(4) : "0.0000"}{" "}
                                {commercialOffer.offerClient.devise}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={1} className={styles.customInput}>
                        <div>
                            <OfferInput product={product} inputType="discount_amount_1" type="number" /> %
                            <div className={styles.stockAlert}>
                                <strong>Base :</strong> {product.remise1montantbase ? Number.parseFloat(product.remise1montantbase).toFixed(4) : "0.00"} %
                                <div className={product.justificatif !== "" ? styles.justificationField : styles.justificationFieldNone}>
                                    {product.justificatif}
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={1}>
                        {product.unit_price_brut && product.discount_amount_1
                            ? Number.parseFloat(product.unit_price_brut * (1 - product.discount_amount_1 / 100)).toFixed(4)
                            : product.unit_price_brut
                            ? Number.parseFloat(product.unit_price_brut).toFixed(4)
                            : "0.0000"}{" "}
                        {commercialOffer.offerClient.devise}
                    </Grid>

                    <Grid item xs={1} className={styles.customInput}>
                        <div>
                            <OfferInput product={product} inputType="credit_amount" type="number" isDisabled={false} /> {commercialOffer.offerClient.devise}
                        </div>
                    </Grid>

                    {/* <Grid item xs={1} className={styles.totalQte}> */}
                    <Grid item xs={1} className={styles.customInput}>
                        {/* {product.quantity} */}
                        <div>
                            <OfferInput product={product} inputType="quantity" type="number" isDisabled={false} />
                        </div>
                        {product.stockdisponible < product.quantity && (
                            <div className={styles.stockAlert}>
                                <WarningAmberIcon sx={{ color: orange[500] }} fontSize="small" /> stock
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={1} className="SubTotal">
                        {product.credit_amount
                            ? Number.parseFloat(product.credit_amount).toFixed(2)
                            : product.unit_price_brut
                            ? product.discount_amount_1
                                ? Number.parseFloat(product.quantity * (product.unit_price_brut * (1 - product.discount_amount_1 / 100))).toFixed(2)
                                : Number.parseFloat(product.unit_price_brut * product.quantity).toFixed(2)
                            : "0.00"}{" "}
                        {commercialOffer.offerClient.devise}
                    </Grid>

                    <Grid item xs={1.5} className={styles.customInputTopComm}>
                        <OfferInput product={product} inputType="comment" />
                        <div className={styles.rawspace} />
                    </Grid>
                </Grid>
            ))}

            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTotalContainer}>
                <Grid item xs={8.5}>
                    Totaux :
                </Grid>
                <Grid item xs={1} className={styles.totalQte}>
                    {commercialOffer.totalQuantity ? Number.parseFloat(commercialOffer.totalQuantity).toFixed(4) : "0"}
                </Grid>
                <Grid item xs={1} className={styles.total}>
                    {commercialOffer.totalAmountCheckout ? Number.parseFloat(commercialOffer.totalAmountCheckout).toFixed(2) : "0.00"}{" "}
                    {commercialOffer.offerClient.devise}
                </Grid>
                <Grid item xs={1.5}></Grid>
            </Grid>
            <div id="AlertValueNegative" style={{ fontWeight: 600, color: "red" }}>
                Attention, il est interdit d&#39;avoir un PU T.T.C. NET négatif
            </div>

            {commercialOffer.isRecoveryOffer && commercialOffer.status >= 3 ? (
                <SubmitButton
                    id="btn-import-offer-in-sage"
                    buttonStyle={isSubmitting && "disabledBig"}
                    buttonText="Importer dans Sage"
                    onClick={handlingCreateOrder}
                    disabled={isSubmitting}
                />
            ) : (
                <SubmitButton
                    id="btn-save-offer"
                    buttonStyle={isSubmitting && "disabledBig"}
                    buttonText="Enregistrer"
                    onClick={checkOffer}
                    disabled={isSubmitting}
                />
            )}
        </Grid>
    );
};

export default CheckoutOfferTable;
