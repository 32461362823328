import React from "react";

import logoHomepage from "../../../assets/img/e-dental.png";
import styles from "../../../styles/accueil.module.css";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

const Accueil = () => {
    const isOnline = useOnlineStatus();

    if (isOnline) {
        return (
            <div className={styles.page}>
                <img src={logoHomepage} alt="Logo HomePage" className={styles.logo} />
                <div className={styles.slogan} id="slogan">
                    Votre espace de travail 100% numérique
                </div>
            </div>
        );
    }
    return <Offline />;
};

export default Accueil;
