import { easeQuadInOut } from "d3-ease";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

import styles from "../../../styles/meterChart.module.css";
import AnimatedProgressProvider from "../animatedProgressProvider/AnimatedProgressProvider";

/* Import react-circular-progressbar module and styles */
import "react-circular-progressbar/dist/styles.css";

/* ------------------------------------------------------------------------- */

const MeterChart = ({ dataValue, limitValue, dataLabel }) => {
    return (
        <AnimatedProgressProvider valueStart={0} valueEnd={dataValue} duration={1.0} easingFunction={easeQuadInOut}>
            {(value) => (
                <CircularProgressbarWithChildren
                    minValue={0}
                    maxValue={limitValue}
                    value={value}
                    circleRatio={0.75} // semi circle
                    strokeWidth={3} // width of circular line
                    background
                    backgroundPadding={10}
                    className={styles.customPbWC}
                    styles={buildStyles({
                        rotation: 0.625,
                        strokeLinecap: "round",
                        // Colors
                        pathColor: "#1ba9aa", // color semi circular
                        textColor: "#000", // bg text in center
                        trailColor: "#f0f0f0", // bg semi circular
                        // backgroundColor: '#f9f9f9',
                        backgroundColor: "#fff",
                        pathTransition: "none",
                    })}
                >
                    <div className={styles.info}>
                        <strong>{Math.round(value)}</strong>
                        <br />
                        {dataLabel}
                    </div>
                </CircularProgressbarWithChildren>
            )}
        </AnimatedProgressProvider>
    );
};

export default MeterChart;
