import { useEffect, useState } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import frLocale from "date-fns/locale/fr";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

import { useGetDBL100Query } from "../../../app/services/lumenApi";
import { middleware, usePutSettlementMutation } from "../../../app/services/middleware";
import { setIsLoadingInfosClient } from "../../../features/cart/cartSlice";
import SelectClientSettlement from "../../../features/settlement/SelectClientSettlement";
import styles from "../../../styles/settlement.module.css";
import SubmitButton from "../../components/buttons/SubmitButton";
import { ENV } from "../../utils/apiConstants";

const Settlement = () => {
    const dispatch = useDispatch();

    const [client, setClient] = useState(0);
    const [soldeSmiley, setSoldeSmiley] = useState("");
    const [selectedDB, setSelectedDB] = useState(0); // bdd sélectionnée

    const [paymentMethod, setPaymentMethod] = useState(0); // liste des modes de règlement
    const [date, setDate] = useState(new Date());
    const [selectedType, setSelectedType] = useState("1"); // reglement ou remboursement
    const [devise, setDevise] = useState(0); // € ou autre
    const [selectedPayment, setSelectedPayment] = useState(0); // mode de règlement sélectionné
    const [error, setError] = useState(false); // erreur champs amount

    const [triggerInfosClient, resultInfosClient] = middleware.endpoints.getInfosClient.useLazyQuery();
    const [triggerSmiley, resultSmiley] = middleware.endpoints.getSoldeSmiley.useLazyQuery();

    const [triggerPayment, resultPayment] = middleware.endpoints.getPaymentMethod.useLazyQuery();
    const [triggerSettlement, resultSettlement] = usePutSettlementMutation();

    const dbL100 = useGetDBL100Query();
    const paymentMethodList = [];

    let formDate = "";

    if (date !== null) {
        formDate = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
    }

    function clearStates() {
        document.getElementById("inputClient").value = "";
        setClient(0);
        setSoldeSmiley("");
        setDate(new Date());
        setSelectedType("1");
        setDevise(0);
    }

    /* --------------------------------------------------------------------- */
    useEffect(() => {
        if (dbL100.status === "fulfilled") {
            if (ENV === "PROD") {
                setSelectedDB(dbL100.data.database[4].name);
            } else {
                setSelectedDB(dbL100.data.database[0].name);
            }
        }
    }, [dbL100]);

    useEffect(() => {
        if (resultSettlement.status === "fulfilled") {
            if (resultSettlement.data.success === true) {
                clearStates();
                toast.success("Règlement effectué avec succès");
            } else {
                toast.error("Une erreur est survenue. Le règlement n'a pas été effectué.");
            }
        }
    }, [resultSettlement]);

    useEffect(() => {
        if (selectedDB !== 0) {
            triggerPayment(selectedDB);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDB]);

    useEffect(() => {
        if (resultPayment.status === "fulfilled") {
            if (resultPayment.data.nbresultats > 0) {
                resultPayment.data.modesdereglement.map((item, index) => paymentMethodList.push({ code: index, label: item }));
                if (paymentMethodList.length > 0) {
                    setPaymentMethod(paymentMethodList);
                }
                setSelectedPayment(paymentMethodList[0].label);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultPayment]);

    useEffect(() => {
        if (client !== 0) {
            triggerInfosClient(client.clientCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    useEffect(() => {
        if (resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success) {
            if (resultInfosClient.data.client.suuid !== "") {
                if (resultInfosClient.data.client.devise === "EUR") {
                    setDevise("€");
                } else {
                    setDevise(resultInfosClient.data.client.devise);
                }
                triggerSmiley(resultInfosClient.data.client.suuid);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultInfosClient]);

    useEffect(() => {
        if (resultSmiley.isLoading) {
            dispatch(setIsLoadingInfosClient(true));
        }

        if (resultSmiley.status === "fulfilled") {
            dispatch(setIsLoadingInfosClient(false));
            setSoldeSmiley(resultSmiley.data.result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSmiley]);
    /* --------------------------------------------------------------------- */

    const handleMode = (value) => {
        setSelectedPayment(value);
    };

    const handleVerif = (value) => {
        const nb = value.replace(",", ".");
        if (Number.isNaN(nb)) {
            setError(true);
        } else {
            setError(nb <= 0);
        }
    };

    const handleBase = (value) => {
        setSelectedDB(value);
    };

    const handleChangeTypePayment = (event) => {
        setSelectedType(event.target.value);
    };

    const onSubmit = async (values) => {
        try {
            let body = "";
            if (ENV === "PROD") {
                body = {
                    modedereglement: selectedPayment,
                    montant: document.getElementById("outlined-adornment-amount").value,
                    datepaiement: formDate,
                    societe: selectedDB,
                    codeclient: client.clientCode,
                    sens: selectedType === "1" ? "reglement" : "remboursement",
                };
            } else {
                body = {
                    modedereglement: selectedPayment,
                    montant: document.getElementById("outlined-adornment-amount").value,
                    datepaiement: formDate,
                    societe: selectedDB,
                    suuid: "0e2bd827-9720-11ec-8fb6-9220da0d1d4e",
                    sens: selectedType === "1" ? "reglement" : "remboursement",
                };
            }
            if (body !== "") {
                const bodyParam = encodeURIComponent(JSON.stringify(body));
                triggerSettlement(bodyParam);
            }
        } catch (error) {
            toast.error("Le règlement n'a pas pu être créé.");
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1 className={styles.title}>Creer un règlement</h1>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={10} id="selectOrder">
                    <SelectClientSettlement setClient={setClient} />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={12} />
            </Grid>
            {client !== 0 && (
                <div className={styles.formContainer}>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className={styles.settlementFormContainer}>
                                <span className={styles.solde}> Solde Smiley : {resultSmiley.isLoading ? <BeatLoader /> : soldeSmiley}</span>
                                <div className={styles.form}>
                                    <div className={styles.inputContainer}>
                                        <FormControl fullWidth>
                                            <InputLabel>Base SAGE L100</InputLabel>
                                            <Select value={selectedDB} label="Base SAGE L100" onChange={(e) => handleBase(e.target.value)} disabled>
                                                {dbL100.data &&
                                                    dbL100.data.database.map((item) => (
                                                        <MenuItem key={item.code} value={item.name}>
                                                            {item.wording}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className={styles.twoFieldsRow}>
                                        <div className={styles.inputContainer}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                                <DatePicker
                                                    label="Date du règlement"
                                                    value={date}
                                                    onChange={(newValue) => {
                                                        setDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <FormControl fullWidth>
                                                <InputLabel>Mode de Règlement</InputLabel>
                                                <Select value={selectedPayment} label="Mode de Règlement" onChange={(e) => handleMode(e.target.value)}>
                                                    {paymentMethod.length > 0 &&
                                                        paymentMethod.map((item) => (
                                                            <MenuItem key={item.code} value={item.label}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={styles.inputContainer}>
                                        {devise !== 0 && (
                                            <>
                                                <FormControl fullWidth sx={{ m: 1 }}>
                                                    <InputLabel htmlFor="outlined-adornment-amount">Montant</InputLabel>
                                                    <OutlinedInput
                                                        name="amount"
                                                        id="outlined-adornment-amount"
                                                        startAdornment={<InputAdornment position="start">{devise}</InputAdornment>}
                                                        label="Amount"
                                                        onChange={(e) => handleVerif(e.target.value)}
                                                        error={error}
                                                        required
                                                    />
                                                </FormControl>
                                                {error && <span className={styles.error}>Veuillez insérer un montant valide.</span>}
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <label>
                                            <input type="radio" value="1" checked={selectedType === "1" && true} onChange={(e) => handleChangeTypePayment(e)} />
                                            <span className={styles.ml}>Règlement</span>
                                        </label>
                                        <label>
                                            <input
                                                className={styles.radioInput}
                                                type="radio"
                                                value="2"
                                                checked={selectedType === "2" && true}
                                                onChange={(e) => handleChangeTypePayment(e)}
                                            />
                                            <span className={styles.ml}>Remboursement</span>
                                        </label>
                                    </div>
                                </div>

                                <div className={styles.buttons}>
                                    <SubmitButton buttonText="Générer" disabled={error} type="submit" buttonStyle="dark" />
                                </div>
                            </form>
                        )}
                    />
                </div>
            )}
        </>
    );
};

export default Settlement;
