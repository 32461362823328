import { useEffect, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { lumenApi } from "../../../app/services/lumenApi";
import AddressRow from "../../../features/addresses/AddressRow";
import styles from "../../../styles/duplicateOrder.module.css";

/* ------------------------------------------------------------------------- */
const PopUpShow = (props) => {
    const [triggerGetOrderDetails, resGetOrderDetails] = lumenApi.endpoints.getOrderDetails.useLazyQuery();
    const shippingAdr = props.order.livraison;
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(7);

    useEffect(() => {
        const order = {
            codeClient: props.order.codeclient,
            numeroCommande: props.order.numero,
        };
        triggerGetOrderDetails(order);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.codeclient, props.order.numero]);

    useEffect(() => {
        if (resGetOrderDetails.status === "fulfilled") {
            if (resGetOrderDetails.data.success === true) {
                setRows(resGetOrderDetails.data.commandes.commande[0].articles.article);
            }
        }
    }, [resGetOrderDetails]);

    const columns = [
        {
            field: "code",
            headerName: "Article",
            headerClassName: "tableHeader",
            cellClassName: "ref",
            flex: 1,
            headerAlign: "center",
            minWidth: 100,
            align: "left",
        },
        {
            field: "designation",
            headerName: "Désignation",
            headerClassName: "tableHeader",
            cellClassName: "product",
            flex: 1,
            minWidth: 400,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "quantite",
            headerName: "Quantité",
            headerClassName: "tableHeader",
            cellClassName: "quantity",
            flex: 1,
            minWidth: 10,
            headerAlign: "center",
            align: "center",
        },
    ];

    return (
        <div className={styles.popUp}>
            <h2 className={styles.center}>Détails de la commande</h2>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                {/* <Grid item xs={12}></Grid> */}
                <br />
                <Grid item xs={6}>
                    <h4 className={styles.client}>
                        <AccountCircleIcon fontSize="small" />
                        Client : {props.order.codeclient} - {props.order.facturation.intitule}
                    </h4>
                </Grid>
                <Grid item xs={6}>
                    <div className={styles.adrLivr}>
                        <h4>
                            <LocationOnIcon fontSize="small" />
                            Adresse de livraison
                        </h4>
                        <AddressRow item={shippingAdr} />
                    </div>
                </Grid>
            </Grid>

            <div className={styles.viewContainer}>
                <DataGrid
                    rows={rows}
                    getRowId={(row) => row.code}
                    rowHeight={36}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[7, 10, 15]}
                />
            </div>
        </div>
    );
};

export default PopUpShow;
