import { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { addListInfos, setClientQuote, setFactAddress, setShippingAddressClient } from "./quoteSlice";
import { usePostRefreshTokenMutation } from "../../app/services/keycloakApi";
import { middleware, usePutTraiterLogMutation } from "../../app/services/middleware";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import { ENV } from "../../common/utils/apiConstants";
import { getFormDataToRefreshKeycloakToken } from "../../common/utils/helperFunctions";
import styles from "../../styles/addQuote.module.css";
import { authSelector, resetUser } from "../auth/authSlice";

/* ------------------------------------------------------------------------- */
const SelectClientQuote = () => {
    const ref = useRef(null);

    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const [displayResult, setDisplayResult] = useState(false);
    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();

    const [searchResults, setSearchResults] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [timer, setTimer] = useState(null);
    const [triggerSearchClient, result] = middleware.endpoints.getClientsBySearch.useLazyQuery();

    const errorInfos = {
        service: "espaceADV",
        className: "SelectClient.js::getClientBySearchQuery()",
        code: 400,
        userEmail: auth.user.email,
        criticite: 3,
        environment: ENV,
    };

    const [triggerLog] = usePutTraiterLogMutation();

    /* --------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);
    /* --------------------------------------------------------------------- */

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setDisplayResult(false);
        }
    };

    const handleSelectClient = (clientData) => {
        // call refreshToken
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        triggerRefreshToken(formData);

        dispatch(setClientQuote(clientData));
        dispatch(setFactAddress("FAC"));
        dispatch(setShippingAddressClient(JSON.parse(localStorage.getItem("clientQuote")).adresseLivraisonDefaut));

        const info = [
            {
                uniqueId: Math.random().toString(36).substring(2, 9),
                type: "0",
                client: `${clientData.clientCode} - ${clientData.client}`,
            },
        ];
        dispatch(addListInfos(info));
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    useEffect(() => {
        if (!result.isLoading) {
            setShowProgress(false);
        }
        if (!result.isLoading && result.data) {
            if (result.data.success === false) {
                toast.error("Une erreur s'est produite lors de la recherche du client.");

                errorInfos.message = result.data.message;
                errorInfos.criticite = 3;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            } else {
                setSearchResults(result.data.nbresultats > 0 ? result.data.clients.client : null);
            }
        }
        if (!result.data) {
            setSearchResults(null);
        }
        if (result.isError) {
            toast.error("Une erreur s'est produite lors de la recherche du client.");
            errorInfos.message = result.error.error;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.isLoading, result.data, searchResults]);

    function handleInputChange(input) {
        const filterValue = input.trim();
        /**
         * Handling to avoid error when input field value = 0
         * It's a specific case which induces a CORS Error
         */

        if (filterValue !== "0" && filterValue.length > 0) {
            setShowProgress(true);
            clearTimeout(timer);

            const newTimer = setTimeout(() => {
                triggerSearchClient(input);
            }, 400);

            setTimer(newTimer);

            setShowProgress(false);
            setDisplayResult(true);
        }
    }

    // -- display content ----------------------------------------
    return (
        <div className={styles.searchContainer} ref={ref}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2 className={`${styles.title} title-add-quote`}>Nouveau Devis - Sélection du client</h2>

                    <div className={styles.selectCell}>
                        <input
                            autoComplete="off"
                            type="text"
                            name="clientQuote"
                            placeholder="Indiquez vos critères de recherche: numéro client, raison sociale ou département"
                            className={styles.searchInput}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                        <span className={styles.searchIcon}> {showProgress ? <CircularProgress color="inherit" /> : <SearchIcon />}</span>
                    </div>
                </Grid>
            </Grid>

            {/* --------------------------------------------------------------------------------- */}

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className={displayResult ? styles.resultsContainer : styles.none}>
                        <span className={styles.closeSearch}>
                            <CloseIcon onClick={() => setDisplayResult(false)} />
                        </span>
                        <div className={styles.resultsBox}>
                            {result?.data?.success === true && result.data.nbresultats === 0 && (
                                <h5 style={{ textTransform: "none" }}>Aucun client ne correspond à ces critères </h5>
                            )}

                            {result?.data?.success &&
                                searchResults &&
                                searchResults.map((client, index) => (
                                    <Grid container direction="row" justifyContent="start" alignItems="center" className={styles.cartTableRow} key={index}>
                                        {/* <Grid item xs={1}><AccountCircleIcon fontSize="medium" /></Grid> */}
                                        <Grid item xs={4}>
                                            <h4 className={styles.ref}>{client.raisonsociale}</h4>
                                            <h4 className={`${styles.ref} label`}>Code SAGE X3 : {client.code}</h4>
                                            <h4 className={styles.ref}>Code SAGE L100 : {client.codel100}</h4>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p className={styles.productDesignation}>{client.adresse1}</p>
                                            {client.adresse2.trim().length > 0 && <p className={styles.productDesignation}>{client.adresse2}</p>}
                                            {client.adresse3.trim().length > 0 && <p className={styles.productDesignation}>{client.adresse3}</p>}
                                            <p className={styles.productDesignation}>
                                                {client.codepostal}, {client.ville}, {client.pays}
                                            </p>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <SubmitButton
                                                buttonText="Sélectionner"
                                                id={`btnClientQuote-${index}`}
                                                onClick={() =>
                                                    handleSelectClient({
                                                        clientCode: client.code,
                                                        client: client.raisonsociale,
                                                        codepostal: client.codepostal,
                                                        adresseLivraisonDefaut: client.adresselivraisondefaut,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={7}></Grid>
                                    </Grid>
                                ))}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default SelectClientQuote;
