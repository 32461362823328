import React, { useEffect, useState } from "react";

import CloudOffIcon from "@mui/icons-material/CloudOff";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

import ValuePopUp from "./ValuePopUp";
import { lumenApi } from "../../../app/services/lumenApi";
import styles from "../../../styles/allChronologie.module.css";
import { escapeRegExp, convertDateFRToEN } from "../../utils/helperFunctions";
import TableSearchInput from "../tableSearchInput/TableSearchInput";

/* ------------------------------------------------------------------------- */
const AllChronologie = () => {
    const [pageSize, setPageSize] = useState(14);

    const [triggerGetAllOrdersFromAllUser, resultAllOrderFromAllUser] = lumenApi.endpoints.getAllOrdersFromAllUser.useLazyQuery();

    useEffect(() => {
        triggerGetAllOrdersFromAllUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = resultAllOrderFromAllUser.data;
    const isFetching = resultAllOrderFromAllUser.isFetching;
    const isError = resultAllOrderFromAllUser.isError;

    const [orderId, setOrderId] = useState(0);
    const [orderType, setOrderType] = useState(0);

    // liaison chronologie - pop-up par récupération de l'order_id
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClick = (params) => {
        const type = params.row.order_type;
        if (type === "saisie") {
            const id = params.row.id.replace(type, "");
            setOrderId(id);
        } else if (type === "orders_web") {
            const id = params.row.id.replace("web", "");
            setOrderId(id);
        }
        setOrderType(type);
        handleClickOpen();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const stringDateComparator = (str1, str2) => {
        // - Ce tri fonctionne sur env local mais pas sur env de DEV!!!
        // TODO revoir pourquoi
        // let d1 = convertStringToDate(str1)
        // let d2 = convertStringToDate(str2)
        // return d1.getTime() - d2.getTime()

        const d1 = convertDateFRToEN(str1.substring(0, 10)) + str1.substring(10, str1.length);
        const d2 = convertDateFRToEN(str2.substring(0, 10)) + str2.substring(10, str2.length);
        return Date.parse(d1) > Date.parse(d2);
    };

    // affichage tableau de la chronologie
    const columns = [
        {
            field: "nom_prenom",
            headerName: "Adv",
            headerClassName: "tableHeader",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "clientCode",
            headerName: "Code client",
            headerClassName: "tableHeader",
            cellClassName: "SFNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },

        {
            field: "client",
            headerName: "Client",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },

        {
            field: "codepostal",
            headerName: "Code postal",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "created_at",
            headerName: "Date",
            headerClassName: "tableHeader",
            sortingOrder: ["desc", "asc"],
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
            type: "string",
            sortComparator: stringDateComparator,
        },
        {
            field: "order_type",
            headerName: "Origine",
            headerClassName: "tableHeader",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => params?.value && params.value.charAt(0).toUpperCase() + params.value.slice(1),
        },
        {
            field: "document",
            renderCell: (params) => <FindInPageIcon className={styles.see} onClick={() => handleClick(params)} />,
            headerName: "Documents générés",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
    ];

    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState(data);

    useEffect(() => {
        setRows(data);
    }, [data]);

    if (isFetching || rows === undefined) return;

    if (!data) {
        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3} className={styles.boxHisto}>
                <div>
                    <h2 className={styles.title}>Commandes passées</h2>
                </div>
                <div className={styles.noResult}>
                    <p>
                        <CloudOffIcon />
                        Vous n&#39;avez aucune commande passée
                    </p>
                </div>
            </Box>
        );
    }
    if (isError) return <div>Une erreur est survenue</div>;

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = data.filter((elem) => {
            return Object.keys(elem).some((field) => {
                return searchRegex.test(elem[field].toString());
            });
        });
        setRows(filteredRows);
    };

    return (
        <Box sx={{ flexGrow: 1 }} className={styles.boxHisto}>
            <h2 className={styles.title}>Historique de toutes les commandes</h2>
            <h4 className={styles.legend}>Affichage de l&#39;historique des commandes passés par tous les utilisateurs de l&#39;Espace ADV.</h4>
            <div className={styles.gridContainer}>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "created_at", sort: "desc" }],
                        },
                    }}
                    components={{ Toolbar: TableSearchInput }}
                    rows={rows}
                    rowHeight={36}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[14, 28, 50]}
                    sortingOrder={["asc", "desc"]}
                    columns={columns}
                    disableRowSelectionOnClick
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(""),
                        },
                    }}
                />
                <Dialog open={open} onClose={handleClose} maxWidth="xl">
                    <div className={styles.grey}>
                        <DialogTitle className={styles.dialogTitle}>Détails des documents liés à cette commande</DialogTitle>
                        <div>{orderId !== 0 && orderType !== 0 && <ValuePopUp type={orderType} id={orderId} />}</div>
                        <DialogActions>
                            <Button onClick={handleClose}>Fermer</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        </Box>
    );
};

export default AllChronologie;
