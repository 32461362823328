import { useEffect, useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import moment from "moment";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import styles from "./tableOrderByDay.module.css";
import { usePostGetNbOrdersPerDayMutation } from "../../../app/services/lumenApi";
import { usePutTraiterLogMutation } from "../../../app/services/middleware";
import { authSelector } from "../../../features/auth/authSlice";
import {
    statSelector,
    setSelectedDateDeb,
    setSelectedDateFin,
    setRefreshTableOrderByUser,
    setLabelSelection,
    setSearchOnDuration,
} from "../../../features/stat/statSlice";
import { ENV } from "../../utils/apiConstants";
import { strDate, elegantStyleDate } from "../../utils/helperFunctions";
import Spinner from "../spinner/Spinner";

/* ----------------------------------------------------------------------- */

function CustomToolbar() {
    return (
        <div className={styles.customTools}>
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        </div>
    );
}

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 180,
        fontSize: theme.typography.pxToRem(13),
        border: "1px solid #dadde9",
        padding: "10px",
    },
}));

/* ----------------------------------------------------------------------- */
const TableOrderByDay = () => {
    const auth = useSelector(authSelector);
    const stat = useSelector(statSelector);

    const dispatch = useDispatch();

    const [triggerOrdersOnDuration, resOrdersOnDuration] = usePostGetNbOrdersPerDayMutation();
    const [titleTable, setTitleTable] = useState("Chiffres réalisés par jour sur la période définie");
    /* ----------------------------------------------------------------------- */

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    /* ----------------------------------------------------------------------- */
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState([]);
    const [tableLength, setLength] = useState(0);

    const columns = [
        {
            field: "day",
            headerName: "jour",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === tableLength ? <span className={styles.txtBold}>Total</span> : elegantStyleDate(params.value)}</>,
            flex: 1,
            sortable: true,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "nbmanualorders",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre de
                    <br />
                    commandes
                    <br />
                    {" manuelles"}
                </p>
            ),
            // headerName: "Nombre de commandes manuelles",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",

            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === tableLength ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "nbweborders",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre de
                    <br />
                    commandes
                    <br />
                    {" web"}
                </p>
            ),
            // headerName: "Nombre de commandes web",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === tableLength ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
            wrapText: true,
            autoHeight: true,
        },

        {
            field: "nbtotalorders",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre total de
                    <br />
                    commandes
                    <br />
                    finalisées
                </p>
            ),
            // headerName: "Nombre total de commandes finalisées",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === tableLength ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "nbtotalordersWaiting",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre total de
                    <br />
                    commandes
                    <br />
                    en attente
                </p>
            ),
            // headerName: "Nombre total de commandes en attente",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === tableLength ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "treatmentTime",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Temps de traitement
                    <br />
                    {"commande web "}
                    <br />
                    (moyenne)
                </p>
            ),
            // headerName: "Temps de traitement commande web (moyenne)",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === tableLength ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },
    ];

    /* --------------------------------------------------------------------- */

    useEffect(() => {
        if (resOrdersOnDuration.isError) {
            errorInfos.className = "Stats.js::postGetNbOrdersByUser()";
            const sourceMsg = resOrdersOnDuration.error?.data?.message ? resOrdersOnDuration.error.data.message : resOrdersOnDuration.error.error;
            errorInfos.message = `Echec lors de la récuperation des commandes par jour sur une période - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation des commandes par jour sur une période. Merci de contacter le Service IT.</span>, {
                duration: 10000,
            });
        }

        if (resOrdersOnDuration.data != null && resOrdersOnDuration.status === "fulfilled") {
            setRows(resOrdersOnDuration.data.jours.jour);
            setLength(resOrdersOnDuration.data.jours.jour.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resOrdersOnDuration]);

    /* --------------------------------------------------------------------- */
    const handleChangeDateDeb = (newValue) => {
        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            dispatch(setSelectedDateDeb(strDate(newValue)));
        }
    };

    const handleChangeDateFin = (newValue) => {
        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            dispatch(setSelectedDateFin(strDate(newValue)));
        }
    };

    const refreshByDuration = () => {
        dispatch(setRefreshTableOrderByUser(false));
        if (stat.selectedDateDeb > stat.selectedDateFin) {
            toast(
                <>
                    <WarningAmberIcon />
                    <span>La date de début doit être antérieure à la date de fin</span>
                </>,
                {
                    duration: 3000,
                }
            );
        } else {
            const body = {
                dateDeb: stat.selectedDateDeb,
                dateFin: stat.selectedDateFin,
            };

            const bodyParam = encodeURIComponent(JSON.stringify(body));
            triggerOrdersOnDuration(bodyParam);

            const title = `Chiffres réalisés entre ${elegantStyleDate(stat.selectedDateDeb)} et ${elegantStyleDate(stat.selectedDateFin)}`;
            setTitleTable(title);

            // update chart graph
            dispatch(setSearchOnDuration(true)); // bool to update chart graph
            dispatch(setLabelSelection(title));
        }
    };
    /* --------------------------------------------------------------------- */
    if (rows === undefined) {
        return <Spinner />;
    }
    return (
        <>
            <div className={styles.paramContainer}>
                <fieldset className={styles.customFieldset}>
                    <legend className={styles.pLegend}>Paramétres de sélection</legend>

                    <br />
                    <p className={styles.subTitle}>Période</p>
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date de début"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDateDeb)}
                            onChange={handleChangeDateDeb}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <br />
                    <br />
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date de fin"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDateFin)}
                            onChange={handleChangeDateFin}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <Fab size="small" color="secondary" aria_label="refresh" className={styles.refreshPos_01}>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <em>
                                    {"Rafraichir le tableau suivant "}
                                    <b>la période sélectionnée</b>
                                </em>
                            }
                        >
                            <RefreshIcon onClick={refreshByDuration} />
                        </HtmlTooltip>
                    </Fab>
                </fieldset>
            </div>

            <div className={styles.tableContainer}>
                <div className={styles.labelArray}>{titleTable}</div>
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "day", sort: "asc" }],
                            },
                        }}
                        components={{ Toolbar: CustomToolbar }}
                        rows={rows}
                        rowHeight={36}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20]}
                        sortingOrder={["asc", "desc"]}
                        columns={columns}
                        disableRowSelectionOnClick
                    />
                </div>
            </div>
        </>
    );
};

export default TableOrderByDay;
