import { useEffect, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { usePostRefreshTokenMutation } from "../../app/services/keycloakApi";
import { lumenApi } from "../../app/services/lumenApi";
import { middleware, usePostArticlesMutation, usePutTraiterLogMutation } from "../../app/services/middleware";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import Spinner from "../../common/components/spinner/Spinner";
import { ENV } from "../../common/utils/apiConstants";
import { findImageItem, getFormDataToRefreshKeycloakToken, importAllImages, strDate } from "../../common/utils/helperFunctions";
import styles from "../../styles/singleCart.module.css";
import AddressRow from "../addresses/AddressRow";
import { authSelector, resetUser } from "../auth/authSlice";
import {
    addToCartPreview,
    cartPreviewSelector,
    clearCartPreview,
    setClientPreview,
    setShippingAddressPreview,
    clearCartPreviewItems,
} from "../cart/cartPreviewSlice";
import {
    clearCart,
    clearSiteSource,
    restoreCart,
    setClient,
    setIsOrderImport,
    setIsOrderWeb,
    setFromQuote,
    setIsRecoveryOrder,
    setOrderSaisieId,
    setOriginComment,
    setSelectedDeliveryDate,
    setShippingAddress,
    setCarrier,
    setService,
} from "../cart/cartSlice";
import { updateLastLocation } from "../location/locationSlice";

const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));
const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));

/* ------------------------------------------------------------------------- */
const SingleCart = () => {
    // console.log("[INFO] - component SingleCart is rendered ...")

    const navigate = useNavigate();

    const location = useLocation();

    const auth = useSelector(authSelector);
    const cartPreview = useSelector(cartPreviewSelector);
    const dispatch = useDispatch();

    const [address, setAddress] = useState(0);

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const { id } = useParams();
    const [triggerGetOrder, resGetOrder] = lumenApi.endpoints.getOrder.useLazyQuery();

    const [triggerGetAddresses, resGetAllAddresses] = middleware.endpoints.getAddresses.useLazyQuery();
    const [triggerArticle] = usePostArticlesMutation();
    const [triggerRefreshToken] = usePostRefreshTokenMutation();

    /* --------------------------------------------------------------------------
     * - function to call triggerArticle for each product of the order
     * ----------------------------------------------------------------------- */
    const getProductsDetails = () => {
        const tabArticles = [];

        resGetOrder.data.products.forEach(async (product) => {
            const body = {
                article: product.reference,
            };
            const bodyParam = encodeURIComponent(JSON.stringify(body));
            await triggerArticle(bodyParam)
                .unwrap()
                .then((response) => {
                    const articles = {
                        ...response.articles.article[0],
                        ...product,
                    };

                    tabArticles.push(articles);
                    tabArticles.sort((a, b) => (a.indexInCart > b.indexInCart ? 1 : -1));
                    dispatch(clearCartPreviewItems());
                    dispatch(addToCartPreview(tabArticles));
                });
        });
    };
    /* ----------------------------------------------------------------------- */

    useEffect(() => {
        dispatch(clearCartPreview());
        if (resGetOrder.isError) {
            errorInfos.className = "SingleCart.js::getOrder()";
            const sourceMsg = resGetOrder.data.error?.data?.message ? resGetOrder.data.error.data.message : resGetOrder.data.error.error;
            errorInfos.message = `Echec lors de la récuperation de la commande - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation de la commande. Merci de contacter le service IT.</span>, {
                duration: 10000,
            });
        }

        if (resGetOrder.status === "fulfilled") {
            dispatch(
                setClientPreview({
                    clientCode: resGetOrder.data.order.clientCode,
                    client: resGetOrder.data.order.client,
                })
            );

            const param = {
                clientCode: resGetOrder.data.order.clientCode,
                isActif: "Oui",
            };
            triggerGetAddresses(param);
            getProductsDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetOrder]);

    useEffect(() => {
        if (resGetAllAddresses.status === "fulfilled" && resGetOrder.status === "fulfilled") {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setAddress(resGetAllAddresses.data.adresseslivraison.adresselivraison.find((address) => address.numero === resGetOrder.data.order.shippingAddress));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetAllAddresses]);

    useEffect(() => {
        if (address !== 0) {
            dispatch(setShippingAddressPreview(address));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    useEffect(() => {
        dispatch(clearCartPreview());
        triggerGetOrder(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* --------------------------------------------------------------------------
     * - function called on click button 'Reprendre la commande'
     * ----------------------------------------------------------------------- */
    const handleRestartOrder = async () => {
        // call keycloak refreshToken
        const formData = getFormDataToRefreshKeycloakToken();
        formData.append("refresh_token", auth.refreshToken);
        await triggerRefreshToken(formData)
            .unwrap()
            .then((response) => {
                if (response.status === false) {
                    toast(
                        <>
                            <InfoIcon fontSize="medium" className={styles.posInToast} />
                            Votre session a expirée.
                            <br />
                            Merci de saisir votre login et mot de passe pour vous reconnecter.
                        </>,
                        {
                            duration: 7000,
                        }
                    );

                    // - send log warning
                    errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                    errorInfos.userEmail = auth.user.email;
                    errorInfos.className = "SingleCart.js - event onClick button Reprendre la commmande";
                    errorInfos.code = 300;
                    errorInfos.criticite = 1;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);

                    dispatch(resetUser());
                    navigate("/login");
                } else {
                    dispatch(clearCart());
                    Object.values(cartPreview.cartPreviewItems[0]).map((product) => dispatch(restoreCart(product)));
                    dispatch(
                        setClient({
                            clientCode: resGetOrder.data.order.clientCode,
                            client: resGetOrder.data.order.client,
                            codepostal: resGetOrder.data.order.codepostal,
                        })
                    );
                    if (resGetOrder.data.order.fromQuote === 1) {
                        dispatch(setFromQuote(true));
                    }
                    dispatch(setShippingAddress(resGetOrder.data.order.shippingAddress));
                    dispatch(setIsOrderWeb(false));
                    dispatch(clearSiteSource(false));
                    dispatch(setIsOrderImport(false));
                    dispatch(setIsRecoveryOrder(true));
                    dispatch(setOrderSaisieId(resGetOrder.data.order.id));
                    dispatch(
                        setCarrier({
                            carrierCode: resGetOrder.data.order.carrierCode == null ? "" : resGetOrder.data.order.carrierCode,
                        })
                    );

                    dispatch(
                        setService({
                            serviceCode: resGetOrder.data.order.serviceCode == null ? "" : resGetOrder.data.order.serviceCode,
                        })
                    );
                    const strDateLivr = strDate(resGetOrder.data.order.datelivraison);
                    const today = strDate(new Date());

                    if (strDateLivr < today) {
                        dispatch(setSelectedDeliveryDate(today));
                    } else {
                        dispatch(setSelectedDeliveryDate(strDateLivr));
                    }

                    dispatch(setOriginComment(resGetOrder.data.order.origine));
                    dispatch(clearCartPreview());
                    dispatch(updateLastLocation(location.pathname));
                    navigate("/order");
                }
            });
    };

    return (
        <>
            {cartPreview.clientPreview.length !== 0 && cartPreview.shippingAddressPreview.length !== 0 ? (
                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                    {/* On utilise un Grid item xs={8} pour centrer le tableau sur une largeur de 12 */}
                    <Grid item xs={8}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            {/* - Bloc du haut avec le client et l'adresse --------------------- */}
                            <Grid item xs={6} className={styles.dataClient}>
                                <h4>
                                    <AccountCircleIcon fontSize="medium" className={styles.adjustPos} />
                                    Client : {cartPreview.clientPreview.clientCode} - {cartPreview.clientPreview.client}
                                </h4>
                            </Grid>
                            <Grid item xs={6} className={styles.adrLivr}>
                                <h4>
                                    <LocationOnIcon fontSize="medium" className={styles.adjustPos} />
                                    Adresse de livraison
                                </h4>
                                <AddressRow item={cartPreview.shippingAddressPreview} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Spinner />
            )}

            {cartPreview.cartPreviewItems.length > 0 ? (
                <>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                        {/* On utilise un Grid item xs={8} pour centrer la grille sur une largeur de 12 */}
                        <Grid item xs={8}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={styles.cartTableHeader}>
                                <Grid item xs={5}>
                                    Produit
                                </Grid>
                                <Grid item xs={3}>
                                    Qté
                                </Grid>
                                <Grid item xs={4}>
                                    Com.
                                </Grid>
                            </Grid>

                            {Object.values(cartPreview.cartPreviewItems[0]).map((product, index) => (
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    justifyContent="flex-center"
                                    className={styles.cartTableRow}
                                    key={index}
                                >
                                    <Grid item xs={2}>
                                        <div className={styles.blockImg}>
                                            <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h4 className={styles.ref}>{product.reference}</h4>
                                        <p className={styles.productDesignation}>{product.designation}</p>
                                    </Grid>
                                    <Grid item xs={3} className={styles.txtCenter}>
                                        <p>{product.quantity}</p>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {product.comment}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    {/* - Fin bloc Commande --------------------------------------------- */}

                    <div className={styles.bottomButtons}>
                        <SubmitButton buttonText="Reprendre la commande" onClick={handleRestartOrder} />
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </>
    );
};

export default SingleCart;
